export const CartItemDataFragment = `
 	id
	quantity
	product_id
	price_id
	user_id
	vendor_id
	product {
        id
        name
        sku
        image_path
        is_favorite
        is_verified
        packaging_details
        base_product {
            id
            name
            image_path
            packaging_details
        }
        vendor {
            id
            name
        }
	}
	cart_id
	vendor {
		id 
		name
		image_path
		banner_image_path
		products_count
		default_order_email
		is_vetted
		is_catalogable
		has_punchout
		units_of_measure {
			id
			name
		}
		integrations {
			id
			vendor_id
			integration_id
			integration {
				is_active
				id
				name
			}
			vendor {
				name
				id
			}
		}
	}
	price {
		id
		price
		product_id
		unit_of_measure
		formulary {
		    id
		    name
		}
	}
	sku
	auxiliary_id
	chart_of_account
	line_of_business
	chart_of_account_id
	line_of_business_id
	name
	uom
	unit_price
`
