// @flow
import React, { Suspense, useEffect } from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import AppLayout from 'src/navigation/_layouts/AppLayout'
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	withRouter,
} from 'react-router-dom'
import routes from 'src/navigation/routes'
import AppConfigs, { LD_CLIENT_ID } from 'src/configs/app'
import { Auth } from 'aws-amplify'
import PageTitle from 'src/components/_generic/page-title'
import PermissionRender from 'src/components/_generic/permission-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import ErrorPage from 'src/components/_generic/errors/error-page'
import DevFixture from 'src/library/DevFixture/DevFixture'
import { useGetAuthUserOrgOnboarding } from 'src/api/users/useGetAuthUserOrgOnboarding'
import FeatureRender from 'src/components/_generic/feature-render'
import { useQueryClient } from 'react-query'
import { withQuickshopModal } from 'src/components/Store/MarketplaceProductList/Quickshop/withQuickshopModal'
import useInitLaunchDarkly from 'src/_boot/useInitLaunchDarkly'
import { SubscriptionProvider } from 'src/_boot/SubscriptionContext'
import { PusherProvider } from 'src/_boot/PusherContext'
import { ToastProvider } from 'src/_boot/ToastContext'

const App = ({ location }) => {
	const history = useHistory()
	const queryClient = useQueryClient()

	useInitLaunchDarkly()

	useEffect(() => {
		Auth.currentAuthenticatedUser({ bypassCache: false }).catch(() => {
			return Auth.signOut({ global: true }).finally(() => {
				window.localStorage.removeItem('unit')
				queryClient.invalidateQueries('unit')
			})
		})
	}, [location])

	useEffect(() => {
		if (
			location?.pathname?.match('/bills') &&
			[...location?.pathname?.match('/bills')][0] === '/bills'
		) {
			let newPath = location?.pathname?.replace('bills', 'bills')
			history.push(newPath)
		}

		if (
			location?.pathname?.match('/bill') &&
			[...location?.pathname?.match('/bill')][0] === '/bill'
		) {
			let newPath = location?.pathname?.replace('bill', 'bill')
			history.push(newPath)
		}

		if (
			location?.pathname?.match('/pay/') &&
			[...location?.pathname?.match('/pay/')][0] === '/pay/'
		) {
			let newPath = location?.pathname?.replace('pay', 'payables')
			history.push(newPath)
		}
	}, [])

	const { authUser } = useGetAuthenticatedUser()
	const { organization } = useGetAuthUserOrgOnboarding()

	const renderRoute = (key, route, props) => {
		queryClient.setQueryData('cm-dev-tools', { page: { component: key } })

		const shouldOverridePermissions =
			route.permissionOverride &&
			route.permissionOverride(authUser, props)

		if (
			organization &&
			!organization?.onboardingComplete() &&
			route?.path?.includes('/procure') &&
			route?.path !== '/procure/store/onboarding'
		) {
			return <Redirect to={'/procure/store/onboarding'} />
		}

		return (
			<PermissionRender
				permissions={route.permissions}
				overridePermissions={shouldOverridePermissions}
				failedRender={
					<ErrorPage message='You do not have permission to view this resource.' />
				}
			>
				<FeatureRender
					featureKey={route.featureKey}
					failedRender={
						<ErrorPage message='Your organization does not have access to this feature. Please contact success@curemint.io for more information. ' />
					}
				>
					<PageTitle title={route.title} />
					<route.component
						{...props}
						{...route.props}
						title={route?.title}
					/>
				</FeatureRender>
			</PermissionRender>
		)
	}

	return (
		<PusherProvider>
			<SubscriptionProvider>
				<ToastProvider>
					<Suspense fallback={<AppLayout />}>
						<Switch>
							<Route exact path={`/dev`} component={DevFixture} />
							<AppLayout>
								<Switch>
									{routes.keyMap(([key, route]) => (
										<Route
											key={key}
											exact
											path={`${AppConfigs.appPrefix}${route?.path}`}
											render={(props) =>
												renderRoute(key, route, props)
											}
										/>
									))}
								</Switch>
							</AppLayout>
						</Switch>
					</Suspense>
				</ToastProvider>
			</SubscriptionProvider>
		</PusherProvider>
	)
}

export default withLDProvider({
	clientSideID: LD_CLIENT_ID,
})(withQuickshopModal(withRouter(App)))
