// @flow
import { useMutation } from 'src/api/index'
import { UpdateUserMutation } from 'src/api/users/update-user-mutation'

export const useUpdateUser = () => {
	return useMutation({
		key: ['update_user'],
		request: UpdateUserMutation,
	})
}
