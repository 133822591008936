// flow
import React from 'react'
import { Button } from 'reactstrap'
import { FlexColumn } from 'src/pages/_styles/container.styles'

type Props = {
	icon: React.ReactNode,
	color?: string,
	onClick: () => any,
	targetId?: string | number,
	disabled?: boolean,
	className?: string,
	dataToggle?: string,
	styles?: Object,
}

const IconButton = ({
	color = 'default',
	icon,
	onClick,
	targetId,
	styles,
	disabled = false,
	className = '',
	dataToggle = undefined,
	...rest
}: Props) => {
	const backgroundIsTransparent = color === 'transparent'

	return (
		<Button
			className={`btn-just-icon ${
				backgroundIsTransparent ? 'cm-button-transparent' : ''
			} ${className}`}
			data-testid='btn-just-icon'
			color={color}
			type='submit'
			onClick={onClick}
			id={targetId || ''}
			disabled={disabled}
			data-toggle={dataToggle || undefined}
			style={{ ...styles }}
			{...rest}
		>
			<FlexColumn alignItems={'center'}>{icon}</FlexColumn>
		</Button>
	)
}

export default IconButton
