// @flow

import type { FilterStateType } from 'src/flowtypes/Objects/Filters/FilterStateType'

export type SortType = {
	column: string,
	direction: 'asc' | 'desc',
}

export type ColumnDataType = {
	columnName: string,
	alias: string,
}

export type QueryModifierType = {
	resultsPerPage: number,
	page: number,
	searchTarget: string,
	filters: FilterStateType,
	addOns: [string],
	sorts: [SortType],
	selects: [ColumnDataType],
}

export const DefaultQueryModifiers = {
	resultsPerPage: 10,
	page: 1,
	searchTarget: '',
	filters: {},
	addOns: [],
}
