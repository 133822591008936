// @flow
import React from 'react'
import version from 'src/configs/version'

const SideMenuVersion = () => (
	<p
		style={{
			fontSize: '.8em',
			padding: 20,
			color: '#b0b0b0',
			textAlign: 'center',
			width: '100%',
		}}
	>
		Last Code Release:
		<br />
		{version}
	</p>
)

export default SideMenuVersion
