// @flow

import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	unpaid: {
		value: 10,
		name: 'Unpaid',
		label: 'Unpaid',
		color: 'var(--status-label-default)',
	},
	draft: {
		value: 20,
		name: 'Draft',
		label: 'Draft',
		color: 'var(--status-label-default)',
	},
	approving: {
		value: 30,
		name: 'Approving',
		label: 'Approving',
		color: 'var(--warning)',
	},
	scheduled: {
		value: 40,
		name: 'Scheduled',
		label: 'Scheduled',
		color: 'var(--cm-brand-info)',
	},
	rejected: {
		value: 50,
		name: 'Rejected',
		label: 'Rejected',
		color: 'var(--danger)',
	},
	pending: {
		value: 60,
		name: 'Pending',
		label: 'Pending',
		color: 'var(--warning)',
	},
	canceled: {
		value: 70,
		name: 'Canceled',
		label: 'Canceled',
		color: 'var(--danger)',
	},
	clearing: {
		value: 80,
		name: 'Clearing',
		label: 'Clearing',
		color: 'var(--warning)',
	},
	returned: {
		value: 90,
		name: 'Returned',
		label: 'Returned',
		color: 'var(--danger)',
	},
	partiallyCompleted: {
		value: 100,
		name: 'Partially Completed',
		label: 'Partially Completed',
		color: 'var(--success)',
	},
	paid: {
		value: 110,
		name: 'Paid',
		label: 'Paid',
		color: 'var(--success)',
	},
	markedPaid: {
		value: 120,
		name: 'Marked Paid',
		label: 'Marked Paid',
		color: 'var(--success)',
	},
	getEditableStatusValues: function () {
		return [this.unpaid.value, this.draft.value, this.scheduled.value]
	},
	getDeletableStatusValues: function () {
		return [this.unpaid.value, this.draft.value, this.scheduled.value]
	},
	getPayableStatusesForBill: function () {
		return [
			this.unpaid,
			this.canceled,
			this.returned,
			this.rejected,
		]
	},
	getManuallySettableStatuses: function () {
		return [this.unpaid, this.markedPaid]
	},
})
