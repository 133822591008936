// @flow
import React from 'react'
import ProspectiveOrderBudgetProgressBarPopover from 'src/components/Budgets/Checkout/ProgressBar/prospective-order-budget-progress-bar-popover'

type Props = {
	budget: Object,
	targetId: string,
}

const RemainingSpendPopover = ({ budget, targetId }: Props) => {
	const {
		budget_allotment_id,
		budget_amount,
		current_spend,
		projected_spend,
	} = budget
	return (
		<ProspectiveOrderBudgetProgressBarPopover
			key={`remaining-spend-popover-${budget_allotment_id}-2`}
			label={'Remaining'}
			value={budget_amount - (current_spend + projected_spend)}
			percentOfMax={(
				((budget_amount - (current_spend + projected_spend)) /
					budget_amount) *
				100
			).toFixed(2)}
			targetId={targetId}
		/>
	)
}

export default RemainingSpendPopover
