// @flow

export const AttachFileTypes = {
	acceptAllFileTypes: {
		accept: {
			'application/*': ['.doc', '.docx', '.pdf', '.xls', '.xlsx'],
			'image/*': ['.jpg', '.jpeg', '.png'],
			'text/*': ['.csv'],
		},
		asArray: [
			'.csv',
			'.doc',
			'.docx',
			'.jpg',
			'.jpeg',
			'.pdf',
			'.png',
			'.xls',
			'.xlsx',
		],
	},
	acceptCSVFileTypes: {
		accept: {
			'text/*': ['.csv'],
		},
		asArray: ['.csv'],
	},
	acceptDocumentFileTypes: {
		accept: {
			'application/*': ['.doc', '.docx', '.pdf', '.xls', '.xlsx'],
			'text/*': ['.csv'],
		},
		asArray: ['.csv', '.doc', '.docx', '.pdf', '.xls', '.xlsx'],
	},
	acceptImageFileTypes: {
		accept: {
			'image/*': ['.jpg', '.jpeg', '.png'],
		},
		asArray: ['.jpg', '.jpeg', '.png'],
	},
}
