import useGlobalState from 'src/library/useGlobalState/useGlobalState'

export const useVirtualInclusions = (key, { removeExclusions }) => {
	const [inclusions, setInclusions] = useGlobalState(
		`${key}-inclusions`,
		[],
		false,
		300
	)

	const addInclusions = (results: string[]) => {
		removeExclusions(results?.map((item) => item?.id) ?? [])
		setInclusions([...new Set([...(inclusions ?? []), ...results])])
	}

	return {
		inclusions,
		addInclusions,
	}
}
