// @flow
import React from 'react'
import imgLogo from 'src/assets/img/logo.png'

type Props = {
	style?: Object,
	className?: string,
}

const CureMintLogo = ({ style = {}, className = '' }: Props) => {
	return (
		<img
			className={className}
			alt='CureMint® Logo'
			style={style}
			src={imgLogo}
		/>
	)
}

export default CureMintLogo
