// @flow
import { useQuery } from 'src/api/index'
import { GetManyOrgVendorsQuery } from 'src/api/organization_vendors/get-many-org-vendors-query'

export const useGetManyOrgVendors = (ids = []) => {
	const { data: org_vendors, isLoading } = useQuery({
		key: ['many_org_vendors', { ids }],
		request: GetManyOrgVendorsQuery,
		options: {
			enabled: ids.length > 0,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { org_vendors, isLoading }
}
