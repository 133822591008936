import styled from 'styled-components'
import { DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledDropdownItem = styled(DropdownItem)`
	display: flex;
	gap: 8px;
`

export const DropdownIcon = styled(FontAwesomeIcon)`
	font-size: 16px;
	min-width: 16px;
`
