// @flow
import React from 'react'
import { Button } from 'reactstrap'

type Props = {
	onClick: () => any,
}

const GlobalSearchButton = ({ onClick }: Props) => (
	<Button
		style={Styles.btn}
		color='neutral'
		type={'submit'}
		onClick={onClick}
	>
		Search
	</Button>
)

export default GlobalSearchButton

const Styles = {
	btn: {
		margin: 0,
		lineHeight: 1.5,
		border: '1px solid #DDDDDD',
	},
}
