import { useQuery } from 'react-query'
import { useVirtualExclusions } from 'src/components/Search/InstantSearch/_hooks/useVirtualExclusions'
import { useVirtualInclusions } from 'src/components/Search/InstantSearch/_hooks/useVirtualInclusions'
import { EmptyFunc } from 'src/library/index'

export const useVirtualHits = (key) => {
	const exclusionState = useVirtualExclusions(key)
	const inclusionState = useVirtualInclusions(key, exclusionState)

	const { data } = useQuery(key, EmptyFunc, {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		cacheTimer: 1000 * 60 * 60,
	})
	const filteredResults =
		data?.filter((item) => !exclusionState.exclusions.includes(item?.id)) ??
		[]

	const inclusionResults =
		inclusionState?.inclusions?.filter(
			(inclusion) =>
				!filteredResults?.find((result) => result?.id === inclusion?.id)
		) ?? []

	const results = [...inclusionResults, ...filteredResults]

	return {
		results,
		exclusionState,
		inclusionState,
	}
}
