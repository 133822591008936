// @flow
import React, { useEffect, useRef } from 'react'
import { Input } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import useDidClickOutsideHook from 'src/hooks/useDidClickOutsideHook'
import { useGlobalSearch } from 'src/components/TopNav/GlobalSearch/GlobalSearchProvider'
import { useDebouncedCallback } from 'use-debounce'
import useKeystrokes from 'src/hooks/keystrokes/useKeystrokes'
import keystrokes from 'src/configs/keystrokes'
import { useFormikContext } from 'formik'
import { connectSearchBox } from 'react-instantsearch/connectors'

const GlobalSearchInput = ({ refine, id, setIsOpen }) => {
	const { modifiers, setters } = useGlobalSearch()
	const { values, setFieldValue } = useFormikContext()

	const { keysPressed: enterPressed } = useKeystrokes([keystrokes.ENTER])
	const history = useHistory()
	const { pathname } = useLocation()

	const ref = useRef()
	const searchRef = useRef()

	useDidClickOutsideHook(ref, () => setIsOpen(false))

	const { callback: handleSearch } = useDebouncedCallback((target) => {
		if (pathname === '/procure/store/explore') {
			setters.setSearchTarget(target)
		} else {
			setters.setQueryModifiers({
				...modifiers,
				searchTarget: target,
				filters: {
					flags: [],
				},
			})
			refine(target)
		}
	}, 500)

	useEffect(() => {
		if (modifiers?.searchTarget !== values['search']) {
			setFieldValue('search', modifiers?.searchTarget)
		}
	}, [modifiers?.searchTarget])

	const toggleMenu = (open: boolean) => {
		if (pathname !== '/procure/store/explore') {
			setIsOpen(open)
		}
	}

	const handleSubmit = () => {
		setIsOpen(false)

		history.push({
			pathname: '/procure/store/explore',
		})
	}

	const handleInputChange = (e) => {
		setFieldValue('search', e.target.value)
		handleSearch(e.target.value)
	}

	const handleKeyPress = (e) => {
		if (enterPressed(e)) {
			handleSubmit()
		}
	}

	return (
		<Input
			id={id}
			lid={'search-input'}
			name={'search'}
			value={values['search']}
			placeholder='Product name or sku #'
			type='text'
			aria-label='Search product name or sku number'
			onFocus={() => toggleMenu(true)}
			onChange={handleInputChange}
			onKeyPress={handleKeyPress}
			innerRef={searchRef}
		/>
	)
}
export default connectSearchBox(GlobalSearchInput)
