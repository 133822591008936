// @flow
import React from 'react'
import Podium from 'src/components/_svgr/Icons/Podium'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

export default {
	sections: [
		{
			title: 'CureMint® Management',
			shouldDisplay: (authUser) =>
				authUser.isCuremintAdmin() &&
				authUser.isInCuremintOrganization(),
			items: [
				{
					name: 'Categories',
					icon: 'cm-icon-categories-sm',
					iconColor: '#60d92b',
					link: '/admin/categories',
					permissions: [
						'curemint-admin',
						'curemint-developer',
						'curemint-manage-categories',
					],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/categories'),
				},
				{
					name: 'Products',
					icon: 'cm-icon-products-sm',
					iconColor: '#f57b00',
					link: '/admin/products',
					permissions: ['curemint-admin', 'curemint-developer'],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/products') ||
						locationPathname
							?.toLowerCase()
							?.includes('/admin/base_products'),
				},
				{
					name: 'Product Requests',
					icon: 'cm-icon-bulb-63',
					iconColor: '#8367ce',
					link: '/admin/product-requests',
					permissions: ['curemint-admin', 'curemint-developer'],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/product-requests'),
				},
				{
					name: 'Vendors',
					icon: 'cm-icon-vendors-sm',
					iconColor: '#56e8dd',
					link: '/admin/vendors',
					permissions: ['curemint-admin', 'curemint-developer'],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/vendors'),
				},
				{
					name: 'Manufacturers',
					icon: 'cm-icon-manufacturers-sm',
					iconColor: '#d091a6',
					link: '/admin/manufacturers',
					permissions: [
						'curemint-admin',
						'curemint-developer',
						'curemint-manage-manufacturers',
					],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/manufacturers'),
				},
				{
					name: 'Organizations',
					icon: 'cm-icon-tooth-sm',
					iconColor: '#60d92b',
					link: '/admin/organizations',
					permissions: [
						'curemint-admin',
						'curemint-developer',
						'curemint-manage-organizations',
					],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/organizations'),
				},
				{
					name: 'All Orders',
					icon: 'cm-icon-tags-stack',
					iconColor: '#d78172',
					link: '/admin/orders',
					permissions: ['curemint-admin', 'curemint-developer'],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/orders'),
				},
				{
					name: 'All Bills',
					icon: 'cm-icon-cheque',
					iconColor: '#4b6fdf',
					link: '/cm-admin/bills',
					permissions: ['curemint-admin', 'curemint-developer'],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/cm-admin/bills'),
				},
				{
					name: 'All Price Books',
					icon: 'cm-icon-spreadsheet-sm',
					iconColor: '#60d92b',
					link: '/admin/formularies',
					permissions: [
						'curemint-admin',
						'curemint-developer',
						'curemint-manage-formularies',
					],
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/formularies'),
				},
				{
					name: 'Integrations',
					icon: 'cm-icon-content-delivery',
					iconColor: 'var(--color-brand-blue)',
					link: '/curemint/integrations',
					permissions: ['curemint-developer'],
					shouldDisplay: (authUser) => authUser.isCuremintDeveloper(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/curemint/integrations'),
				},
				{
					name: 'Feature Tiers',
					icon: 'cm-icon-categories-sm',
					iconColor: '#fbd27b',
					link: '/tiers/features',
					permissions: ['curemint-admin', 'curemint-developer'],
					svg: <Podium color='#fbd27b' />,
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isCuremintDeveloper(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/tiers/features'),
				},
			],
		},
		{
			title: 'Data',
			shouldDisplay: (authUser) =>
				authUser.isPurchaserOfAnyLocation() ||
				authUser.isAdminOfAnyLocation(),
			items: [
				{
					name: 'Dashboard',
					icon: 'cm-icon-h-dashboard',
					iconColor: '#4e9d09',
					link: '/dashboard',
					featureKey: 'dashboard',
					shouldDisplay: (authUser) =>
						authUser.isPurchaserOfAnyLocation() ||
						authUser.isAdminOfAnyLocation(),
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase()?.includes('/dashboard'),
				},
				{
					name: 'Data Explorer',
					icon: 'cm-icon-report',
					iconColor: '#ff884d',
					link: '/explorer',
					featureKey: 'data_explorer',
					shouldDisplay: (authUser) =>
						authUser.isPurchaserOfAnyLocation() ||
						authUser.isAdminOfAnyLocation(),
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase()?.includes('/explorer'),
				},
			],
		},
		{
			title: 'Finance',
			ldFlags: ['payablesIntegrations'],
			shouldDisplay: (authUser) => authUser?.isBankingCustomer(),
			items: [
				{
					name: 'Accounts',
					icon: 'cm-icon-pig',
					iconColor: '#d091a6',
					link: '/admin/finance/accounts',
					shouldDisplay: (authUser) => authUser.isBankingCustomer(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/finance/accounts'),
				},
				{
					name: 'Transactions',
					icon: 'cm-icon-report',
					iconColor: '#ff884d',
					link: '/admin/finance/transactions',
					shouldDisplay: (authUser) => authUser.isBankingCustomer(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/finance/transactions'),
				},
				{
					name: 'Statements',
					icon: 'cm-icon-paper',
					iconColor: '',
					link: '/admin/finance/statements',
					shouldDisplay: (authUser) => authUser?.isBankingCustomer(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/finance/statements'),
				},
			],
		},
		{
			title: 'Procure',
			shouldDisplay: (authUser) => {
				return !!(
					authUser?.organization?.hasTags(['module:Procure']) &&
					(authUser?.isCuremintAdmin() ||
						authUser?.isOrganizationAdmin() ||
						authUser?.isPurchaserOfAnyLocation() ||
						authUser?.isAdminOfAnyLocation())
				)
			},
			items: [
				{
					name: 'Store',
					icon: 'cm-icon-store',
					iconColor: '#3ECDF9',
					link: '/procure/store',
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase() === '/procure/store' ||
						locationPathname?.toLowerCase() === '/' ||
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/categories') ||
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/vendors') ||
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/lists') ||
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/explore'),
				},
				{
					name: 'Banners',
					icon: 'cm-icon-privacy-settings',
					iconColor: '#f568c0',
					link: '/procure/store/manage',
					featureKey: 'customized_banners',
					shouldDisplay: (authUser, flags) =>
						flags?.newCatalogPage
							? false
							: (authUser.isCuremintAdmin() ||
									authUser.isOrganizationAdmin()) &&
							  authUser?.organization?.isFeatureEnabled(
									'customized_banners'
							  ),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/manage'),
				},
				{
					name: 'Catalog',
					icon: 'cm-icon-book-open-2',
					iconColor: '#f57b00',
					link: '/procure/store/catalog',
					shouldDisplay: (authUser, flags) =>
						flags?.newCatalogPage
							? false
							: authUser.isCuremintAdmin() ||
							  authUser.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase() ===
						'/procure/store/catalog',
				},
				{
					name: 'Price Books',
					icon: 'cm-icon-book-open-2',
					iconColor: '#3ac057',
					link: '/organization/price-books',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase() ===
						'/organization/price-books',
				},
				{
					name: 'Inventory',
					icon: 'cm-icon-barcode',
					iconColor: '#9e74de',
					link: '/procure/inventory',
					ldFlags: ['inventoryManagement'],
					shouldDisplay: (authUser) =>
						authUser.isPurchaserOfAnyLocation() ||
						authUser.isAdminOfAnyLocation(),
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase() ===
						'/procure/inventory',
				},
				{
					name: 'Order Rules',
					icon: 'cm-icon-route-alert-2',
					iconColor: '#3ac057',
					link: '/procure/store/order_rules',
					featureKey: 'order_rules',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/order_rules'),
				},
				{
					name: 'Orders',
					icon: 'cm-icon-tags-stack',
					iconColor: '#d78172',
					link: '/procure/store/orders',
					shouldDisplay: () => true,
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/orders'),
					alertType: 'warning',
					reason: 'order-issues-count',
				},
				{
					name: 'Approvals',
					icon: 'cm-icon-check-single-sm',
					iconColor: '#3ac057',
					link: '/procure/store/approvals',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser?.isAdminOfAnyLocationGroup() ||
						authUser?.isAdminOfAnyLocation(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/procure/store/approvals'),
				},
				{
					name: 'Product Requests',
					icon: 'cm-icon-bulb-63',
					iconColor: '#8367ce',
					link: '/procure/store/catalog/request/product',
					featureKey: 'product_requests',
					shouldDisplay: (authUser) =>
						(authUser.isCuremintAdmin() ||
							authUser.isOrganizationAdmin()) &&
						authUser?.organization?.isFeatureEnabled(
							'product_requests'
						),
					isActive: (locationPathname) =>
						locationPathname?.toLowerCase() ===
						'/procure/store/catalog/request/product',
				},
			],
		},
		{
			title: 'Payables',
			shouldDisplay: (authUser) =>
				(authUser?.isCuremintAdmin() ||
					authUser?.isOrganizationAdmin() ||
					authUser?.isTranscriber() ||
					authUser?.isValidator() ||
					authUser?.isManager()) &&
				(authUser?.organization?.hasTags(['module:Payables']) ||
					authUser?.organization?.hasTags([
						'organization-type:Transcription Organization',
					])),
			items: [
				{
					name: 'Bills',
					icon: 'cm-icon-cheque',
					iconColor: '#67ba93',
					link: '/admin/bills',
					shouldDisplay: (authUser) =>
						authUser?.isCuremintAdmin() ||
						authUser?.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/bills'),
				},
				{
					name: 'Payments',
					icon: 'cm-icon-cheque',
					iconColor: '',
					link: '/admin/finance/payments',
					shouldDisplay: (authUser) => authUser?.isBankingCustomer(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/finance/payments'),
				},
				{
					name: 'Transcriptions',
					icon: 'cm-icon-book-open-2',
					iconColor: '#f57b00',
					link: '/payables/transcriptions',
					shouldDisplay: (authUser) =>
						(authUser?.isCuremintAdmin() ||
							authUser?.isTranscriber()) &&
						authUser?.organization?.hasTags([
							'organization-type:Transcription Organization',
						]),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/payables/transcriptions'),
				},
				{
					name: 'Validations',
					icon: 'cm-icon-check-double',
					iconColor: '#3ECDF9',
					link: '/payables/validations',
					shouldDisplay: (authUser) =>
						(authUser?.isCuremintAdmin() ||
							authUser?.isValidator()) &&
						authUser?.organization?.hasTags([
							'organization-type:Transcription Organization',
						]),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/payables/validations'),
				},
				{
					name: 'Escalations',
					icon: 'cm-icon-ambulance',
					iconColor: '#d0a91d',
					link: '/payables/escalations',
					shouldDisplay: (authUser) =>
						(authUser?.isCuremintAdmin() ||
							authUser?.isManager()) &&
						authUser?.organization?.hasTags([
							'organization-type:Transcription Organization',
						]),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/payables/escalations'),
				},
			],
		},
		{
			title: 'My Organization',
			shouldDisplay: (authUser) => {
				return (
					authUser.isPurchaserOfAnyLocation() ||
					authUser.isAdminOfAnyLocation()
				)
			},
			items: [
				{
					name: 'Locations',
					icon: 'cm-icon-office',
					iconColor: '#d9af2d',
					link: '/admin/locations',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin() ||
						authUser.isAdminOfAnyLocationGroup() ||
						(authUser.isAdminOfAnyLocation() &&
							authUser.locations.length > 1),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/locations') ||
						locationPathname
							?.toLowerCase()
							?.includes('/admin/location_groups'),
				},
				{
					name: 'My Location',
					icon: 'cm-icon-office',
					iconColor: '#d9af2d',
					link: '/admin/my/location',
					shouldDisplay: (authUser) =>
						!authUser.isCuremintAdmin() &&
						!authUser.isOrganizationAdmin() &&
						!authUser.isAdminOfAnyLocationGroup() &&
						authUser.isAdminOfAnyLocation() &&
						authUser.locations.length === 1,
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/my/location'),
				},
				{
					name: 'Budgets',
					icon: 'cm-icon-receipt-2',
					iconColor: '#3ac057',
					link: '/admin/budgets',
					featureKey: 'budgets',
					shouldDisplay: (authUser) =>
						authUser.isPurchaserOfAnyLocation() ||
						authUser?.isAdminOfAnyLocation(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/budgets'),
				},
				{
					name: 'Users',
					icon: 'cm-icon-multiple',
					iconColor: '#4b6fdf',
					link: '/admin/users',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin() ||
						authUser.isAdminOfAnyLocationGroup() ||
						authUser.isAdminOfAnyLocation(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/users') ||
						locationPathname
							?.toLowerCase()
							?.includes('/my/account'),
				},
				{
					name: 'Vendors',
					icon: 'cm-icon-server-rack-2',
					iconColor: '#56e8dd',
					link: '/organization/vendors',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/organization/vendors'),
				},
				{
					name: 'Entities',
					link: '/admin/entities',
					fontAwesome: (
						<FontAwesomeIcon
							icon={regular('layer-group')}
							color={'#E69161'}
						/>
					),
					shouldDisplay: (authUser) =>
						(authUser.isCuremintAdmin() ||
							authUser.isOrganizationAdmin()) &&
						authUser?.organization?.hasTags([
							'taggable-feature:Multi-Entity Feature',
						]),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/entities'),
				},
				{
					name: 'Accounting Categories',
					icon: 'cm-icon-book-open-4',
					iconColor: '#34c0eb',
					link: '/admin/accounting_categories',
					shouldDisplay: (authUser) =>
						authUser?.organization?.hasTags([
							'module:Payables',
							'module:Procure',
						]) &&
						(authUser.isCuremintAdmin() ||
							authUser.isOrganizationAdmin()),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/accounting_categories'),
				},
				{
					name: 'Integrations',
					ldFlags: ['accountingSoftwareSync'],
					link: '/admin/accounting_software',
					fontAwesome: (
						<FontAwesomeIcon
							icon={regular('arrow-right-arrow-left')}
							color={'#9878D8'}
						/>
					),
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/accounting_software'),
				},
				{
					name: 'Settings',
					icon: 'cm-icon-settings',
					iconColor: '#6b8788',
					link: '/admin/settings',
					shouldDisplay: (authUser) =>
						authUser.isCuremintAdmin() ||
						authUser.isOrganizationAdmin(),
					isActive: (locationPathname) =>
						locationPathname
							?.toLowerCase()
							?.includes('/admin/settings'),
				},
			],
		},
		{
			title: 'CureMint®',
			shouldDisplay: (authUser) =>
				(!authUser?.isTranscriber() &&
					!authUser?.isValidator() &&
					!authUser?.isManager()) ||
				authUser?.isCuremintAdmin() ||
				authUser?.isOrganizationAdmin(),
			items: [
				{
					name: 'Help Center',
					icon: 'cm-icon-wand',
					iconColor: '#8764e9',
					externalLink: 'https://support.curemint.io/',
					shouldDisplay: () => true,
				},
			],
		},
	],
}
