// @flow
import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import Slider from 'rc-slider'

type Props = {
	image: any,
	onCropComplete: (croppedArea: any, croppedAreaPixels: any) => any,
}

const ImageCropper = ({ image, onCropComplete }: Props) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)

	return (
		<>
			<div
				style={{ position: 'relative', width: '100%', height: '400px' }}
			>
				<Cropper
					image={image}
					crop={crop}
					zoom={zoom}
					aspect={1 / 1}
					onCropChange={(cropValue) => setCrop(cropValue)}
					onCropComplete={onCropComplete}
					restrictPosition={false}
				/>
			</div>
			<div
				style={{
					margin: '35px auto 0 auto',
					width: '400px',
					height: '40px',
				}}
			>
				<Slider
					max={6}
					min={0.1}
					defaultValue={1}
					step={0.1}
					value={zoom}
					onChange={setZoom}
				/>
			</div>
		</>
	)
}

export default ImageCropper
