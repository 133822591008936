// @flow
import enums from 'src/configs/enums'

export const getHumanReadableEntityType = (componentType: number): string => {
	switch (componentType) {
		case enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_EXCLUDE:
			return 'Exclude'
		case enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_INCLUDE:
			return 'Include'
		case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CHART_OF_ACCOUNT:
			return 'Chart of Account'
		case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_LINE_OF_BUSINESS:
			return 'Line of Business'
		case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_VENDOR:
			return 'Vendor'

		default:
			return 'Property'
	}
}
