// @flow
import React, { createContext, useContext } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { Configure } from 'react-instantsearch-dom'
import { useSearchCache } from 'src/components/Search/InstantSearch/_hooks/useSearchCache'
import { useInitAlgoliaSearch } from 'src/components/Search/InstantSearch/_hooks/useInitAlgoliaSearch'
import { VirtualSearchBox } from 'src/components/Search/InstantSearch/_components/VirtualSearchBox'
import { usePersistantSearchState } from 'src/components/Search/InstantSearch/_hooks/usePersistantSearchState'
import VirtualHits from 'src/components/Search/InstantSearch/_components/VirtualHits'

export const indexName = process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + 'products'

const RefreshContext = createContext()

export function useRefreshSearch() {
	const refresh = useContext(RefreshContext)

	return {
		refresh,
	}
}

export const withInstantSearch =
	(Component) =>
	({
		hasVirtualSearch = false,
		shouldBrowse = false,
		persistKey = 'global-search-state',
		...rest
	}) => {
		const { searchClient } = useInitAlgoliaSearch(persistKey, shouldBrowse)
		const { shouldRefresh, refresh } = useSearchCache()

		const { searchState, onSearchStateChange } =
			usePersistantSearchState(persistKey)

		return (
			<RefreshContext.Provider value={refresh}>
				<InstantSearch
					searchClient={searchClient}
					indexName={indexName}
					searchState={searchState}
					onSearchStateChange={onSearchStateChange}
					refresh={shouldRefresh}
				>
					<VirtualHits resultKey={persistKey}>
						<Configure hitsPerPage={24} page={0} />
						{hasVirtualSearch ? <VirtualSearchBox /> : null}
						<Component {...rest} />
					</VirtualHits>
				</InstantSearch>
			</RefreshContext.Provider>
		)
	}
