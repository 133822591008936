import React, { createContext, useContext, useEffect } from 'react'
import { connectHits } from 'react-instantsearch/connectors'
import { useQueryClient } from 'react-query'
import Product from 'src/apollo/_entities/product'
import { useVirtualHits } from 'src/components/Search/InstantSearch/_hooks/useVirtualHits'

const ResultsContext = createContext()
const ExclusionsContext = createContext()
const InclusionsContext = createContext()

export function useSearchResults() {
	const results = useContext(ResultsContext)
	const { exclusions, addExclusions, removeExclusions } =
		useContext(ExclusionsContext)
	const { addInclusions } = useContext(InclusionsContext)

	return {
		results,
		exclusions,
		addInclusions,
		addExclusions,
		removeExclusions,
	}
}

const VirtualHits = connectHits(({ hits, children, resultKey }) => {
	const hitsStr = JSON.stringify(hits)
	const queryClient = useQueryClient()

	const { results, inclusionState, exclusionState } =
		useVirtualHits(resultKey)

	useEffect(() => {
		queryClient.setQueryData(
			resultKey,
			hits.map((product) => Product(product))
		)
	}, [hitsStr])

	return (
		<ResultsContext.Provider value={results}>
			<ExclusionsContext.Provider value={exclusionState}>
				<InclusionsContext.Provider value={inclusionState}>
					{children}
				</InclusionsContext.Provider>
			</ExclusionsContext.Provider>
		</ResultsContext.Provider>
	)
})

export default function (props) {
	return <VirtualHits {...props} />
}
