import styled, { css } from 'styled-components'

export const Spacer = styled.div`
	display: block;

	${({ size }) => {
		switch (size) {
			case 'lg':
				return css`
					height: 3rem;
				`
			case 'md':
				return css`
					height: 2rem;
				`
			case 'sm':
			default:
				return css`
					height: 1rem;
				`
		}
	}}
`

export const HorizontalDivider = styled.hr`
	margin: 0;
`
