// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import TooltipWrapper from 'src/components/_generic/tooltips/tooltip-wrapper'
import DisabledOverlay from 'src/components/_generic/disabled-overlay'

type Props = {
	children?: Node,
	disabled?: boolean,
	tooltipText?: string,
	tooltipPlacement?: string,
}

const DisabledOverlayWithTooltip = ({
	children,
	disabled = false,
	tooltipText = '',
	tooltipPlacement = 'auto',
}: Props) => {
	const renderTooltipWrapperContent = (content) => {
		return (
			<ConditionalRender condition={disabled} failedRender={content}>
				<TooltipWrapper
					tooltipContent={tooltipText}
					placement={tooltipPlacement}
					CustomElement={'div'}
				>
					{content}
				</TooltipWrapper>
			</ConditionalRender>
		)
	}

	return (
		<>
			{renderTooltipWrapperContent(
				<DisabledOverlay disabled={disabled}>
					{children}
				</DisabledOverlay>
			)}
		</>
	)
}

export default DisabledOverlayWithTooltip
