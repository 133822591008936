// @flow
import React from 'react'
import { Progress } from 'reactstrap'
import SplitLayout from 'src/components/_layouts/split-layout'
import ProgressBarPercentage from 'src/components/_generic/progress-bars/progress-bar-percentage'
import HappyProgressBar from 'src/components/Budgets/Checkout/ProgressBar/happy-progress-bar'
import DangerProgressBar from 'src/components/Budgets/Checkout/ProgressBar/danger-progress-bar'

type Props = {
	budget: Object,
	style?: Object,
}

const ProspectiveOrderBudgetProgressBar = ({ budget, style = {} }: Props) => {
	const {
		budget_allotment_id,
		budget_name,
		budget_amount,
		current_spend,
		projected_spend,
	} = budget

	const totalSpend = current_spend + projected_spend
	const totalSpendBreaksBounds = budget_amount - totalSpend <= 0
	const maximumValue = totalSpendBreaksBounds ? totalSpend : budget_amount

	if (isNaN(totalSpend)) return null

	return (
		<div style={{ position: 'relative', marginTop: '8px' }}>
			<h6>{budget_name}</h6>
			<SplitLayout
				leftContent={
					<p style={{ fontSize: '12px' }} className={'text-left'}>
						Spend: ${current_spend}
					</p>
				}
				rightContent={
					<p style={{ fontSize: '12px' }} className='text-right'>
						Budget: ${budget_amount}
					</p>
				}
			/>
			<Progress multi style={style}>
				{!totalSpendBreaksBounds ? (
					<HappyProgressBar
						key={`happy-progress-bar-${projected_spend}`}
						budget={budget}
						maxValue={maximumValue}
					/>
				) : (
					<DangerProgressBar
						key={`danger-progress-bar-${projected_spend}`}
						budget={budget}
						maxValue={maximumValue}
					/>
				)}
			</Progress>
			<ProgressBarPercentage
				key={`progress-bar-percentage-${budget_allotment_id}`}
				valuesSum={totalSpend}
				max={budget_amount}
			/>
		</div>
	)
}

export default ProspectiveOrderBudgetProgressBar
