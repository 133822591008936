// @flow
import React, { useState } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import Form from 'src/components/_generic/forms/form'
import Validators from 'src/configs/ValidationModels/auth'
import CardSection from 'src/components/_generic/cards/card-section'
import Input from 'src/components/_generic/forms/input'
import Button from 'reactstrap/lib/Button'
import AuthLayout from 'src/navigation/_layouts/AuthLayout'
import Authenticator from 'src/library/Authenticator/Authenticator'

const AuthConfirmSignUp = ({ authState, authData, onStateChange }) => {
	const [loading, setLoading] = useState(false)
	const { resendSignUp, confirmSignUp } = Authenticator(onStateChange)

	const onLoginClicked = () => {
		onStateChange('signIn')
	}

	const handleResendCode = () => {
		resendSignUp(authData.email)
	}

	const handleConfirm = async (confirmData) => {
		setLoading(true)
		await confirmSignUp({
			email: authData.email,
			password: authData.password,
			code: confirmData.code,
		})
		setLoading(false)
	}

	return (
		<ConditionalRender condition={authState === 'confirmSignUp'}>
			<AuthLayout>
				<CardSection cardProps={{ padding: 60, width: 400 }}>
					<h3 className={'ml-auto mr-auto cm-mb-double'}>
						Confirm Account
					</h3>
					<Form
						initialValues={{ code: '' }}
						validator={Validators.auth.confirmRegister}
						onSubmit={handleConfirm}
					>
						<Input name='code' label='Verification Code' required />
						<PrimaryButton
							className='btn-block cm-mt-double cm-mb-double'
							title='Confirm Account'
							type='submit'
							disabled={loading}
							onClick={() => {}}
						/>
					</Form>
					<Button
						className='btn btn-link'
						color='primary'
						onClick={handleResendCode}
					>
						Resend Verification Code
					</Button>
					<Button
						className='btn btn-link'
						color='primary'
						onClick={onLoginClicked}
					>
						Log In
					</Button>
				</CardSection>
			</AuthLayout>
		</ConditionalRender>
	)
}

export default AuthConfirmSignUp
