// @flow
import Price from 'src/apollo/_entities/price'
import StringFormatter from 'src/library/StringFormatter'
import OrganizationVendor from 'src/apollo/_entities/organizationVendor'

const Product = (productData) => {
	if (!productData) return null

	const prices = productData?.prices
	const uom = prices && prices?.length > 0 ? prices[0]?.unit_of_measure : null

	const name =
		productData?.name || productData?.base_product?.name
			? (productData?.name ?? productData?.base_product?.name)
					?.replace(/&quot;/g, '"')
					?.replace(/&amp;/g, '&')
			: null

	const available_prices = productData?.available_prices
		?.map((price) => {
			let priceData = {
				...price,
				product_id: productData?.id,
				product_name: name,
				product_sku: productData?.sku,
			}
			return Price(priceData)
		})
		?.sort((a, b) => a.price - b.price)

	if (typeof productData?.formulary_ids === 'string') {
		productData.formulary_ids = productData?.formulary_ids?.split(',')
	}

	if (!productData?.formulary_ids) {
		// if we have no formulary ids by this point
		// pull them off the available prices
		productData.formulary_ids = [].concat.apply(
			[],
			available_prices?.map((price) => price?.formulary?.id) ?? []
		)
	}

	const hasMultiplePrices = () =>
		productData?.formulary_ids?.length > 1 ||
		productData?.available_prices?.length > 1

	const hasPrice = () =>
		productData?.available_prices &&
		productData?.available_prices?.length > 0

	const isSiblingTo = (targetSku) => {
		const clean = (sku) =>
			StringFormatter.replaceMany(
				StringFormatter.clean(sku),
				[',', '\\-', '\\.'],
				''
			)

		const searchTarget = clean(targetSku)

		return (
			clean(productData?.sku) !== searchTarget &&
			productData?.siblings
				?.map((sku) => clean(sku))
				?.includes(searchTarget)
		)
	}

	const formularies = available_prices
		?.map((price) => price.formulary_name)
		?.join(', ')

	const org_product_tags =
		productData?.org_tags?.map(({ tag }) => tag?.name)?.join(', ') ?? []
	const global_product_tags =
		productData?.global_tags?.map(({ tag }) => tag?.name)?.join(', ') ?? []

	const siblings = productData?.siblings

	const canPlaceOrder = () =>
		productData?.vendor?.organization_vendor?.can_place_order ?? false
	const hasTemporaryDataLockout = () =>
		productData?.vendor?.organization_vendor?.has_temporary_data_lockout ??
		false

	return {
		...productData,
		value: productData?.id,
		label: productData?.sku,
		id: '' + productData.id,
		sku: productData.sku,
		name,
		formulary_ids: productData.formulary_ids ?? null,
		formularies,
		description:
			productData?.description ??
			productData?.base_product?.description ??
			null,
		packaging_details:
			productData?.packaging_details ??
			productData?.base_product?.packaging_details ??
			null,
		image_path:
			productData?.image_path ??
			productData?.base_product?.image_path ??
			'logo_placeholder.jpg',
		is_favorite: productData?.is_favorite,
		is_verified: productData?.is_verified,
		vendor: {
			...productData?.vendor,
			id: productData?.vendor?.id,
			name: productData?.vendor_name ?? productData?.vendor?.name,
			image_path:
				productData?.vendor?.image_path ?? productData?.image_path,
			organization_vendor: OrganizationVendor(
				productData?.vendor?.organization_vendor
			),
		},
		primary_category: productData?.base_product?.primary_category ?? null,
		base_product: productData?.base_product ?? null,
		shopping_lists: productData?.shopping_lists ?? [],
		available_prices,
		best_price:
			available_prices?.[0]?.price ?? productData?.best_price ?? null,
		unit_of_measure: uom,
		_requestdata: productData,
		comments: productData?.comments ?? [],
		global_product_tags,
		global_tags: productData?.global_tags ?? [],
		org_product_tags,
		org_tags: productData?.org_tags ?? [],
		stock_levels: productData?.stock_levels ?? [],
		siblings,
		hasMultiplePrices,
		hasPrice,
		isSiblingTo,
		canPlaceOrder,
		hasTemporaryDataLockout,
	}
}

export default Product
