// @flow
import React from 'react'
import { Button } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	onClick: () => any,
	isExpanded: boolean,
}

const SideMenuCloseButton = ({ onClick, isExpanded }: Props) => {
	return (
		<Button
			className={`btn-just-icon ${
				isExpanded
					? 'cm-sidenav-close-button'
					: 'cm-sidenav-close-button-hidden'
			}`}
			color='primary'
			onClick={onClick}
		>
			<ConditionalRender
				condition={isExpanded}
				failedRender={<i className='cm-icon cm-icon-menu-sm' />}
			>
				<i className='cm-icon cm-icon-close-bold' />
			</ConditionalRender>
		</Button>
	)
}

export default SideMenuCloseButton
