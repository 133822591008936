// @flow

export default {
	PENDING: 10,
	PARTIALLY_FULFILLED: 11,
	RECEIVED: 20,
	PARTIALLY_RECEIVED: 21,
	REJECTED: 30,
	REJECTED_BY_VENDOR: 31,
	BACKORDERED: 40,
	BACKORDERED_BY_VENDOR: 41,
	PARTIALLY_BACKORDERED: 42,
	ADJUSTED_BY_VENDOR: 50,
	SUBSTITUTED_BY_VENDOR: 60,
	SHIPPED: 70,
	PARTIALLY_SHIPPED: 71,

	PENDING_HUMAN_READABLE: 'Pending',
	PARTIALLY_FULFILLED_HUMAN_READABLE: 'Partially Fulfilled',
	RECEIVED_HUMAN_READABLE: 'Received',
	PARTIALLY_RECEIVED_HUMAN_READABLE: 'Partially Received',
	REJECTED_HUMAN_READABLE: 'Rejected',
	REJECTED_BY_VENDOR_HUMAN_READABLE: 'Rejected by Vendor',
	BACKORDERED_HUMAN_READABLE: 'Backordered',
	BACKORDERED_BY_VENDOR_HUMAN_READABLE: 'Backordered by Vendor',
	PARTIALLY_BACKORDERED_HUMAN_READABLE: 'Partially Backordered',
	ADJUSTED_BY_VENDOR_HUMAN_READABLE: 'Adjusted by Vendor',
	SUBSTITUTED_BY_VENDOR_HUMAN_READABLE: 'Substituted by Vendor',
	SHIPPED_HUMAN_READABLE: 'Shipped',
	PARTIALLY_SHIPPED_HUMAN_READABLE: 'Partially Shipped',

	getHumanReadable: function (status: number): string {
		switch (status) {
			case this.PARTIALLY_FULFILLED:
				return this.PARTIALLY_FULFILLED_HUMAN_READABLE
			case this.RECEIVED:
				return this.RECEIVED_HUMAN_READABLE
			case this.PARTIALLY_RECEIVED:
				return this.PARTIALLY_RECEIVED_HUMAN_READABLE
			case this.REJECTED:
				return this.REJECTED_HUMAN_READABLE
			case this.REJECTED_BY_VENDOR:
				return this.REJECTED_BY_VENDOR_HUMAN_READABLE
			case this.BACKORDERED:
				return this.BACKORDERED_HUMAN_READABLE
			case this.BACKORDERED_BY_VENDOR:
				return this.BACKORDERED_BY_VENDOR_HUMAN_READABLE
			case this.PARTIALLY_BACKORDERED:
				return this.PARTIALLY_BACKORDERED_HUMAN_READABLE
			case this.ADJUSTED_BY_VENDOR:
				return this.ADJUSTED_BY_VENDOR_HUMAN_READABLE
			case this.SUBSTITUTED_BY_VENDOR:
				return this.SUBSTITUTED_BY_VENDOR_HUMAN_READABLE
			case this.SHIPPED:
				return this.SHIPPED_HUMAN_READABLE
			case this.PARTIALLY_SHIPPED:
				return this.PARTIALLY_SHIPPED_HUMAN_READABLE
			default:
				return this.PENDING_HUMAN_READABLE
		}
	},

	getStatusFromHumanReadable: function (status: string): number {
		switch (status) {
			case this.PARTIALLY_FULFILLED_HUMAN_READABLE:
				return this.PARTIALLY_FULFILLED
			case this.RECEIVED_HUMAN_READABLE:
				return this.RECEIVED
			case this.PARTIALLY_RECEIVED_HUMAN_READABLE:
				return this.PARTIALLY_RECEIVED
			case this.REJECTED_HUMAN_READABLE:
				return this.REJECTED
			case this.REJECTED_BY_VENDOR_HUMAN_READABLE:
				return this.REJECTED_BY_VENDOR
			case this.BACKORDERED_HUMAN_READABLE:
				return this.BACKORDERED
			case this.BACKORDERED_BY_VENDOR_HUMAN_READABLE:
				return this.BACKORDERED_BY_VENDOR
			case this.PARTIALLY_BACKORDERED_HUMAN_READABLE:
				return this.PARTIALLY_BACKORDERED
			case this.ADJUSTED_BY_VENDOR_HUMAN_READABLE:
				return this.ADJUSTED_BY_VENDOR
			case this.SUBSTITUTED_BY_VENDOR_HUMAN_READABLE:
				return this.SUBSTITUTED_BY_VENDOR
			case this.SHIPPED_HUMAN_READABLE:
				return this.SHIPPED
			case this.PARTIALLY_SHIPPED_HUMAN_READABLE:
				return this.PARTIALLY_SHIPPED
			default:
				return this.PENDING
		}
	},

	getManuallySettableStatuses: function (): number[] {
		return [
			this.PENDING,
			this.RECEIVED,
			this.PARTIALLY_RECEIVED,
			this.REJECTED,
			this.BACKORDERED,
			this.SHIPPED,
		]
	},

	getRejectedStatuses: function (status: number): boolean {
		return status === this.REJECTED || status === this.REJECTED_BY_VENDOR
	},

	getIsReceived: function (status: number): boolean {
		return status === this.RECEIVED || status === this.PARTIALLY_RECEIVED
	},

	getReceivedStatuses: function (): number[] {
		return [this.RECEIVED, this.PARTIALLY_RECEIVED]
	},

	getBackorderedStatuses: function (status: number): boolean {
		return (
			status === this.BACKORDERED ||
			status === this.BACKORDERED_BY_VENDOR ||
			status === this.PARTIALLY_BACKORDERED
		)
	},

	getShippedStatuses: function (status: number): boolean {
		return status === this.SHIPPED || status === this.PARTIALLY_SHIPPED
	},

	getDetailStatuses: function (status: number): boolean {
		return (
			status === this.PARTIALLY_FULFILLED ||
			status === this.ADJUSTED_BY_VENDOR ||
			status === this.SUBSTITUTED_BY_VENDOR
		)
	},

	getUnhandledStatuses: function (): number[] {
		return [
			this.PENDING,
			this.PARTIALLY_FULFILLED,
			this.PARTIALLY_RECEIVED,
			this.BACKORDERED,
			this.BACKORDERED_BY_VENDOR,
			this.PARTIALLY_BACKORDERED,
			this.ADJUSTED_BY_VENDOR,
			this.SUBSTITUTED_BY_VENDOR,
			this.SHIPPED,
			this.PARTIALLY_SHIPPED,
		]
	},

	getOrderItemIssueStatuses: function (): number[] {
		return [
			this.PARTIALLY_FULFILLED,
			this.BACKORDERED_BY_VENDOR,
			this.PARTIALLY_BACKORDERED,
			this.REJECTED_BY_VENDOR,
			this.ADJUSTED_BY_VENDOR,
			this.SUBSTITUTED_BY_VENDOR,
			this.PARTIALLY_SHIPPED,
		]
	},

	getAllOrderItemStatuses: function () {
		return [
			this.PENDING,
			this.PARTIALLY_FULFILLED,
			this.RECEIVED,
			this.PARTIALLY_RECEIVED,
			this.REJECTED,
			this.REJECTED_BY_VENDOR,
			this.BACKORDERED,
			this.BACKORDERED_BY_VENDOR,
			this.PARTIALLY_BACKORDERED,
			this.ADJUSTED_BY_VENDOR,
			this.SUBSTITUTED_BY_VENDOR,
			this.SHIPPED,
			this.PARTIALLY_SHIPPED,
		]
	},
}
