import styled from 'styled-components'
import { Card, CardBody } from 'reactstrap'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import SplitLayout from 'src/components/_layouts/split-layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LinkButton from 'src/components/_generic/buttons/link-button'
import IconButton from 'src/components/_generic/buttons/icon-button'
import AsyncSelect from 'src/components/_generic/forms/async-select/async-select'
import NewModal from 'src/components/_generic/new-modal/new-modal'
import ScrollLayout from 'src/components/_layouts/scroll-layout'
import SearchBar from 'src/components/Search/search-bar'
import AccountingSoftwareSyncStatusBadge from 'src/components/AccountingSoftware/Badges/AccountingSoftwareSyncStatusBadge'
import CardSection from 'src/components/_generic/cards/card-section'
import { Icon } from 'src/components/_generic/icons/icons.styles'

export const BaseCard = styled(Card)`
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	background: #ffffff;
`

export const AccountingSoftwareConnectionCard = styled(BaseCard)`
	max-width: ${(props) => (props?.isModal ? '252px' : '264px')};
	flex-grow: 1;
`

export const DimensionCard = styled(CardSection)`
	padding: 0;
	flex-grow: 1;
	width: 100%;
`

export const MappingCard = styled(CardSection)`
	flex-grow: 1;
	width: 100%;
`

export const SettingsCard = styled(BaseCard)`
	min-width: 56vw;
`

export const BaseFlexContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
`

export const AccountingSoftwareImageContainer = styled(BaseFlexContainer)`
	min-height: 124px;
	border-radius: 16px;
	border: 1px solid #dadee3;
	margin: 16px 20px;
	padding: 8px;
	justify-content: center;
`

export const ConnectionsSection = styled(BaseFlexContainer)`
	gap: ${(props) => (props?.isModal ? '28px 22px' : '24px')};
	margin: 12px auto ${(props) => (props?.isModal ? '' : '48px')};
`

export const DimensionCardFooter = styled(BaseFlexContainer)`
	padding: 12px 24px;
	justify-content: center;
`

export const MappingsSection = styled(BaseFlexContainer)`
	flex-direction: column;
	gap: 24px;
	margin: 12px auto 48px;
`

export const DimensionsSection = styled(MappingsSection)`
	margin-top: 24px;
`

export const MappingTitleSection = styled(BaseFlexContainer)`
	align-items: flex-start;
	gap: 12px;
	font-size: 20px;
`

const BaseBodyText = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #697888;
`

export const MappingDescription = styled(BaseBodyText)`
	font-size: 16px;
	line-height: 24px;
	color: #000000;
`

export const SectionDescription = styled(BaseBodyText)``
export const SettingDescription = styled(BaseBodyText)``

export const ModalDescription = styled(BaseBodyText)`
	font-size: 16px;
	line-height: 24px;
	color: #262c32;
	padding: 12px 48px;
	margin: 0px;
	border-bottom: solid 1px #dadee3;
`

export const SectionTitle = styled.h2`
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
`

export const DimensionHeaderTitle = styled.h2`
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
`

export const MappingSectionTitle = styled(SectionTitle)`
	font-weight: 600;
`

export const SettingTitle = styled.h3`
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #262c32;
`
export const AccountingSoftwareCardTitle = styled(SettingTitle)`
	font-size: 20px;
`

export const DimensionColumnTitle = styled.h3`
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
`

export const AccountingSoftwareButton = styled(PrimaryButton)`
	min-width: 100%;
	justify-content: center;
`

export const AlertLink = styled(LinkButton)`
	padding: 0px 0px 1px 0.25rem;
`

export const DimensionDetailsButton = styled(IconButton)`
	display: flex;
	color: #697888;
	font-size: 24px;

	&:hover {
		color: #00619e;
		background-color: #e1f3ff;
	}

	& > span > .fa-angle-right {
		margin-top: -1px;
	}
`

export const BreadcrumbArrow = styled(FontAwesomeIcon)`
	padding-right: 4px;
`

export const SuccessStatusIcon = styled(Icon)`
	color: #2a8d4b;
`

export const WarningStatusIcon = styled(Icon)`
	color: #e7ae1b;
`

export const ErrorStatusIcon = styled(Icon)`
	color: #d23f29;
`

export const LoadingStatusIcon = styled(Icon)`
	animation-duration: 600ms;
`

export const NotConnectedStatusIcon = styled(Icon)`
	color: #a1abb6;
`

export const AccountingSoftwareCardBadge = styled(
	AccountingSoftwareSyncStatusBadge
)`
	font-size: 16px;
`
export const SectionContainer = styled.div`
	margin: 24px auto 36px;
`

export const SettingItemSection = styled(SplitLayout)`
	margin: 20px 24px;
`

export const DimensionHeaderSection = styled(SplitLayout)`
	padding: 12px 24px;
	border-bottom: solid 1px #dadee3;
`

export const MultiOrgEntityHeaderSection = styled(SplitLayout)`
	padding: 12px 28px;
	border-bottom: solid 1px #dadee3;
`

export const CureMintDimension = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 12px;
	gap: 8px;
	background: #f1f3f4;

	border: 1px solid #bec5cc;
	border-radius: 8px;
`

export const DimensionHeaderColumn = styled.th`
	display: table-cell;
	width: auto;
`

export const DimensionColumn = styled.td`
	display: table-cell;
	width: auto;
`

export const DimensionArrowColumn = styled.td`
	display: table-cell;
	text-align: center;
`

const BaseTableSection = styled.div`
	& .table {
		table-layout: fixed;
		width: 100%;
	}

	& .table > tbody > tr:nth-child(odd) {
		background-color: inherit;
	}
`

export const DimensionTableSection = styled(BaseTableSection)`
	& .table > tbody > tr > td {
		padding: 20px;
	}

	& .table > thead > tr > th {
		padding: 20px;
		color: #000000;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
	}

	& .table > tbody > tr:nth-child(1) {
		padding-left: 4px;
	}

	& .table > thead > th:nth-child(1) {
		padding-left: 4px;
	}

	& table tr th:nth-child(2) {
		max-width: 92px;
		min-width: 92px;
		width: 92px;
	}
`

export const AccountingCategoryListTableSection = styled(BaseTableSection)`
	& .table {
		border-collapse: separate;
		border-spacing: 0;
	}

	& .table > thead {
		position: sticky;
		top: 0px;
		background-color: #fff;
		z-index: 1;
	}

	& .table > thead > tr > th {
		padding: 12px 6px;
		border-bottom: solid 1px #dadee3;
	}

	& .table > tbody > tr > td {
		padding: 6px;
	}

	& .table > thead > tr > th:nth-child(1) {
		width: 73px;
		padding-left: 48px;
	}

	& .table > tbody > tr > td:nth-child(1):not(.empty) {
		width: 73px;
		padding-left: 48px;
	}

	& .table > thead > tr > th:nth-child(2) {
		width: ${(props) => (props?.showCode ? '110px' : 'auto')};
	}

	& .table > tbody > tr > td:nth-child(2) {
		width: ${(props) => (props?.showCode ? '110px' : 'auto')};
	}
`

export const EntityConnectionTableSection = styled.div`
	& .table {
		table-layout: fixed;
		width: 100%;
	}

	& .table > thead > tr > th,
	& .table > tbody > tr > td {
		padding: 14px 8px;
		line-height: 21px;
	}

	& .table > thead > tr > th:nth-child(1),
	& .table > tbody > tr > td:nth-child(1):not(.empty) {
		width: 54px;
		padding-left: 28px;
		padding-right: 10px;
	}

	& .table > thead > tr > th:last-child,
	& .table > tbody > tr > td:last-child {
		width: 54px;
		padding-right: 28px;
	}

	& .table > thead > tr > th:nth-child(2),
	& .table > tbody > tr > td:nth-child(2) {
		width: 100px;
	}

	& .table > tbody > tr > td:nth-child(1):not(.empty) > span > svg {
		font-size: 16px;
	}
`

export const DimensionSelect = styled(AsyncSelect)`
	margin-bottom: 0;
`

export const BulkSelectionRowSection = styled(SplitLayout)`
	background-color: #f1f3f4;
`

export const AccountingSoftwareMappingModal = styled(NewModal)`
	.cm-modal-header {
		font-weight: 600;
		font-size: 36px;
		color: #000000;
		line-height: 46px;
		padding-left: 48px;
		border-bottom: solid 1px #dadee3;
	}

	.modal-body {
		padding: 0px;
		height: fit-content;
	}

	.cm-table-search-container {
		padding-left: 48px;
		border-bottom: solid 1px #dadee3;
	}

	.cm-table-search-input {
		margin-left: 2px;

		&::placeholder {
			color: #697888;
		}
	}
`

export const AddCMEntitiesScrollLayout = styled(ScrollLayout)`
	max-height: calc(100vh - 500px);
`

export const SelectAccountingSoftwareConnectionScrollLayout = styled(
	ScrollLayout
)`
	max-height: calc(100vh - 200px);
	padding: 16px 48px;
`

export const MultiOrgEntitySearchBar = styled(SearchBar)`
	&.cm-table-search-container {
		border-bottom: solid 1px #dadee3;
	}
`

export const MultiOrgEntityCardBody = styled(CardBody)`
	&.card-body {
		padding: 0px;
	}
`

export const AccountingSoftwareCardBody = styled.div`
	flex: 1 1 auto;
	min-height: 1px;
	padding: 0 20px 40px 20px;
`
