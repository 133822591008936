// @flow
import React, { useState } from 'react'
import { Button } from 'reactstrap'
import s3Image from 'src/library/s3Image'
import ImagePickerModal from 'src/components/_generic/image-picker/ImagePickerModal'
import Image from 'src/components/_generic/image'
import ConditionalRender from 'src/components/_generic/conditional-render'
import ClickBoundary from 'src/components/_generic/click-boundary'

type Props = {
	alt: string,
	imagePath: string,
	className?: string,
	fallbackImagePath: string,
	onImageUploaded: (string) => any,
	small?: boolean,
}

const ImagePicker = ({
	imagePath,
	fallbackImagePath,
	alt,
	onImageUploaded,
	small = false,
	className = '',
}: Props) => {
	const [isOpen, setIsOpen] = useState(false)

	const toggleModal = (e) => {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		setIsOpen(!isOpen)
	}

	return (
		<>
			<div
				className='cm-image-picker'
				onClick={toggleModal}
				onKeyPress={toggleModal}
				role='button'
				tabIndex='-1'
			>
				<Image
					alt={`${alt}`}
					className={`cm-image-picker-img ${className}`}
					src={s3Image(imagePath)}
					fallbackSrc={s3Image(fallbackImagePath)}
				/>
				<div className='cm-image-picker-overlay'>
					<div
						className={`cm-overlay-button${small ? '-small' : ''}`}
					>
						{small ? (
							<Button
								className='btn-just-icon'
								color='default'
								type='button'
								onClick={toggleModal}
							>
								<i className='cm-icon cm-icon-pencil' />
							</Button>
						) : (
							<Button
								className='btn-round'
								color='default'
								type='button'
								onClick={toggleModal}
							>
								<i className='cm-icon cm-icon-pencil pr-3' />
								Change Image
							</Button>
						)}
					</div>
				</div>
			</div>
			<ConditionalRender condition={isOpen}>
				<ClickBoundary>
					<ImagePickerModal
						isOpen={isOpen}
						onCloseClick={toggleModal}
						onImageUploaded={onImageUploaded}
					/>
				</ClickBoundary>
			</ConditionalRender>
		</>
	)
}

export default ImagePicker
