// @flow
import React, { useEffect } from 'react'
import { useGetLocationList } from 'src/api/locations/useGetLocationList'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import type { QueryModifierRenderType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierRenderType'
import Table from 'src/components/_generic/tables/table'
import EmptyFunc from 'src/library/emptyFunc'
import useTargetScrolling from 'src/hooks/useTargetScrolling'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import useLockBodyScroll from 'src/hooks/useLockBodyScroll'
import useCumulativeList from 'src/hooks/useCumulativeList'
import SelectCurrentLocationRow from 'src/components/TopNav/SecondaryNav/SelectCurrentLocation/select-current-location-row'

type Props = {
	modifiers: QueryModifierType,
	renders: QueryModifierRenderType,
	setters: QueryModifierSetterType,
	onClose: (boolean) => any,
}

const CurrentLocationForm = ({
	modifiers,
	renders,
	setters,
	onClose,
}: Props) => {
	const {
		locations: newResults,
		isLoading,
		paginatorInfo,
	} = useGetLocationList(modifiers)
	const { cumulativeList: locations } = useCumulativeList(newResults)
	const resultsRef = React.useRef()
	const { isNewAtBottom, handleScroll } = useTargetScrolling(resultsRef)
	useLockBodyScroll()

	useEffect(() => {
		if (isNewAtBottom && !isLoading) {
			setters.setPage(paginatorInfo?.currentPage + 1)
		}
	}, [isNewAtBottom])

	const renderListRow = (item) => (
		<SelectCurrentLocationRow
			key={`location-${item.id}`}
			location={item}
			onClose={onClose}
		/>
	)

	return (
		<>
			{renders.renderSearch('Search Locations')}
			<div
				ref={resultsRef}
				style={{ maxHeight: 300, overflowY: 'scroll' }}
				onScroll={handleScroll}
			>
				<Table
					dataSource={locations?.filter((location) =>
						location?.name
							?.toLowerCase()
							.includes(modifiers?.searchTarget?.toLowerCase())
					)}
					renderHeaderRow={EmptyFunc}
					renderItem={renderListRow}
					isLoading={false}
				/>
			</div>
		</>
	)
}

export default withQueryModifiers(CurrentLocationForm)
