// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

type Props = {
	children?: Node,
	failedRender?: Node,
	featureKey: string,
	limit?: number,
}

const FeatureRender = ({
	children,
	featureKey,
	failedRender,
	limit,
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const hasFeature = (featureKey) => {
		return authUser?.organization?.isFeatureEnabled(featureKey, limit)
	}

	if (!authUser) {
		return null
	}

	return (
		<ConditionalRender
			condition={() => hasFeature(featureKey)}
			failedRender={failedRender}
		>
			{children}
		</ConditionalRender>
	)
}

export default FeatureRender
