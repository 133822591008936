// @flow
import React from 'react'
import Spacer from 'src/components/_generic/spacer'

const CartPreviewEmptyView = () => {
	return (
		<div style={Style.container}>
			<i className='cm-icon cm-icon-cart cm-icon-lg' />
			<Spacer />
			<p>Your cart is empty</p>
		</div>
	)
}

export default CartPreviewEmptyView

const Style = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '50px 20px',
	},
}
