// @flow
import type { Node } from 'react'
import React from 'react'
import { Table as RSTable } from 'reactstrap'
import LoadingView from 'src/components/_generic/loading-view'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { handleVariableType } from 'src/components/_generic/utils'
import EmptyTableRow from 'src/components/_generic/tables/rows/empty-table-row'

export type TableProps = {
	dataSource: Array<Object>,
	renderHeaderRow?: () => any | Node,
	renderItem: (Object) => any | Node,
	renderEmpty?: () => any | Node,
	isLoading?: boolean,
	className?: '',
}

const Table = ({
	dataSource = [],
	renderHeaderRow = () => null,
	renderItem,
	isLoading = false,
	renderEmpty = (isLoading) => (
		<EmptyTableRow
			message={isLoading ? 'Loading...' : 'This list is empty.'}
		/>
	),
	className,
}: TableProps) => {
	return (
		<div className={`position-relative ${className}`}>
			<RSTable>
				<thead>
					<ConditionalRender condition={dataSource?.length > 0}>
						{handleVariableType(renderHeaderRow)}
					</ConditionalRender>
				</thead>
				<tbody>
					<ConditionalRender
						condition={dataSource?.length > 0}
						failedRender={handleVariableType(
							renderEmpty,
							isLoading
						)}
					>
						{dataSource?.map((item, index) =>
							handleVariableType(renderItem, item, index)
						)}
					</ConditionalRender>
				</tbody>
			</RSTable>
			<LoadingView
				className='cm-loading-view-center'
				loading={isLoading}
			/>
		</div>
	)
}

export default Table
