// @flow
import enums from 'src/configs/enums'

export const getComponentTypeFromHumanReadable = (
	humanReadable: string
): number => {
	switch (humanReadable) {
		case 'A purchaser of a location':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION
		case 'A purchaser of a group':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP
		case 'A purchaser of the organization':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ORG
		case 'All purchasers':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ALL
		case 'An admin of a location':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION
		case 'An admin of a group':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP
		case 'All admins':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_ALL
		case 'A location':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION
		case 'A group':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP
		case 'A user':
			return enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER
		case 'Purchased a product':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT
		case 'Purchased from a price book':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY
		case 'Purchased from a list':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST
		case 'Purchased from a vendor':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR
		case 'Purchased from a manufacturer':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_MANUFACTURER
		case 'Purchased from a line of business':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_LINE_OF_BUSINESS
		case 'Purchased from a chart of account':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_CHART_OF_ACCOUNT
		case 'Purchased off catalog product':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_PURCHASED_OFF_CATALOG
		case 'Purchased from a catalog':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG
		case 'Percentage of any budget':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET
		case 'Quantity of a product':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT
		case 'Quantity of any product from a price book':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY
		case 'Quantity of any product from a category':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY
		case 'Quantity of any product from a list':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST
		case 'Quantity of any product from a vendor':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR
		case 'Quantity of all product from a vendor':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_VENDOR_ORDER
		case 'Quantity of any product from a manufacturer':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_MANUFACTURER
		case 'Quantity of any product from a line of business':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_LINE_OF_BUSINESS
		case 'Quantity of any product from a chart of account':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_QUANTITY_CHART_OF_ACCOUNT
		case 'Quantity of any product from a catalog':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG
		case 'Total spent on an order':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER
		case 'Total spent with a vendor on an order':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR
		case 'Total spent with a manufacturer on an order':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER
		case 'Total spent on a product on an order':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT
		case 'Total spent on a line of business on an order':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_LINE_OF_BUSINESS
		case 'Total spent on a chart of account on an order':
			return enums.ORDER_RULE_COMPONENT_TYPE
				.CONDITION_SPEND_CHART_OF_ACCOUNT
		case 'Price of any product':
			return enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT
		case 'Send order and notify':
			return enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY
		case 'Block order until approved':
			return enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL

		default:
			return 'Unknown'
	}
}
