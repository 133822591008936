// @flow
import React from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import MultiSelect from 'src/components/_generic/forms/multi-select'
import type { CatalogType } from 'src/flowtypes/Entities/CatalogType'
import { useGetAllCatalogNames } from 'src/api/catalogs/useGetAllCatalogNames'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	filterFn?: (catalog: CatalogType) => any,
	includeAllOptions?: boolean,
}

const MultiSelectCatalogsDropdown = ({
	name,
	label,
	required,
	disabled,
	includeAllOptions,
	filterFn = () => true,
	onChange = () => {},
	...rest
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const { catalogs } = useGetAllCatalogNames(authUser?.organization?.id)
	const handleOnChange = (catalogs) => {
		onChange(catalogs?.length)
	}

	const selectAllOption = { value: 0, label: 'All Catalogs' }
	const options = [...catalogs]

	if (includeAllOptions) options.unshift(selectAllOption)

	return (
		<MultiSelect
			options={options?.filter(filterFn)}
			label={label}
			name={name}
			required={required}
			disabled={disabled}
			onChange={(catalogs) => handleOnChange(catalogs)}
			{...rest}
		/>
	)
}

export default MultiSelectCatalogsDropdown
