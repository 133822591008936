// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

type Props = {
	children?: Node,
	failedRender?: Node,
	permissions: Array<string>,
	overridePermissions?: boolean,
}

const PermissionRender = ({
	children,
	permissions,
	failedRender,
	overridePermissions = false,
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const hasPermissions = (permissions) => {
		if (!permissions || permissions.length === 0) {
			return true
		}
		for (let i = 0; i < permissions.length; i++) {
			if (authUser.hasPermission(permissions[i])) {
				return true
			}
		}
		return false
	}

	if (!authUser) {
		return null
	}

	return (
		<ConditionalRender
			condition={() => hasPermissions(permissions) || overridePermissions}
			failedRender={failedRender}
		>
			{children}
		</ConditionalRender>
	)
}

export default PermissionRender
