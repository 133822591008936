// @flow
import React from 'react'
import { withAuthenticator } from 'aws-amplify-react'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import { Formik } from 'formik'
import GlobalSearchLayout from 'src/components/TopNav/GlobalSearch/SearchForm/global-search-layout'

const GlobalSearchForm = ({
	children,
	className = '',
	handleSubmit = () => {},
	initialValues = { search: '' },
}) => {
	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={initialValues}
			enableReinitialize
		>
			{(props) => (
				<form
					onSubmit={props.handleSubmit}
					className={`form-inline flex-grow-1 ${className}`}
					autoComplete='off'
				>
					<GlobalSearchLayout>{children}</GlobalSearchLayout>
				</form>
			)}
		</Formik>
	)
}
export default withQueryModifiers(
	withAuthenticator(GlobalSearchForm),
	null,
	[],
	12
)
