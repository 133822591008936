// @flow

export const serverUrl = process.env.REACT_APP_LARAVEL_SERVER_URL
export const DEV_MODE =
	process.env.REACT_APP_ENV === 'development' ||
	process.env.REACT_APP_ENV === 'testing' ||
	(typeof window !== 'undefined' &&
		(window?.location?.hostname === 'localhost' ||
			window?.location?.hostname === '127.0.0.1'))

export default {
	appPrefix: '',
	graphQLUrl: process.env.REACT_APP_GRAPH_QL_URL,
	serverUrl,
	env: process.env.REACT_APP_ENV,
}

export const SEARCH_DEBOUNCE_MS = Number(
	process.env.REACT_APP_DEBOUNCE_SEARCH_MS || 250
)

export const SUBSCRIPTION_CONFIGS = {
	appKey: process.env.REACT_APP_PUSHER_APP_KEY,
	options: {
		cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || 'us2',
		authEndpoint:
			process.env.REACT_APP_PUSHER_SUBSCRIPTION_AUTH ||
			'http://localhost:8000/graphql/subscriptions/auth',
	},
}

export const PUSHER_CONFIGS = {
	appKey: process.env.REACT_APP_PUSHER_APP_KEY,
	options: {
		cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || 'us2',
		authEndpoint:
			process.env.REACT_APP_PUSHER_CHANNEL_AUTH ||
			'http://localhost:8000/broadcasting/auth',
	},
}

// If not defined, use the test client ID. Client IDs are not secret as per LaunchDarkly's documentation.
export const LD_CLIENT_ID =
	process.env.REACT_APP_LD_CLIENT_ID || '60cc77c3f68b450bc5809e7a'

// If not defined, use the sandbox url
export const unitUrl = process.env.REACT_APP_UNIT_URL || 'https://api.s.unit.sh'
