// @flow
import React from 'react'
import ConfigTools from 'src/configs/ConfigTools'
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
	ErrorStatusIcon,
	LoadingStatusIcon,
	NotConnectedStatusIcon,
	SuccessStatusIcon,
	WarningStatusIcon,
} from 'src/components/AccountingSoftware/AccountingSoftware.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'src/components/_generic/badges/badge'
import enums from 'src/configs/enums'

export default ConfigTools({
	NOT_CONNECTED: {
		value: 4,
		name: 'not_connected',
		label: 'Not Connected',
		icon: <NotConnectedStatusIcon icon={solid('ban')} />,
		badge: (
			<Badge
				title={'Not Connected'}
				color={'default'}
				icon={<FontAwesomeIcon icon={solid('ban')} />}
			/>
		),
		reason: 'There is no entity associated with this bill',
	},
	UNABLE_TO_SYNC: {
		value: 5,
		name: 'unable_to_sync',
		label: 'Unable To Sync',
		icon: <ErrorStatusIcon icon={solid('diamond-exclamation')} />,
		badge: (
			<Badge
				title={'Unable To Sync'}
				color={'danger'}
				icon={<FontAwesomeIcon icon={solid('diamond-exclamation')} />}
			/>
		),
		reason: 'Unable to sync with Accounting Software',
	},
	NOT_SYNCED: {
		value: 0,
		name: 'not_synced',
		label: 'Not Synced',
		icon: null,
		badge: <Badge title={'Not Synced'} color={'default'} />,
		reason: null,
	},
	SYNCED: {
		value: 2,
		name: 'synced',
		label: 'Synced',
		icon: <SuccessStatusIcon icon={solid('circle-check')} />,
		badge: (
			<Badge
				title={'Synced'}
				color={'success'}
				icon={<FontAwesomeIcon icon={solid('circle-check')} />}
			/>
		),
		reason: null,
	},
	OUT_OF_SYNC: {
		value: 6,
		name: 'out_of_sync',
		label: 'Out Of Sync',
		icon: <WarningStatusIcon icon={solid('exclamation-triangle')} />,
		badge: (
			<Badge
				title={'Out Of Sync'}
				color={'warning'}
				icon={<FontAwesomeIcon icon={solid('warning')} />}
			/>
		),
		reason: null,
	},
	SYNC_ERRORED: {
		value: 3,
		name: 'sync_errored',
		label: 'Sync Error',
		icon: <ErrorStatusIcon icon={solid('rotate-exclamation')} />,
		badge: (
			<Badge
				title={'Sync Error'}
				color={'danger'}
				icon={<FontAwesomeIcon icon={solid('rotate-exclamation')} />}
			/>
		),
		reason: null,
	},
	SYNCING: {
		value: 1,
		name: 'syncing',
		label: 'Syncing',
		icon: <LoadingStatusIcon icon={duotone('loader')} spinPulse />,
		badge: (
			<Badge
				title={'Syncing'}
				color={'default'}
				icon={<FontAwesomeIcon icon={solid('sync')} />}
			/>
		),
		reason: 'Bill is currently syncing',
	},
	getUnsyncableStatusesValues: function () {
		return [
			enums.billSyncStatuses.SYNCING.value,
			enums.billSyncStatuses.NOT_CONNECTED.value,
			enums.billSyncStatuses.UNABLE_TO_SYNC.value,
		]
	},
})
