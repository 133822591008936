// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { UncontrolledPopover } from 'reactstrap'

type Props = {
	label: string,
	value: string | number | Node,
	percentOfMax?: number | string,
	targetId: string,
}

const ProspectiveOrderBudgetProgressBarPopover = ({
	label = '',
	value,
	percentOfMax,
	targetId,
}: Props) => {
	return (
		<UncontrolledPopover
			trigger={'hover'}
			placement={'bottom'}
			target={targetId}
		>
			<div style={Style.container}>
				<p style={Style.label}>{label}</p>
				<div
					style={{
						width: '100%',
						borderBottom: '1px solid #eee',
						marginBottom: '10px',
					}}
				/>
				<div style={Style.leftContainer}>
					<p className={'text-left'} style={Style.values}>
						{`$${value.toFixed(2)}`}
					</p>
					<ConditionalRender condition={!!percentOfMax}>
						<p className={'text-left'} style={Style.values}>
							{percentOfMax}%
						</p>
					</ConditionalRender>
				</div>
			</div>
		</UncontrolledPopover>
	)
}

export default ProspectiveOrderBudgetProgressBarPopover

const Style = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		padding: '10px',
		fontSize: '12px',
	},
	leftContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		width: '100%',
	},
	label: {
		fontSize: '12px',
		fontWeight: 500,
	},
	values: {
		fontSize: '12px',
	},
}
