// @flow
import version from 'src/configs/version'
import bugsnagClient from 'src/library/Bugsnag/bugsnag-client'

const reportBugsnagError = (err: Error, toastMessage?: string, user = null) => {
	if (process.env.REACT_APP_BUGSNAG_ENABLED) {
		bugsnagClient.notify(err, {
			user: {
				id: user?.id,
				permissions: user?.permissions,
			},
			beforeSend: function (report) {
				report.app.version = version
			},
		})
	}
}

export default reportBugsnagError
