// @flow
import React from 'react'
import Spacer from 'src/components/_generic/spacer'
import ProspectiveOrderBudgetProgressBar from 'src/components/Budgets/Checkout/ProgressBar/prospective-order-budget-progress-bar'
import { SkeletonText } from 'src/components/_generic/skeletons'
import type { BudgetType } from 'src/flowtypes/Entities/Budgets/BudgetType'

type Props = {
	isLoading: boolean,
	budgets: [BudgetType],
}
const ProspectiveOrderBudgetsBreakdown = ({ isLoading, budgets }: Props) => {
	return (
		<>
			<SkeletonText
				width={80}
				height={10}
				space={10}
				isLoading={isLoading}
			/>
			<SkeletonText
				width={40}
				height={10}
				space={10}
				isLoading={isLoading}
			/>
			<SkeletonText width={250} height={20} isLoading={isLoading}>
				{budgets?.map((budget) => (
					<ProspectiveOrderBudgetProgressBar
						key={`prospective-order-budget-progress-${budget?.budget_allotment_id}`}
						budget={budget}
						style={styles.progressBar}
					/>
				))}
				<Spacer />
				<hr style={styles.bottomBuffer} />
			</SkeletonText>
		</>
	)
}

export default ProspectiveOrderBudgetsBreakdown

const styles = {
	progressBar: {
		height: '20px',
	},
	bottomBuffer: {
		marginBottom: '20px',
	},
}
