// @flow
import React, { useState } from 'react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { useField } from 'formik'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	name: string,
	defaultValue?: number | string,
	onChange: (any) => any,
	onIncrement: () => any,
	onDecrement: () => any,
	height?: number,
	disabled?: boolean,
}

const NumberIncrementInput = ({
	name,
	onChange,
	onIncrement = () => {},
	onDecrement = () => {},
	disabled = false,
	height = 40,
	...rest
}: Props) => {
	const [{ value }] = useField(name)
	const [isHover, setIsHover] = useState(false)
	const handleDecrement = () => {
		if (!disabled) onDecrement()
	}

	const handleIncrement = () => {
		if (!disabled) onIncrement()
	}

	const handleMouseEnter = () => {
		setIsHover(true)
	}
	const handleMouseLeave = () => {
		setIsHover(false)
	}
	return (
		<InputGroup
			className={'cm-number-increment-input'}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<InputGroupAddon addonType='prepend'>
				<InputGroupText
					className='cm-number-increment-input-button'
					onClick={handleDecrement}
					style={{ height }}
				>
					-
				</InputGroupText>
			</InputGroupAddon>
			<NumberFormat
				name={name}
				isNumericString={false}
				thousandSeparator={false}
				decimalScale={0}
				className={'form-control cm-number-increment-input-input'}
				onChange={onChange}
				disabled={disabled}
				style={{ height }}
				{...rest}
			/>
			<InputGroupAddon addonType='append'>
				<InputGroupText
					className='cm-number-increment-input-button'
					onClick={handleIncrement}
					style={{ height }}
				>
					+
				</InputGroupText>
			</InputGroupAddon>
			<ConditionalRender condition={isHover && +value >= 10000}>
				<span className='cm-number-increment-input-hover-label'>
					{value}
				</span>
			</ConditionalRender>
		</InputGroup>
	)
}

export default NumberIncrementInput
