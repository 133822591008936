// @flow
import React from 'react'

type Props = {
	valuesSum: number,
	max: number,
}

const ProgressBarPercentage = ({ valuesSum, max }: Props) => {
	return (
		<>
			{valuesSum / max > 0.95 ? (
				<p
					style={{
						fontWeight: 'bold',
						color: '#fff',
						position: 'absolute',
						marginLeft: 10,
						right: 5,
						marginTop: -21,
						pointerEvents: 'none',
					}}
				>
					{Math.round((100 * valuesSum) / max)}%
				</p>
			) : (
				<p
					style={{
						position: 'absolute',
						marginLeft: 10,
						left: `${100 * (valuesSum / max)}%`,
						marginTop: -20,
						pointerEvents: 'none',
					}}
				>
					{Math.round((100 * valuesSum) / max)}%
				</p>
			)}
		</>
	)
}

export default ProgressBarPercentage
