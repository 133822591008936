import React, { createContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDropzoneError } from 'src/pages/_components/Dropzone/useDropzoneError'
import { DropzoneRoot } from 'src/pages/_components/Dropzone/Dropzone.styles'
import { AttachFileTypes } from 'src/configs/Enums/AttachFileTypes'

export const DropzoneContext = createContext()

type Props = {
	onDrop: (acceptedFiles: File[]) => void,
	onDropRejected: (rejectedFiles: File[]) => void,
	acceptedFileTypes?: Object,
	maxSize?: number,
	disabled?: boolean,
	children?: React.ReactNode,
}

export const DropzoneContextProvider = ({
	onDrop,
	onDropRejected,
	acceptFileTypes = AttachFileTypes.acceptAllFileTypes,
	maxSize = 10000000,
	disabled = false,
	children,
}: Props) => {
	const [dropState, setDropstate] = useState({
		isDragging: false,
		hasErrors: false,
		errorMessage: null,
	})

	const { hasErrored, errorMessage, sizeError, fileTypeError, clearErrors } =
		useDropzoneError(acceptFileTypes.asArray, maxSize)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (file) => {
			clearErrors()
			onDrop(file)
		},
		onDropRejected: (files) => {
			onDropRejected(files)
			hasErrored(files)
		},
		accept: acceptFileTypes?.accept,
		maxSize: maxSize,
	})

	useEffect(() => {
		setDropstate({
			isDragging: isDragActive,
			hasErrors: !!(sizeError || fileTypeError),
			errorMessage: !!(sizeError || fileTypeError)
				? errorMessage()
				: null,
		})
	}, [isDragActive, sizeError, fileTypeError])

	useEffect(() => {
		if (dropState.hasErrors) {
			setTimeout(() => {
				clearErrors()
			}, 8000)
		}
	}, [dropState.hasErrors])

	return (
		<DropzoneRoot {...getRootProps()}>
			<input {...getInputProps()} tabIndex='-1' disabled={disabled} />
			<DropzoneContext.Provider value={dropState}>
				{children}
			</DropzoneContext.Provider>
		</DropzoneRoot>
	)
}
