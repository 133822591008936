import { gql } from 'graphql-request'
import Product from 'src/apollo/_entities/product'

export const GetProductQuery = {
	identifier: 'get_product',
	gql: gql`
		query product($product_id: ID!) {
			product(product_id: $product_id) {
				id
				sku
				name
				description
				packaging_details
				image_path
				is_verified
				is_favorite
				is_deletable
				vendor {
					id
					name
					image_path
					has_live_prices
					has_stock_levels
					organization_vendor {
						id
						order_email
						account_number
						connection_status
						can_place_order
						has_temporary_data_lockout
					}
				}
				shopping_lists {
					id
					name
				}
				available_prices {
					id
					price
					formulary {
						id
						name
						owner_id
					}
				}
				base_product {
					id
					manufacturer_sku
					name
					description
					packaging_details
					image_path
					manufacturer {
						id
						name
					}
					parent_category {
						id
						name
					}
					primary_category {
						id
						name
					}
					products {
						id
						sku
						vendor {
							name
							image_path
						}
						available_prices {
							id
							price
							formulary {
								id
								name
								owner_id
							}
						}
						current_stock_level {
							id
							status
							quantity
							availability_date
							updated_at
						}
					}
				}
				current_stock_level {
					id
					status
					quantity
					availability_date
					updated_at
				}
			}
		}
	`,
	variables: ({ product_id }) => ({ product_id }),
	formatResults: (data) => {
		return Product(data?.product) ?? null
	},
}
