// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { StyledDropdownItem } from 'src/pages/_components/Dropdowns/_styles/dropdown.styles'

export type DropdownItemProps = {
	disabled?: boolean,
	icon?: Node,
	title?: string | Node,
	onClick: () => any,
}

const DropdownItem = ({
	icon,
	title,
	disabled = false,
	onClick = () => null,
	...rest
}: DropdownItemProps) => {
	return (
		<StyledDropdownItem disabled={disabled} onClick={onClick} {...rest}>
			<ConditionalRender condition={!!icon}>{icon}</ConditionalRender>
			<ConditionalRender condition={!!title}>
				{!!title ? title : '-'}
			</ConditionalRender>
		</StyledDropdownItem>
	)
}

export default DropdownItem
