// @flow
import React from 'react'

type Props = {
	shouldDisplay: boolean,
}

const TransparentOverlay = ({ shouldDisplay }: Props) => (
	<div className={`${shouldDisplay ? 'cm-mobile-overlay' : ''}`} />
)

export default TransparentOverlay
