// @flow
import { useQuery } from 'src/api/index'
import { GetShoppingListsQuery } from 'src/api/shopping_lists/get-shopping-lists-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import ShoppingList from 'src/api/shopping_lists/_entities/ShoppingList'

export const useGetShoppingLists = (modifiers: QueryModifierType) => {
	const { data, isLoading } = useQuery({
		key: ['shopping-lists', { modifiers }],
		request: GetShoppingListsQuery,
		options: {
			enabled: !!modifiers,
			staleTime: 1000 * 60 * 10,
		},
	})

	const lists = data?.data?.map((list) => ShoppingList(list)) ?? []
	const paginatorInfo = data?.paginatorInfo ?? {
		lastPage: 1,
	}

	return { lists, isLoading, paginatorInfo }
}
