// @flow
import React from 'react'
import { EntityConnectionContext } from 'src/pages/entities/_contexts/EntityConnectionContext'
import ConditionalRender from 'src/components/_generic/conditional-render'

export default {
	billNumber: {
		label: 'Bill #',
		columnName: 'bill_number',
	},
	entity: {
		label: 'Entity',
		columnName: 'organization_entity',
		wrapper: (content) => (
			<EntityConnectionContext.Consumer>
				{(connectionStatus) => (
					<ConditionalRender
						condition={connectionStatus?.has_multiple_entities}
					>
						{content}
					</ConditionalRender>
				)}
			</EntityConnectionContext.Consumer>
		),
	},
	vendor: {
		label: 'Vendor',
		columnName: 'vendor',
	},
	locationName: {
		label: 'Ship To',
		columnName: 'location',
	},
	amount: {
		label: 'Amount',
		columnName: 'amount',
		className: 'text-right',
	},
	billDate: {
		label: 'Bill Date',
		columnName: 'bill_date',
		className: 'text-center',
	},
	dueDate: {
		label: 'Due Date',
		columnName: 'due_date',
		className: 'text-center',
	},
	billBuddy: {
		label: 'Bill Buddy',
		columnName: null,
		className: 'text-center',
	},
	paymentStatus: {
		label: 'Payment Status',
		columnName: 'payment_status',
		className: 'text-center',
	},
}
