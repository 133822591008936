import React from 'react'
import { connectHighlight } from 'react-instantsearch/connectors'

function HighlightPart({
	children,
	highlightedTagName,
	isHighlighted,
	nonHighlightedTagName,
}) {
	const TagName = isHighlighted ? highlightedTagName : nonHighlightedTagName

	return (
		<TagName
			className={
				isHighlighted
					? 'ais-Highlight--highlighted'
					: 'ais-Highlight--nonHighlighted'
			}
			style={isHighlighted ? Styles.highlightStyle : {}}
		>
			{children}
		</TagName>
	)
}

export default connectHighlight(
	({
		hit,
		highlight,
		attribute,
		highlightedTagName = 'mark',
		nonHighlightedTagName = 'span',
		...rest
	}) => {
		const parsedHit = highlight({
			highlightProperty: '_highlightResult',
			attribute,
			hit,
		})

		return (
			<span {...rest} className='ais-Highlight'>
				{parsedHit.map((part, partIndex) => {
					return (
						<HighlightPart
							key={partIndex}
							highlightedTagName={highlightedTagName}
							nonHighlightedTagName={nonHighlightedTagName}
							isHighlighted={part.isHighlighted}
						>
							{part.value}
						</HighlightPart>
					)
				})}
			</span>
		)
	}
)

const Styles = {
	highlightStyle: {
		backgroundColor: '#D7EFFF',
	},
}
