import { useGetManyOrgVendors } from 'src/api/organization_vendors/useGetManyOrgVendors'

export const useCheckoutStateHelpers = (checkoutState, dispatch) => {
	const { org_vendors: vendors } = useGetManyOrgVendors([
		...new Set(
			checkoutState?.cart?.items.map((cartItem) => cartItem?.vendor_id)
		),
	])

	const setCurrentStep = (step) =>
		dispatch({ type: 'set-current-step', payload: step })

	const getBillingInstructions = (id) => {
		let found = vendors?.find((vendor) => vendor?.vendor?.id === id)
		return found && found?.billing_instructions
			? found?.billing_instructions
			: `No billing instructions have been provided for this vendor, so we'll
                            instruct them to bill the billing address on file.`
	}

	const getShippingRules = (id) => {
		return vendors?.find((vendor) => vendor?.vendor?.id === id)
			?.shipping_rules
	}

	const getCartVendors = (cart) => {
		return cart?.items?.reduce((accumulator, cartItem) => {
			if (
				accumulator.filter((item) => item?.id === cartItem?.vendor?.id)
					.length === 0
			) {
				accumulator.push({
					id: cartItem?.vendor?.id,
					name: cartItem?.vendor?.name,
					cart_items: cart?.items?.filter(
						(item) => item.vendor_id === cartItem?.vendor?.id ?? []
					),
				})
			}

			return accumulator
		}, [])
	}

	return {
		setCurrentStep,
		getBillingInstructions,
		getShippingRules,
		getCartVendors,
	}
}
