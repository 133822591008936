// @flow
import { useState } from 'react'

export default function useBooleanState(initialState) {
	const [value, setValue] = useState(initialState)

	const setTrue = () => setValue(true)
	const setFalse = () => setValue(false)

	return [value, setTrue, setFalse]
}
