// @flow
import React from 'react'
import { Popover } from 'reactstrap'
import popoverSizeConfigs from 'src/configs/popover-sizing'
import CardHeader from 'src/components/_generic/cards/card-header'
import IconButton from 'src/components/_generic/buttons/icon-button'
import useDidClickOutsideHook from 'src/hooks/useDidClickOutsideHook'
import useBooleanState from 'src/hooks/useBooleanState'
import CurrentLocationForm from 'src/components/TopNav/SecondaryNav/SelectCurrentLocation/CurrentLocationForm'
import { useGetLocationList } from 'src/api/locations/useGetLocationList'
import { DefaultQueryModifiers } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

const CurrentLocationNavItem = () => {
	const { isLoading } = useGetLocationList(DefaultQueryModifiers)
	const { authUser } = useGetAuthenticatedUser()

	const [isOpen, setIsOpenTrue, setIsOpenFalse] = useBooleanState(false)
	const hoverRef = React.useRef()
	useDidClickOutsideHook(hoverRef, setIsOpenFalse)

	const targetId = 'select-current-location-button'

	const renderLocationName = () => {
		return !isLoading ? (
			<span id={targetId} onClick={setIsOpenTrue}>
				<i className='cm-icon cm-icon-position-pin align-middle mr-1' />{' '}
				{authUser?.current_location?.name ?? 'Set Purchase Location'}
			</span>
		) : null
	}

	return (
		<ConditionalRender
			condition={!isLoading}
			failedRender={renderLocationName()}
		>
			<div className={'cm-topnav-secondary-link'}>
				{renderLocationName()}
				<Popover
					trigger={'hover'}
					placement={'bottom'}
					isOpen={isOpen}
					target={targetId}
					className='select-current-location-button'
					style={{ overflowY: 'hidden' }}
					hideArrow
					modifiers={{
						computeStyle: {
							fn: (data) => popoverSizeConfigs.fixedWidth(data),
						},
					}}
				>
					<div ref={hoverRef}>
						<CardHeader
							title={'Shop For Location'}
							rightContent={
								<IconButton
									icon={
										<i
											className={'cm-icon cm-icon-close'}
											style={{ fontSize: '20px' }}
										/>
									}
									onClick={setIsOpenFalse}
									color={'transparent'}
									className={'cm-close-cart-preview'}
								/>
							}
						/>
						<CurrentLocationForm onClose={setIsOpenFalse} />
					</div>
				</Popover>
			</div>
		</ConditionalRender>
	)
}

export default CurrentLocationNavItem
