// @flow
import { getIn, useField, useFormikContext } from 'formik'
import React from 'react'
import InputLabel from 'src/components/_generic/forms/input-label'
import ConditionalRender from 'src/components/_generic/conditional-render'
import withFormikProtection from 'src/components/_generic/forms/withFormikProtection'
import { FormErrorMessage } from 'src/pages/_styles/text.styles'
import { StyledAsyncSelect } from 'src/pages/_styles/form.styles'
import type { AsyncInputProps } from 'src/pages/_components/Inputs/_types/FormFieldProps'
import isNullOrUndefined from 'src/library/isNullOrUndefined'

export const AsyncSelect = ({
	options,
	label,
	name = '',
	required = false,
	disabled = false,
	loading = false,
	placeholder = 'Select',
	components = {},
	onLoadMore = () => {},
	onChange = () => {},
	noOptionsMessage = ({ inputValue }) => `No matches found for ${inputValue}`,
	formInputClass = '',
	searchTarget = '',
	onInputChange,
	hideDropdownIndicator = false,
	maxMenuHeight = 300,
	onMenuScrollToBottom = () => {},
	menuPortalTarget = undefined,
	isClearable = undefined,
	styles = {},
	containerClassName,
	className = '',
	minMenuWidth = undefined,
	menuEdge = undefined,
	...rest
}: AsyncInputProps) => {
	const [field] = useField(name)
	const { errors, touched, setFieldValue, setFieldTouched } =
		useFormikContext()

	const error = getIn(errors, name)
	const touch = getIn(touched, name)

	const handleOnBlur = (e) => {
		if (typeof field?.onBlur === 'function') {
			field.onBlur(e)
		}
		setFieldTouched(name)
	}
	const customStyles = {
		container: (provided) => ({
			...provided,
			backgroundColor: '#fff',
		}),
		indicatorsContainer: (provided) => ({
			...provided,
			...(styles?.indicatorsContainer ?? {}),
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			...(hideDropdownIndicator ? Styles.noDisplay : {}),
		}),
		menu: (provided) => {
			const passedDownMenuStyles = styles.hasOwnProperty('menu')
				? styles.menu
				: {}

			return {
				...provided,
				minWidth: minMenuWidth,
				marginLeft: !!minMenuWidth ? 8 : undefined,
				...passedDownMenuStyles,
			}
		},
		menuPortal: (provided) => {
			const passedDownMenuStyles = styles.hasOwnProperty('menu')
				? styles.menu
				: {}

			const leftCalc =
				menuEdge === 'right'
					? provided?.left - (minMenuWidth - provided?.width)
					: provided?.left

			return {
				...provided,
				minWidth: minMenuWidth,
				marginLeft: !!minMenuWidth ? 8 : undefined,
				left: leftCalc,
				...passedDownMenuStyles,
			}
		},
		option: (provided) => ({
			...provided,
			overflowWrap: 'break-word',
		}),
	}

	const isGroupedOptions = options?.length > 0 ? options?.[0]?.options : false
	const flattenedGroupOptions = [].concat(
		...options?.map(({ options }) => options)
	)
	const valueOptions = isGroupedOptions ? flattenedGroupOptions : options

	const portalElement = !isNullOrUndefined(menuPortalTarget)
		? typeof menuPortalTarget !== 'string'
			? menuPortalTarget
			: menuPortalTarget?.length > 0
			? document.getElementById(menuPortalTarget)
			: undefined
		: undefined

	return (
		<div
			className={
				typeof containerClassName !== 'undefined'
					? String(containerClassName).toString() + ' ' + className
					: `${formInputClass} ${className}`
			}
		>
			<ConditionalRender condition={label}>
				<InputLabel required={required}>{label}</InputLabel>
			</ConditionalRender>
			<StyledAsyncSelect
				cacheOptions={rest?.cacheOptions ?? true}
				defaultOptions={options}
				loadOptions={onLoadMore}
				className={`react-select react-select-default ${
					!!(touch && error) ? 'cm-select-has-danger' : ''
				}`}
				classNamePrefix='react-select'
				name={field.name}
				value={
					valueOptions
						? valueOptions.find(
								(option) => option?.value === field?.value
						  ) ?? null
						: ''
				}
				onChange={(option) => {
					setFieldValue && setFieldValue(field.name, option?.value)
					onChange && onChange(option)
				}}
				placeholder={placeholder}
				isDisabled={disabled}
				isLoading={loading}
				noOptionsMessage={noOptionsMessage}
				inputValue={searchTarget}
				onInputChange={onInputChange}
				onBlur={(e) => handleOnBlur(e)}
				inputProps={{ 'aria-label': label }}
				components={components}
				styles={customStyles}
				onMenuScrollToBottom={onMenuScrollToBottom}
				isClearable={isClearable}
				maxMenuHeight={maxMenuHeight}
				menuPortalTarget={portalElement}
				minMenuWidth={minMenuWidth}
				{...rest}
			/>
			<ConditionalRender condition={touch && error}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</ConditionalRender>
		</div>
	)
}

export default withFormikProtection(AsyncSelect)

const Styles = {
	noDisplay: {
		display: 'none',
	},
}
