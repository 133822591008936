// @flow
import { useReducer } from 'react'

const CheckoutStateReducer = (state, action) => {
	switch (action.type) {
		case 'set-current-step':
			return {
				...state,
				current_step: action.payload,
			}
		default:
			return {
				...state,
			}
	}
}

const useCheckoutStateReducer = () => {
	return useReducer(CheckoutStateReducer, {
		current_step: 1,
		has_set_custom_lob: false,
	})
}

export default useCheckoutStateReducer
