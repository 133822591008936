// @flow
import { hot } from 'react-hot-loader/root'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { default as apolloClient } from 'apollo/apollo-client'
import 'assets/scss/theme-override.scss'
import 'rc-slider/assets/index.css'
import 'react-multi-carousel/lib/styles.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-responsive-modal/styles.css'
import 'react-phone-number-input/style.css'
import 'assets/css/prism.css'
import 'assets/css/app.css'
import 'assets/fonts/css/style.css'
import GlobalStateProvider from 'src/library/useGlobalState/GlobalStateProvider'
import ChatterProvider from 'src/library/Chatter/ChatterProvider'
import { Authenticator, Loading, RequireNewPassword } from 'aws-amplify-react'
import AuthSignIn from 'src/components/Auth/AuthSignIn'
import AuthConfirmSignUp from 'src/components/Auth/AuthConfirmSignUp'
import AuthForgotPassword from 'src/components/Auth/AuthForgotPassword'
import Amplify from 'aws-amplify'
import AwsConfigs from 'src/configs/aws'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { QueryClientProvider } from 'react-query'
import { GlobalSearchProvider } from 'src/components/TopNav/GlobalSearch/GlobalSearchProvider'
import App from 'src/navigation/App'
import { queryClient } from 'src/_boot/query-client'
import bugsnagClient from 'src/library/Bugsnag/bugsnag-client'
import ErrorBoundary from 'src/components/_generic/errors/error-boundary'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { ToastProvider } from 'src/_boot/ToastContext'

require('dotenv').config()

Amplify.configure(AwsConfigs.cognito)
const BugSnagBoundery = bugsnagClient.getPlugin('react')

const CureMintApp = ({ authState, location }) => (
	<ConditionalRender
		condition={authState === 'signedIn' || authState === 'verifyContact'}
	>
		<DndProvider backend={HTML5Backend}>
			<GlobalSearchProvider>
				<ErrorBoundary>
					<BugSnagBoundery>
						<App location={location} />
					</BugSnagBoundery>
				</ErrorBoundary>
			</GlobalSearchProvider>
		</DndProvider>
	</ConditionalRender>
)

const AuthApp = () => (
	<Authenticator hideDefault>
		<AuthSignIn override={'SignIn'} />
		<RequireNewPassword />
		<AuthConfirmSignUp override={'ConfirmSignUp'} />
		<AuthForgotPassword override={'ForgotPassword'} />
		<Loading />
		<CureMintApp />
	</Authenticator>
)

const RootRouter = hot(() => (
	<BrowserRouter>
		<Route path='/' component={AuthApp} />
	</BrowserRouter>
))

ReactDOM.render(
	<ApolloProvider client={apolloClient}>
		<Suspense fallback={<div>Loading...</div>}>
			<GlobalStateProvider>
				<QueryClientProvider client={queryClient}>
					<ToastProvider>
						<ChatterProvider>
							<RootRouter />
						</ChatterProvider>
					</ToastProvider>
				</QueryClientProvider>
			</GlobalStateProvider>
		</Suspense>
	</ApolloProvider>,
	document.getElementById('root')
)
