import { gql } from 'graphql-request'

export const RemoveFavoriteMutation = {
	identifier: 'remove_favorite',
	gql: gql`
		mutation removeFavoriteProduct($product_id: ID!) {
			removeFavoriteProduct(product_id: $product_id) {
				id
				is_favorite
			}
		}
	`,
	variables: (product_id) => ({ product_id }),
	formatResults: (data) => data?.removeFavoriteProduct?.id,
	onSuccess: (key, queryClient) => {
		queryClient.invalidateQueries(['favorite_products'])
	},
	optimisticUpdate: (key, queryClient, state, data) => {
		state.splice(state.indexOf('' + data), 1)
		queryClient.setQueryData(key, state)
	},
}
