import React from 'react'
import CloseIconButton from 'src/components/_generic/buttons/close-icon-button'
import SkeletonSettingsCard from 'src/components/_generic/skeletons/skeleton-settings-card'
import CmDevToolsLayout from 'src/components/CMDevTools/cm-dev-tools-layout'
import CMDevToolsView from 'src/components/CMDevTools/Content/CMDevToolsView'

type Props = {
	close: () => any,
}

const CMDevTools = ({ close }: Props) => {
	return (
		<SkeletonSettingsCard isLoading={false}>
			<CmDevToolsLayout>
				<CloseIconButton
					style={Styles.close}
					lid='close-button'
					onClick={close}
				/>
				<CMDevToolsView lid={'content'} />
			</CmDevToolsLayout>
		</SkeletonSettingsCard>
	)
}

export default CMDevTools

const Styles = {
	close: {
		margin: '10px',
	},
}
