// @flow
import React from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DropzonePromptIcon } from 'src/components/_generic/dropzone/dropzone.styles'

const DropzonePromptDragging = () => (
	<>
		<DropzonePromptIcon icon={solid('bullseye-pointer')} size={'xl'} />
		<h3>Drop file here!</h3>
	</>
)

export default DropzonePromptDragging
