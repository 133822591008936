// @flow
import React, { useState } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import Form from 'src/components/_generic/forms/form'
import Validators from 'src/configs/ValidationModels/auth'
import CardSection from 'src/components/_generic/cards/card-section'
import Input from 'src/components/_generic/forms/input'
import Button from 'reactstrap/lib/Button'
import AuthLayout from 'src/navigation/_layouts/AuthLayout'
import Authenticator from 'src/library/Authenticator/Authenticator'

const AuthForgotPassword = ({ authState, authData, onStateChange }) => {
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState()
	const { forgotPassword, forgotPasswordSubmit } = Authenticator(
		onStateChange
	)

	const onSignInClicked = () => {
		onStateChange('signIn')
	}

	const handleResetPassword = ({ email }) => {
		setLoading(true)
		forgotPassword(email?.trim()).then(() => {
			setEmail(email?.trim())
		})
		setLoading(false)
	}

	const handleResetPassowrdVerifyCode = async (newCredentials) => {
		setLoading(true)
		await forgotPasswordSubmit({ ...newCredentials, email })
		setLoading(false)
	}

	return (
		<ConditionalRender condition={authState === 'forgotPassword'}>
			<AuthLayout>
				<CardSection cardProps={{ padding: 60, width: 400 }}>
					<h3 className={'ml-auto mr-auto cm-mb-double'}>
						Reset Password
					</h3>
					<ConditionalRender condition={!email}>
						<Form
							initialValues={{ email: authData?.email }}
							validator={Validators.auth.forgotPasswordRequest}
							onSubmit={handleResetPassword}
						>
							<Input name='email' label='Email' required />
							<PrimaryButton
								className='btn-block cm-mt-double cm-mb-double'
								title='Request Password Reset Code'
								type='submit'
								disabled={loading}
								onClick={() => {}}
							/>
						</Form>
					</ConditionalRender>
					<ConditionalRender condition={!!email}>
						<Form
							initialValues={{ code: '', new_password: '' }}
							validator={Validators.auth.forgotPasswordVerifyCode}
							onSubmit={handleResetPassowrdVerifyCode}
						>
							<Input
								name='code'
								label='Verification Code'
								required
							/>
							<Input
								name='new_password'
								label='New Password'
								type='password'
								required
							/>

							<PrimaryButton
								className='btn-block cm-mt-double cm-mb-double'
								title='Reset Password'
								type='submit'
								disabled={loading}
								onClick={() => {}}
							/>
						</Form>
					</ConditionalRender>

					<Button
						className='btn btn-link'
						color='primary'
						onClick={onSignInClicked}
					>
						Sign In
					</Button>
				</CardSection>
			</AuthLayout>
		</ConditionalRender>
	)
}

export default AuthForgotPassword
