// @flow
import { Query, useQuery } from 'src/api/index'
import { GetAccountingCodesQuery } from 'src/api/accounting_codes/get-accounting-codes-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import AccountingCode from 'src/apollo/_entities/accountingCode'
import { useQueryClient } from 'react-query'

export const useGetAccountingCodes = (modifiers: QueryModifierType) => {
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery({
		key: ['accounting_codes', { modifiers }],
		request: GetAccountingCodesQuery,
		options: {
			staleTime: 1000 * 60 * 10,
		},
	})

	const getAccountingCodes = async (modifiers: QueryModifierType) => {
		return await queryClient.fetchQuery(
			['accounting_codes', { modifiers }],
			Query(GetAccountingCodesQuery)
		)
	}

	const accounting_codes =
		data?.data?.map((code) => AccountingCode(code)) ?? []

	const paginatorInfo = data?.paginatorInfo ?? { lastPage: 1 }

	return { accounting_codes, getAccountingCodes, isLoading, paginatorInfo }
}
