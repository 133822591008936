// @flow
import React, { useCallback } from 'react'
import SyncStatuses from 'src/configs/Enums/AccountingSoftware/SyncStatuses'
import {
	ErrorStatusIcon,
	LoadingStatusIcon,
	SuccessStatusIcon,
	WarningStatusIcon,
} from 'src/components/AccountingSoftware/AccountingSoftware.styles'
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import IconWithTooltip from 'src/components/_generic/icons/tooltip-icon'
import Badge from 'src/components/_generic/badges/badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccountingSoftwareContext } from 'src/pages/entities/_contexts/AccountingSoftwareContext'

type Props = {
	type?: 'badge' | 'icon',
	withTooltip?: boolean,
	classname?: string,
	dimensionType?: 'chartOfAccounts' | 'suppliers' | 'trackingCategories',
}

const AccountingSoftwareSyncStatusBadge = ({
	type = 'badge',
	withTooltip = false,
	className = '',
	dimensionType,
}: Props) => {
	const renderBadge = (syncStatus, dataChanged, refreshTriggered) => {
		switch (syncStatus) {
			case SyncStatuses.COMPLETED.name:
				return dataChanged ? (
					<Badge
						title={SyncStatuses.WARNING.label}
						color={'warning'}
						icon={<FontAwesomeIcon icon={solid('warning')} />}
					/>
				) : refreshTriggered ? (
					<Badge
						title={SyncStatuses.SYNCING.label}
						color={'default'}
						icon={<FontAwesomeIcon icon={solid('sync')} />}
					/>
				) : (
					<Badge
						title={SyncStatuses.COMPLETED.label}
						color={'success'}
						icon={<FontAwesomeIcon icon={solid('circle-check')} />}
					/>
				)
			case SyncStatuses.SYNCING.name:
				return (
					<Badge
						title={SyncStatuses.SYNCING.label}
						color={'default'}
						icon={<FontAwesomeIcon icon={solid('sync')} />}
					/>
				)
			case SyncStatuses.ERROR.name:
				return (
					<Badge
						title={SyncStatuses.ERROR.label}
						color={'danger'}
						icon={
							<FontAwesomeIcon
								icon={solid('diamond-exclamation')}
							/>
						}
					/>
				)
			default:
				return <></>
		}
	}

	const renderStatusIcon = (syncStatus, dataChanged, refreshTriggered) => {
		switch (syncStatus) {
			case SyncStatuses.COMPLETED.name:
				return dataChanged ? (
					<WarningStatusIcon icon={solid('exclamation-triangle')} />
				) : refreshTriggered ? (
					<LoadingStatusIcon icon={duotone('loader')} spinPulse />
				) : (
					<SuccessStatusIcon icon={solid('circle-check')} />
				)
			case SyncStatuses.SYNCING.name:
				return <LoadingStatusIcon icon={duotone('loader')} spinPulse />
			case SyncStatuses.ERROR.name:
				return <ErrorStatusIcon icon={solid('diamond-exclamation')} />
			default:
				return <></>
		}
	}

	const renderTooltip = useCallback(
		(syncStatus, dataChanged, refreshTriggered) => {
			switch (syncStatus) {
				case SyncStatuses.COMPLETED.name:
					return dataChanged
						? SyncStatuses.WARNING.label
						: refreshTriggered
						? SyncStatuses.SYNCING.label
						: SyncStatuses.COMPLETED.label
				case SyncStatuses.SYNCING.name:
					return SyncStatuses.SYNCING.label
				case SyncStatuses.ERROR.name:
					return SyncStatuses.ERROR.label
				default:
					return ''
			}
		},
		[]
	)

	const renderTypeWithTooltip = (
		syncStatus,
		dataChanged,
		refreshTriggered
	) => {
		return (
			<IconWithTooltip
				tooltipText={renderTooltip(
					syncStatus,
					dataChanged,
					refreshTriggered
				)}
				tooltipPlacement={'top'}
			>
				{type === 'badge'
					? renderBadge({ syncStatus, dataChanged, refreshTriggered })
					: renderStatusIcon(
							syncStatus,
							dataChanged,
							refreshTriggered
					  )}
			</IconWithTooltip>
		)
	}

	return (
		<AccountingSoftwareContext.Consumer>
			{({
				accountingSoftwareSyncStatus,
				dataChanged,
				refreshTriggered,
				getDimensionSettings,
			}) => {
				const dimension =
					dimensionType?.length > 0 &&
					typeof getDimensionSettings === 'function'
						? getDimensionSettings(dimensionType)
						: null

				const syncStatusData = !!dimension
					? {
							dataChanged: dimension?.data_changed,
							refreshTriggered: dimension?.refresh_triggered,
							syncStatus: dimension?.sync_status,
					  }
					: {
							dataChanged: dataChanged,
							refreshTriggered: refreshTriggered,
							syncStatus: accountingSoftwareSyncStatus,
					  }

				return (
					<span className={className}>
						{!!withTooltip
							? renderTypeWithTooltip(
									syncStatusData.syncStatus,
									syncStatusData.dataChanged,
									syncStatusData.refreshTriggered
							  )
							: type === 'badge'
							? renderBadge(
									syncStatusData.syncStatus,
									syncStatusData.dataChanged,
									syncStatusData.refreshTriggered
							  )
							: renderStatusIcon(
									syncStatusData.syncStatus,
									syncStatusData.dataChanged,
									syncStatusData.refreshTriggered
							  )}
					</span>
				)
			}}
		</AccountingSoftwareContext.Consumer>
	)
}

export default AccountingSoftwareSyncStatusBadge
