// @flow
import React, { useState } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import Form from 'src/components/_generic/forms/form'
import Validators from 'src/configs/ValidationModels/auth'
import CardSection from 'src/components/_generic/cards/card-section'
import Input from 'src/components/_generic/forms/input'
import AuthLayout from 'src/navigation/_layouts/AuthLayout'
import CureMintLogo from 'src/components/_generic/curemint-logo'
import Authenticator from 'src/library/Authenticator/Authenticator'
import { useComponentDidMount } from 'src/hooks/index'
import AppConfigs from 'src/configs/app'
import { Auth } from 'aws-amplify'
import { useLocation } from 'react-router-dom'
import useGlobalState from 'src/library/useGlobalState/useGlobalState'
import { FlexColumn } from 'src/pages/_styles/container.styles'
import LinkButton from 'src/components/_generic/buttons/link-button'

const AuthSignIn = ({ authState, authData, onStateChange }) => {
	const [loading, setLoading] = useState(false)
	const [siteStatus, setSiteStatus] = useState({ status: 'online' })
	const [isOverride, setIsOverride] = useGlobalState(
		'maintenance-override',
		false,
		true
	)
	const { signIn } = Authenticator(onStateChange)

	const location = useLocation()

	useComponentDidMount(() => {
		if (!isOverride && location?.search === '?override=true') {
			setIsOverride(true)
		}

		fetch(`${AppConfigs.serverUrl}/api/status`)
			.then((response) => response.json())
			.then((json) => {
				if (json?.status === 'online') {
					setSiteStatus(json)
				} else if (json?.status === 'offline') {
					setSiteStatus(json)
					if (!isOverride) {
						Auth.signOut({ global: true })
							.then(() => {})
							.catch(() => {})
					}
				}
			})
	})

	const onResetPassword = () => {
		onStateChange('forgotPassword')
	}

	const handleSignIn = async (credentials) => {
		setLoading(true)
		await signIn(credentials)
		setLoading(false)
	}

	if (authState === 'signIn') {
		window.scrollTo(0, 0)
	}

	return (
		<ConditionalRender condition={authState === 'signIn'}>
			<AuthLayout>
				<ConditionalRender
					condition={siteStatus?.status === 'online' || isOverride}
					failedRender={
						<CardSection cardProps={{ padding: 60, width: 400 }}>
							{siteStatus?.message} <br />
						</CardSection>
					}
				>
					<CardSection cardProps={{ padding: 40, width: 400 }}>
						<FlexColumn>
							<CureMintLogo className='ml-auto mr-auto cm-mb-double' />
							<Form
								initialValues={{
									email: authData?.email || '',
									password: '',
								}}
								validator={Validators.auth.login}
								onSubmit={handleSignIn}
							>
								<Input name='email' label='Email' required />
								<Input
									name='password'
									label='Password'
									type='password'
									required
								/>
								<PrimaryButton
									className='btn-block cm-mt-double cm-mb-double'
									title='Sign In'
									type='submit'
									disabled={loading}
									onClick={() => {}}
								/>
							</Form>

							<LinkButton
								color='primary'
								onClick={onResetPassword}
								title='Reset password'
							/>
						</FlexColumn>
					</CardSection>
				</ConditionalRender>
			</AuthLayout>
		</ConditionalRender>
	)
}

export default AuthSignIn
