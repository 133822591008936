import { useEffect, useState } from 'react'

export const useSearchCache = () => {
	const [shouldRefresh, setShouldRefresh] = useState(false)

	useEffect(() => {
		if (shouldRefresh) {
			setShouldRefresh(false)
		}
	}, [shouldRefresh])

	const refresh = () => {
		setShouldRefresh(true)
	}

	return {
		shouldRefresh,
		refresh,
	}
}
