// @flow
import { Query, useQuery } from 'src/api/index'
import { GetProjectedSpendByBudgetQuery } from 'src/api/cart_items/get-projected-spend-by-budget-query'
import { useQueryClient } from 'react-query'

type ProjectedSpendInputType = {
	location_id: string | number,
	should_reassociate: boolean,
}

export const useGetProjectedSpendByBudget = (
	input: ProjectedSpendInputType,
	enabled = false
) => {
	const queryClient = useQueryClient()

	const { data: budgets, isLoading } = useQuery({
		key: ['projected_spend_by_budget', input],
		request: GetProjectedSpendByBudgetQuery,
		options: {
			staleTime: 1000 * 60 * 10,
			enabled,
		},
	})

	const getProjectedSpendByProduct = async (
		input: ProjectedSpendInputType
	) => {
		return await queryClient.fetchQuery(
			['projected_spend_by_budget', input],
			Query(GetProjectedSpendByBudgetQuery, queryClient)
		)
	}

	return { budgets, isLoading, getProjectedSpendByProduct }
}
