// @flow
import { useMutation } from 'src/api/index'
import { AwsPresignedUploadMutation } from 'src/api/aws/aws-presigned-upload-mutation'

export const useAwsPresignedUpload = (options = {}) => {
	return useMutation({
		key: 'aws_presigned_upload',
		request: AwsPresignedUploadMutation,
		options,
	})
}
