// @flow
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Navbar } from 'reactstrap'
import SideMenu from 'src/components/SideMenu/SideMenu'
import TopNavBar from 'src/components/TopNav/TopNavBar'
import GlobalSearch from 'src/components/TopNav/GlobalSearch/GlobalSearch'
import SideMenuCloseButton from 'src/components/SideMenu/side-menu-close-button'
import useGlobalState from 'src/library/useGlobalState/useGlobalState'
import ConditionalRender from 'src/components/_generic/conditional-render'
import SecondaryNavBar from 'src/components/TopNav/SecondaryNav/SecondaryNavBar'
import { withRightSidePanel } from 'src/components/RightSidePanel/withRightSidePanel'
import DisabledOverlay from 'src/components/_generic/disabled-overlay'

type Props = {
	isDisabledOverlay?: boolean,
}

const AppLayout = ({ children, isDisabledOverlay }: Props) => {
	const [isExpanded, setIsExpanded] = useGlobalState(
		'side-menu-expanded-state',
		true,
		true
	)
	const history = useHistory()
	const toggleSideMenu = () => {
		setIsExpanded(!isExpanded)
	}

	const renderSecondaryNav = () => {
		return (
			<ConditionalRender
				condition={history?.location?.pathname?.includes(
					'/procure/store'
				)}
			>
				<SecondaryNavBar sideNavIsOpen={isExpanded} />
			</ConditionalRender>
		)
	}

	return (
		<>
			<SideMenu isExpanded={isExpanded} />
			<div
				className={`cm-super-slidey-content ${
					isExpanded
						? 'cm-super-slidey-bar-content-expanded'
						: 'cm-super-slidey-bar-visible'
				}`}
			>
				<DisabledOverlay disabled={isDisabledOverlay}>
					<span className='float-left' style={Styles.topNavBarMargin}>
						&nbsp;
					</span>
					<TopNavBar
						sideNavIsOpen={isExpanded}
						renderSecondaryNav={renderSecondaryNav}
					/>
					<Navbar
						className='navbar-light cm-topnav-mobile-search'
						expand='lg'
					>
						<GlobalSearch id='mobile-global-search' />
					</Navbar>
					<div
						className={`cm-app-content`}
						style={
							history?.location?.pathname?.includes(
								'/procure/store'
							)
								? Styles.secondaryNavHeader
								: Styles.noSecondaryNavHeader
						}
					>
						{children}
					</div>
				</DisabledOverlay>
			</div>
			<SideMenuCloseButton
				isExpanded={isExpanded}
				onClick={toggleSideMenu}
			/>
		</>
	)
}

export default withRightSidePanel(AppLayout)

const Styles = {
	topNavBarMargin: {
		marginRight: 50,
	},
	secondaryNavHeader: {
		marginTop: '100px',
	},
	noSecondaryNavHeader: {
		marginTop: '64px',
	},
}
