// @flow
import React from 'react'
import { StatusText } from 'src/components/_generic/labels/StatusTextLabel.styles'

type Props = {
	label: string,
	color?: string,
}

const StatusTextLabel = ({
	label,
	color = 'var(--brand-text-dark)',
	...rest
}: Props) => {
	return (
		<StatusText color={color} {...rest}>
			{label}
		</StatusText>
	)
}

export default StatusTextLabel
