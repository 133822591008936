// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { DropzoneContext } from 'src/pages/_components/Dropzone/DropzoneContext'
import DropzonePromptError from 'src/components/_generic/dropzone/dropzone-prompt-error'

const DropzoneErrorBoundary = ({
	children,
	component = DropzonePromptError,
}) => {
	const Component = component
	return (
		<DropzoneContext.Consumer>
			{({ hasErrors, errorMessage }) => (
				<ConditionalRender
					condition={hasErrors}
					failedRender={children}
				>
					<Component message={errorMessage} />
				</ConditionalRender>
			)}
		</DropzoneContext.Consumer>
	)
}

export default DropzoneErrorBoundary
