// @flow
import type { Node } from 'react'
import React from 'react'

type Props = {
	children?: Node,
}

const ClickBoundary = ({ children }: Props) => {
	return <div onClick={(e) => e.stopPropagation()}>{children}</div>
}

export default ClickBoundary
