import useGlobalState from 'src/library/useGlobalState/useGlobalState'

export const useVirtualExclusions = (key) => {
	const [exclusions, setExclusions] = useGlobalState(
		`${key}-exclusions`,
		[],
		true,
		300
	)

	const addExclusions = (results: string[]) => {
		setExclusions([...exclusions, ...results])
	}

	const removeExclusions = (results: string[]) => {
		setExclusions(exclusions?.filter((item) => !results.includes(item)))
	}

	return {
		exclusions,
		addExclusions,
		removeExclusions,
	}
}
