import { gql } from 'graphql-request'

export const GetOrgHasProductTagsQuery = {
	identifier: 'get-org-has-product-tags',
	gql: gql`
		query getOrgHasProductTags($organization_id: ID!) {
			orgHasProductTags(organization_id: $organization_id)
		}
	`,

	formatResults: (data) => {
		return data?.orgHasProductTags
	},
}
