// @flow
import React from 'react'
import type { CartItemType } from 'src/flowtypes/Entities/CartItemType'
import MoneyFormatter from 'src/library/MoneyFormatter'
import {
	CartPreviewName,
	CartPreviewUnitPrice,
} from 'src/components/Cart/CartText.styles'

type Props = {
	cart_item: CartItemType,
}

const CartPreviewItemDetails = ({ cart_item }: Props) => {
	return (
		<>
			<CartPreviewUnitPrice>
				${MoneyFormatter.toDollars(cart_item?.unit_price)}
			</CartPreviewUnitPrice>
			<CartPreviewName>{cart_item?.name}</CartPreviewName>
			<p className={'cm-cart-preview-details-text'}>
				Qty: {cart_item?.quantity}
			</p>
		</>
	)
}

export default CartPreviewItemDetails
