// @flow
import React, { useRef } from 'react'
import ResponsivePopover from 'src/components/_generic/popovers/responsive-popover'
import SearchResultsList from 'src/components/TopNav/GlobalSearch/Results/search-results-list'
import { useGlobalSearch } from 'src/components/TopNav/GlobalSearch/GlobalSearchProvider'
import { connectHits } from 'react-instantsearch/connectors'
import Product from 'src/apollo/_entities/product'

type Props = {
	isOpen: boolean,
	setIsOpen: (boolean) => any,
	targetId: string,
}

const GlobalSearchResults = ({ hits, isOpen, setIsOpen, targetId }: Props) => {
	const { modifiers } = useGlobalSearch()
	const resultsRef = useRef()
	const products = hits?.map((product) => Product(product))

	return (
		<ResponsivePopover
			isOpen={isOpen && modifiers?.searchTarget?.length > 3}
			placement={'match-reference'}
			targetId={targetId}
		>
			<SearchResultsList
				aria-expanded={isOpen}
				products={products}
				onClose={() => setIsOpen(false)}
				loading={false}
				ref={resultsRef}
			/>
		</ResponsivePopover>
	)
}
export default connectHits(GlobalSearchResults)
