// @flow
import React from 'react'

type Props = {
	vertical?: boolean,
	style?: Object,
	className?: String,
}

const Divider = ({ vertical = false, style = {}, className = '' }: Props) => {
	if (vertical) {
		return (
			<span
				className={className}
				style={{ ...Styles.vertical, ...style }}
			/>
		)
	} else {
		return <hr className={className} style={{ ...style }} />
	}
}

export default Divider

const Styles = {
	vertical: {
		backgroundColor: '#d0d0d0',
		width: 1,
		height: '60%',
		margin: '15px 10px',
	},
}
