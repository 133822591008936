export const ProductDataFragment = `
		id
		sku
		name
		description
		packaging_details
		image_path
		is_verified
		is_favorite
		is_deletable
		vendor {
			id
			name
		}
		shopping_lists {
			id
			name
		}
		available_prices {
			id
			price
			formulary {
				id
				name
				owner_id
			}
		}
		base_product {
			id
			manufacturer_sku
			name
			description
			packaging_details
			image_path
			manufacturer {
				id
				name
			}
			parent_category {
				id
				name
			}
			primary_category {
				id
				name
			}
		}
		global_tags {
			id
			tag {
				id
				name
				descriptor
				owning_organization_id
			}
		}
		org_tags {
			id
			tag {
				id
				name
				descriptor
				owning_organization_id
			}
		}
		current_stock_level {
		    id
		    status
		    quantity
		    availability_date
		    updated_at
		}
`
