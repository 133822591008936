// @flow
import React from 'react'
import ProspectiveOrderBudgetProgressBarPopover from 'src/components/Budgets/Checkout/ProgressBar/prospective-order-budget-progress-bar-popover'

type Props = {
	budget: Object,
	targetId: string,
}

const CurrentSpendPopover = ({ budget, targetId }: Props) => {
	const { budget_allotment_id, budget_amount, current_spend } = budget
	return (
		<ProspectiveOrderBudgetProgressBarPopover
			key={`current-spend-popover-${budget_allotment_id}-2`}
			label={'Monthly Spend'}
			value={current_spend}
			percentOfMax={((current_spend / budget_amount) * 100).toFixed(2)}
			targetId={targetId}
		/>
	)
}

export default CurrentSpendPopover
