// @flow
import React from 'react'
import { Nav, Navbar, NavItem } from 'reactstrap'
import TopNavbarUserMenu from 'src/components/TopNav/top-navbar-user-menu'
import GlobalSearch from 'src/components/TopNav/GlobalSearch/GlobalSearch'
import CureMintLogo from 'src/components/_generic/curemint-logo'
import Divider from 'src/components/_generic/divider'
import TopNavbarCart from 'src/components/TopNav/top-navbar-cart'
import SplitLayout from 'src/components/_layouts/split-layout'
import { Link } from 'react-router-dom'
import VerticalLayout from 'src/components/_layouts/vertical-layout'
import EmptyFunc from 'src/library/emptyFunc'
import DisabledOverlay from 'src/components/_generic/disabled-overlay'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

type Props = {
	sideNavIsOpen: boolean,
	renderSecondaryNav?: () => any,
}

const TopNavBar = ({
	sideNavIsOpen,
	renderSecondaryNav = EmptyFunc,
}: Props) => {
	const { authUser, isLoading } = useGetAuthenticatedUser()
	return (
		<Navbar
			className={`navbar-light cm-topnav
            ${renderSecondaryNav() === null ? 'cm-topnav-marketplace' : ''}
            ${sideNavIsOpen ? 'cm-topnav-side-open' : ''}`}
			expand='lg'
		>
			<VerticalLayout>
				<SplitLayout
					leftContent={
						<Nav className='flex-grow-1 justify-content-center'>
							<div
								className={`cm-mobile-hidden ${
									sideNavIsOpen
										? 'cm-topnav-logo-side-open'
										: 'cm-topnav-logo'
								}`}
							>
								<NavItem>
									<Link to={'/'}>
										<CureMintLogo
											style={{
												margin: '0px auto 0px auto',
												padding: '20px 0px 20px 80px',
											}}
										/>
									</Link>
								</NavItem>
							</div>
							<GlobalSearch className='cm-mobile-hidden cm-ml-double' />
							<NavItem>
								<Link to={'/'}>
									<CureMintLogo
										className='cm-full-hidden'
										style={{
											margin: '0px auto 0px auto',
											padding: '20px 0 20px 0',
										}}
									/>
								</Link>
							</NavItem>
						</Nav>
					}
					rightContent={
						<Nav>
							<div className='cm-mobile-hidden'>
								<TopNavbarUserMenu />
								<Divider vertical />
							</div>
							<DisabledOverlay
								disabled={
									!isLoading && !authUser?.current_location_id
								}
							>
								<TopNavbarCart />
							</DisabledOverlay>
						</Nav>
					}
					rightStyles={{
						marginLeft: 15,
						marginRight: 15,
						flex: 'none',
					}}
				/>
				{renderSecondaryNav()}
			</VerticalLayout>
		</Navbar>
	)
}

export default TopNavBar
