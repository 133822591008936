// @flow
import type { Node } from 'react'
import React from 'react'
import SplitLayout from 'src/components/_layouts/split-layout'
import { ErrorBoundary } from 'src/components/_generic/errors'
import ConditionalRender from 'src/components/_generic/conditional-render'
import {
	AlertBannerSection,
	BadgeSection,
	BreadcrumbSection,
	PageTitle,
} from 'src/components/_generic/pages/page.styles'
import PageActions from 'src/components/_generic/pages/_components/page-actions'
import MissingPhoneNumberNotice from 'src/components/MyAccount/missing-phone-number-notice'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useLocation } from 'react-router-dom'

type Props = {
	title?: string,
	pageActions?: Node,
	children?: Node,
	style?: Object,
	breadcrumbs?: Node,
	badge?: Node,
	alertBanner?: Node,
	footer?: Node,
}

const Page = ({
	title,
	pageActions = null,
	children,
	style = {},
	breadcrumbs = null,
	badge = null,
	alertBanner = null,
	footer = null,
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const location = useLocation()

	let formattedTitle = ''
	if (title) {
		const splitTitle = title.split(' ')
		formattedTitle = splitTitle
			.map((word) => {
				return word[0].toUpperCase() + word.substring(1)
			})
			.join(' ')
	}

	return (
		<ErrorBoundary isPage>
			<div className='cm-page' style={style}>
				<header className='cm-mt-normal cm-mb-normal'>
					<ConditionalRender condition={!!alertBanner}>
						<ConditionalRender
							condition={
								!!location &&
								!location.pathname.includes('/my/account')
							}
						>
							<MissingPhoneNumberNotice
								isOpen={
									!!authUser &&
									!!authUser.isInactiveBankingCustomer()
								}
							/>
						</ConditionalRender>
						<AlertBannerSection hasBreadcrumbs={!!breadcrumbs}>
							{alertBanner}
						</AlertBannerSection>
					</ConditionalRender>
					<ConditionalRender condition={!!breadcrumbs}>
						<BreadcrumbSection>
							{breadcrumbs ? breadcrumbs : null}
						</BreadcrumbSection>
					</ConditionalRender>
					<ConditionalRender condition={title || pageActions}>
						<SplitLayout
							leftContent={
								<PageTitle>
									{title ? formattedTitle : ''}
								</PageTitle>
							}
							rightContent={
								<PageActions>{pageActions}</PageActions>
							}
						/>
					</ConditionalRender>
					<ConditionalRender condition={badge}>
						<BadgeSection>{badge ? badge : null}</BadgeSection>
					</ConditionalRender>
				</header>
				<main>{children}</main>
				<footer>{footer}</footer>
			</div>
		</ErrorBoundary>
	)
}

export default Page
