// @flow
import enums from 'src/configs/enums'

export const getHumanReadableComponentType = (
	componentType: number
): string => {
	switch (componentType) {
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION:
			return 'A purchaser of a location'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP:
			return 'A purchaser of a group'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ORG:
			return 'A purchaser of the organization'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_ALL:
			return 'All purchasers'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION:
			return 'An admin of a location'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP:
			return 'An admin of a group'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_ALL:
			return 'All admins'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION:
			return 'A location'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP:
			return 'A group'
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER:
			return 'A user'

		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT:
			return 'Purchased a product'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY:
			return 'Purchased from a price book'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST:
			return 'Purchased from a list'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR:
			return 'Purchased from a vendor'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_MANUFACTURER:
			return 'Purchased from a manufacturer'
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_PURCHASED_LINE_OF_BUSINESS:
			return 'Purchased from a line of business'
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_PURCHASED_CHART_OF_ACCOUNT:
			return 'Purchased from a chart of account'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_OFF_CATALOG:
			return 'Purchased off catalog product'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG:
			return 'Purchased from a catalog'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET:
			return 'Percentage of any budget'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT:
			return 'Quantity of a product'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY:
			return 'Quantity of any product from a price book'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY:
			return 'Quantity of any product from a category'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST:
			return 'Quantity of any product from a list'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR:
			return 'Quantity of any product from a vendor'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR_ORDER:
			return 'Quantity of all product from a vendor'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_MANUFACTURER:
			return 'Quantity of any product from a manufacturer'
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_QUANTITY_LINE_OF_BUSINESS:
			return 'Quantity of any product from a line of business'
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_QUANTITY_CHART_OF_ACCOUNT:
			return 'Quantity of any product from a chart of account'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG:
			return 'Quantity of any product from a catalog'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER:
			return 'Total spent on an order'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR:
			return 'Total spent with a vendor on an order'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER:
			return 'Total spent with a manufacturer on an order'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT:
			return 'Total spent on a product on an order'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_LINE_OF_BUSINESS:
			return 'Total spent on a line of business on an order'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_CHART_OF_ACCOUNT:
			return 'Total spent on a chart of account on an order'
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT:
			return 'Price of any product'

		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY:
			return 'Send order and notify'
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY_USER:
			return 'Send order and notify specific user'
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL:
			return 'Block order until approved'
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL_USER:
			return 'Block order until approved by specific user'

		default:
			return 'A user'
	}
}
