import AppConfigs from 'src/configs/app'
import { GraphQLClient } from 'graphql-request'
import { Auth } from 'aws-amplify'
import {
	QueryClient,
	useMutation as useRQMutation,
	useQuery as useRQQuery,
	useQueryClient,
} from 'react-query'
import { useContext, useRef } from 'react'
import { useComponentDidMount } from 'src/hooks/index'
import { errorHandlers } from 'src/api/_generic/gql-error-handlers'
import { SubscriptionContext } from 'src/_boot/SubscriptionContext'
import { getUnixTime } from 'date-fns'

export type QueryRequest = {
	identifier: any,
	gql: string,
	formatResults?: (any) => Promise<any>,
}
export type QueryType = {
	key: any,
	request: QueryRequest,
	options: Object,
}

export type MutationRequest = {
	identifier: any,
	gql: string,
	variables?: (any | Object) => Object,
	formatResults?: (any) => Promise<any>,
	onError?: (
		key: string,
		queryClient: Object,
		err: Object,
		data: Object,
		context: Object
	) => any,
	onSuccess?: (
		key: string,
		queryClient: Object,
		data: Object,
		variables: Object,
		context: Object
	) => any,
	onMutate?: (key: any, queryClient: Object, data: any) => Object,
	optimisticUpdate?: (
		key: any,
		queryClient: Object,
		oldData: any,
		newData: any
	) => any,
}
export type MutationType = {
	key: any,
	request: MutationRequest,
	options: Object,
}

export const Query = (
	request: QueryRequest,
	queryClient: ?QueryClient = undefined
) => {
	return async ({ queryKey }) => {
		const session = await Auth.currentSession()
		const [, data] = queryKey

		const gqlClient = new GraphQLClient(
			`${AppConfigs.graphQLUrl}?qi=${request?.identifier}`,
			{
				headers: {
					authorization: `Bearer ${session?.accessToken?.jwtToken}`,
					'x-timestamp': getUnixTime(new Date()),
				},
			}
		)
		const variables = request?.variables ? request?.variables(data) : data

		return gqlClient
			?.request(request?.gql, variables)
			.then((results) => {
				return request?.formatResults
					? request?.formatResults(results)
					: results
			})
			.catch((err) => handleGQLErrors(err, queryClient))
	}
}

export const useQuery = ({ key, request, options = {} }: QueryType) => {
	const queryClient = useQueryClient()
	const [, variables] = key

	return useRQQuery(key ?? request?.identifier, Query(request, queryClient), {
		onSuccess: (data) => {
			return request?.onSuccess
				? request?.onSuccess(key, queryClient, data, variables)
				: null
		},
		onError: (err) => {
			const defaultOnError =
				queryClient?.getDefaultOptions()?.queries?.onError
			if (!!defaultOnError) {
				defaultOnError(err)
			}
		},
		...options,
	})
}

export const Mutation = (
	request: MutationRequest,
	queryClient: ?QueryClient = undefined
) => {
	return async (data) => {
		const session = await Auth.currentSession()

		const gqlClient = new GraphQLClient(
			`${AppConfigs.graphQLUrl}?qi=${request?.identifier}`,
			{
				headers: {
					authorization: `Bearer ${session?.accessToken?.jwtToken}`,
					'x-timestamp': getUnixTime(new Date()),
				},
			}
		)

		const variables = request?.variables ? request?.variables(data) : data

		return gqlClient
			?.request(request?.gql, variables)
			.then((results) => {
				return request?.formatResults
					? request?.formatResults(results)
					: results
			})
			.catch((err) => handleGQLErrors(err, queryClient))
	}
}

export const useMutation = ({ key, request, options = {} }: MutationType) => {
	const queryClient = useQueryClient()

	return useRQMutation(Mutation(request, queryClient), {
		mutationKey: key,
		onMutate: async (variables) => {
			await queryClient.cancelQueries(key)
			const previous = queryClient.getQueryData(key)

			if (request?.optimisticUpdate) {
				request.optimisticUpdate(key, queryClient, previous, variables)
			}

			return { previous }
		},
		onSuccess: (data, variables, context) => {
			return request?.onSuccess
				? request?.onSuccess(key, queryClient, data, variables, context)
				: null
		},
		onError: async (err, data, context) => {
			await queryClient.invalidateQueries(key)
			await queryClient.setQueryData(key, context.previous)
			const defaultOnError =
				queryClient?.getDefaultOptions()?.mutations?.onError
			if (!!defaultOnError) {
				defaultOnError(err)
			}
		},
		...options,
	})
}

export const Subscription = (request) => {
	return async ({ queryKey }) => {
		const session = await Auth.currentSession()
		const [, , data] = queryKey

		const gqlClient = new GraphQLClient(
			`${AppConfigs.graphQLUrl}?qi=${request?.identifier}`,
			{
				headers: {
					authorization: `Bearer ${session?.accessToken?.jwtToken}`,
					'x-timestamp': getUnixTime(new Date()),
				},
			}
		)
		const variables = request?.variables ? request?.variables(data) : data

		return gqlClient?.rawRequest(request?.gql, variables)
	}
}

export const useSubscription = ({
	key,
	request,
	options = {},
}: MutationType) => {
	const queryClient = useQueryClient()
	const pusherClient = useContext(SubscriptionContext)

	const ref = useRef({
		name: null,
		channel: null,
	})

	useComponentDidMount(
		() => {
			const subscribe = async (request) => {
				const data = await queryClient.fetchQuery(
					['subscribe', ...key],
					Subscription(request)
				)

				const name =
					data?.extensions?.lighthouse_subscriptions?.channel ?? null

				if (!name) return

				const channel = pusherClient?.subscribe(name)

				ref.current = {
					name,
					channel,
				}

				window.onbeforeunload = function () {
					return pusherClient?.unsubscribe(ref.current?.name)
				}

				// eslint-disable-next-line no-unused-expressions
				channel?.bind('lighthouse-subscription', ({ result }) => {
					const formatted = request?.formatResults
						? request?.formatResults(result)
						: result

					return options?.onSubscribeData
						? options.onSubscribeData(key, formatted)
						: request.onSubscribeData(key, queryClient, formatted)
				})
			}

			return subscribe(request)
		},
		() => {
			// eslint-disable-next-line no-unused-expressions
			ref.current?.channel?.unbind('lighthouse-subscription')
			// eslint-disable-next-line no-unused-expressions
			pusherClient?.unsubscribe(ref.current?.name)
		}
	)
}

const handleGQLErrors = (err, queryClient: ?QueryClient) => {
	if (!!queryClient) {
		const errorTypes = new Set()
		const numErrors = err?.response?.errors?.length
		let errorsHandled = 0
		if (numErrors > 0) {
			err.response.errors.forEach((error) => {
				errorTypes.add(error?.extensions?.category)
			})
			errorTypes.delete(undefined)
		}

		errorTypes.forEach((errorType) => {
			const errorHandler = errorHandlers?.[errorType]
			if (!!errorHandler) {
				errorsHandled = errorsHandled + 1
				errorHandler(queryClient)
			}
		})

		if (errorsHandled < numErrors) {
			throw err
		}
	}
}

export function getFragmentBodies(fragments) {
	return fragments
		?.map((fragment) => {
			const name = fragment.match(/fragment\s+(\w+)\s+on/)[1]
			return `...${name}`
		})
		.join('\n')
}
