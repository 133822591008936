// @flow
import type { ShoppingListType } from 'src/flowtypes/Entities/ShoppingListType'
import enums from 'src/configs/enums'
import type { ProductCommentType } from 'src/flowtypes/Entities/ProductCommentType'
import Organization from 'src/apollo/_entities/organization'
import UserGroup from 'src/apollo/_entities/userGroup'
import type { PriceOptionType } from 'src/flowtypes/Objects/PriceOptionType'

const location_roles = [
	'location-admin',
	'location-purchaser',
	'location-observer',
]
const location_group_roles = [
	'location-group-admin',
	'location-group-purchaser',
	'location-group-observer',
]

const User = (userData) => {
	if (!userData) return null

	const {
		locations,
		location_groups,
		permissions,
		created_at,
		current_bank_customer,
		...rest
	} = userData

	const user_groups =
		userData?.user_groups?.map((user_group) => UserGroup(user_group)) ?? []

	const organization = Organization(userData?.organization)

	const permissionList = permissions?.map((permission) => ({
		id: permission?.permission?.id,
		name: permission?.permission?.name,
		display_name: permission?.permission?.display_name,
		detailed_display_name: permission?.permission?.detailed_display_name,
		description: permission?.permission?.description,
		type: permission?.permission?.type,
	}))

	const hasPermission = (target) =>
		permissionList?.map((permission) => permission?.name).includes(target)

	const hasAdminPermission = (target) => {
		let hasPerm = false

		if (target === 'organization-admin') {
			return hasPermission(target)
		} else if (target === 'location-group-admin') {
			hasPerm = isStrictlyAdminOfAnyLocationGroup()
		} else if (target === 'location-admin') {
			hasPerm = isStrictlyAdminOfAnyLocation()
		}

		return hasPerm
	}

	const locationList = locations
		?.map((location) => {
			return {
				id: location?.location?.id,
				name: location?.location?.name,
				permission: location?.permission,
				users: location?.location?.users?.map(({ user }) => user),
			}
			// filter out any location role that is not admin, purchaser, or observer
			// we retain location level access to a user by using a hidden location role called unassigned
			// this needs to be filtered out so it is not displayed on the location or user page.
		})
		.filter((location) =>
			location_roles?.includes(location.permission.name)
		)

	const locationGroupList = location_groups
		?.map((location_group) => ({
			id: location_group?.location_group?.id,
			name: location_group?.location_group?.name,
			permission: location_group?.permission,
			users: location_group?.location_group?.users?.map(
				({ user }) => user
			),
			locations: location_group?.location_group?.locations?.map(
				(location) => location
			),
			locations_count:
				location_group?.location_group?.locations_count || 0,
			users_count: location_group?.location_group?.users_count || 0,
		}))
		.filter((location_group) =>
			location_group_roles?.includes(location_group.permission.name)
		)

	const isAdminOfLocation = (location_id) => {
		const location = locationList?.find(
			(location) => String(location?.id) === String(location_id)
		)

		return (
			location?.permission?.name === 'location-admin' ||
			isOrganizationAdmin() ||
			isAdminOfLocationThroughLocationGroup(location_id) ||
			isCuremintAdmin()
		)
	}

	const isStrictlyAdminOfAnyLocation = () => {
		for (let i = 0; i < locationList?.length; i++) {
			if (locationList[i]?.permission?.name === 'location-admin') {
				return true
			}
		}

		return false
	}

	const isAdminOfAnyLocation = () => {
		return isStrictlyAdminOfAnyLocation() || isAdminOfAnyLocationGroup()
	}

	const isPurchaserOfAnyLocation = () => {
		for (let i = 0; i < locationList?.length; i++) {
			if (locationList[i]?.permission?.name === 'location-purchaser') {
				return true
			}
		}

		return isPurchaserOfAnyLocationGroup()
	}

	const isPurchaserOfAnyLocationGroup = () => {
		for (let i = 0; i < locationGroupList?.length; i++) {
			if (
				locationGroupList[i]?.permission?.name ===
				'location-group-purchaser'
			) {
				return true
			}
		}
		return (
			isOrganizationPurchaser() ||
			isOrganizationAdmin() ||
			isCuremintAdmin()
		)
	}

	const isAdminOfLocationThroughLocationGroup = (location_id) => {
		for (let i = 0; i < locationGroupList?.length; i++) {
			const group = locationGroupList[i]

			if (group?.permission?.name === 'location-group-admin') {
				for (let j = 0; j < group?.locations?.length; j++) {
					const location = group?.locations[j]

					if (
						String(location?.location?.id) === String(location_id)
					) {
						return true
					}
				}
			}
		}
		return false
	}

	const isAdminOfLocationGroup = (location_group_id) => {
		const location_group = locationGroupList?.find(
			(location_group) =>
				String(location_group?.id) === String(location_group_id)
		)
		return (
			location_group?.permission?.name === 'location-group-admin' ||
			isOrganizationAdmin() ||
			isCuremintAdmin()
		)
	}

	const isStrictlyAdminOfAnyLocationGroup = () => {
		for (let i = 0; i < locationGroupList?.length; i++) {
			if (
				locationGroupList[i]?.permission?.name ===
				'location-group-admin'
			) {
				return true
			}
		}

		return false
	}

	const isAdminOfAnyLocationGroup = () => {
		return (
			isStrictlyAdminOfAnyLocationGroup() ||
			isOrganizationAdmin() ||
			isCuremintAdmin()
		)
	}

	const isAdminOfAnyUserGroup = () => {
		return isOrganizationAdmin() || isCuremintAdmin()
	}

	const isOrganizationAdmin = () => {
		return hasPermission('organization-admin')
	}

	const isBankingCustomer = () => {
		return !!current_bank_customer && !!current_bank_customer?.activated_at
	}

	const isInactiveBankingCustomer = () => {
		if (!current_bank_customer) {
			return false
		} else {
			return !current_bank_customer?.activated_at
		}
	}

	const isOrganizationPurchaser = () => {
		return hasPermission('organization-purchaser')
	}

	const isCuremintAdmin = () => {
		const perms = ['curemint-admin', 'curemint-developer']

		for (let i = 0; i < perms.length; i++) {
			if (hasPermission(perms[i])) {
				return true
			}
		}

		return false
	}

	const isCuremintDeveloper = () => {
		const perms = ['curemint-developer']

		for (let i = 0; i < perms.length; i++) {
			if (hasPermission(perms[i])) {
				return true
			}
		}

		return false
	}

	const isTranscriber = () => {
		return hasPermission('bill-transcriber')
	}

	const isValidator = () => {
		return hasPermission('bill-validator')
	}

	const isManager = () => {
		return hasPermission('bill-manager')
	}

	const canManageCatalog = (flags) => {
		return (
			flags?.newCatalogPage &&
			(isOrganizationAdmin() || isCuremintAdmin())
		)
	}

	const hasAnyTranscriptionPermission = () =>
		isTranscriber() || isValidator() || isManager()

	const hasAdminPermissionOrHigher = (
		permissionName: string,
		locationId: number
	) => {
		switch (permissionName) {
			case 'organization-admin': {
				return isOrganizationAdmin()
			}
			case 'location-group-admin': {
				return (
					isOrganizationAdmin() ||
					isAdminOfLocationThroughLocationGroup(locationId)
				)
			}
			case 'location-admin': {
				return (
					isOrganizationAdmin() ||
					isAdminOfLocationThroughLocationGroup(locationId) ||
					isAdminOfLocation(locationId)
				)
			}
			default: {
				return false
			}
		}
	}

	const isInCuremintOrganization = () => {
		return userData?.organization?.is_curemint
	}

	const canManageList = (list: ShoppingListType) => {
		const canManageOrganizationList = () => {
			return isOrganizationAdmin() || isCuremintAdmin()
		}

		const canManageLocationGroupList = () => {
			return (
				isOrganizationAdmin() ||
				isCuremintAdmin() ||
				isAdminOfLocationGroup(list?.ownable_id)
			)
		}

		const canManageLocationList = () => {
			return (
				isOrganizationAdmin() ||
				isCuremintAdmin() ||
				isAdminOfLocationThroughLocationGroup(list?.ownable_id) ||
				isAdminOfLocation(list?.ownable_id)
			)
		}

		if (
			list?.ownable_type === enums.OWNABLE_TYPE.USER &&
			String(list?.ownable_id) === String(userData?.id)
		) {
			return true
		} else {
			switch (list?.ownable_type) {
				case enums.OWNABLE_TYPE.ORGANIZATION:
					return canManageOrganizationList()
				case enums.OWNABLE_TYPE.LOCATION_GROUP:
					return canManageLocationGroupList()
				case enums.OWNABLE_TYPE.LOCATION:
					return canManageLocationList()
				default:
					return false
			}
		}
	}

	const canManageOrder = (order) =>
		isOrganizationAdmin() ||
		isAdminOfLocation(order?.location_id) ||
		isAdminOfLocationThroughLocationGroup(order?.location_id) ||
		String(userData?.id) === String(order?.user?.id)

	const canDeleteOrderFile = (order) => {
		return (
			order?.user_id === userData?.id ||
			isOrganizationAdmin() ||
			isAdminOfLocationThroughLocationGroup(order?.location_id) ||
			isAdminOfLocation(order?.location_id)
		)
	}

	const canEditProductComment = (comment: ProductCommentType) => {
		return userData?.id === comment?.user?.id
	}

	const hasAccessToPayModule = () =>
		(isCuremintAdmin() ||
			isOrganizationAdmin() ||
			isTranscriber() ||
			isValidator() ||
			isManager()) &&
		(organization?.hasTags(['module:Payables']) ||
			organization?.hasTags([
				'organization-type:Transcription Organization',
			]))

	const user_catalogs =
		userData?.catalogs?.map(({ id, catalog }) => ({
			...catalog,
			catalog_access_id: id,
		})) ?? []
	const user_group_catalogs = user_groups?.flatMap(
		(group) =>
			group?.catalogs?.flatMap(({ id, catalog }) => ({
				...catalog,
				group_name: group?.name,
				catalog_access_id: id,
			})) ?? []
	)

	const catalogs = [...new Set([...user_catalogs, ...user_group_catalogs])]

	const hasFullCatalogAccess =
		!!catalogs?.find((catalog) => catalog?.is_full) ||
		isOrganizationAdmin() ||
		isCuremintAdmin()

	const canActivateVendors = () =>
		isOrganizationAdmin() || isCuremintAdmin() || isCuremintDeveloper()

	const canEditPriceOption = (option: PriceOptionType) =>
		!option?.is_live &&
		isOrganizationAdmin() &&
		String(option?.owner_id) === String(userData?.organization?.id)

	return {
		...rest,
		value: userData?.id,
		label: userData?.name,
		hasPermission,
		hasAdminPermission,
		hasAdminPermissionOrHigher,
		hasAccessToPayModule,
		isAdminOfLocation,
		isAdminOfAnyLocation,
		isPurchaserOfAnyLocation,
		isAdminOfLocationGroup,
		isAdminOfLocationThroughLocationGroup,
		isAdminOfAnyLocationGroup,
		isAdminOfAnyUserGroup,
		isOrganizationAdmin,
		isOrganizationPurchaser,
		isBankingCustomer,
		isInactiveBankingCustomer,
		isCuremintAdmin,
		isCuremintDeveloper,
		isTranscriber,
		isValidator,
		isManager,
		hasAnyTranscriptionPermission,
		isInCuremintOrganization,
		canManageList,
		canManageOrder,
		canDeleteOrderFile,
		canEditProductComment,
		canManageCatalog,
		canActivateVendors,
		canEditPriceOption,
		current_bank_customer,
		created_at: +created_at,
		permissions: permissionList,
		locations: locationList,
		location_groups: locationGroupList,
		hasFullCatalogAccess,
		user_groups,
		user_catalogs,
		user_group_catalogs,
		catalogs:
			hasFullCatalogAccess && isOrganizationAdmin() ? null : catalogs,
	}
}

export default User
