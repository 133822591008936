// @flow
import React, { Node } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type { UserType } from 'src/flowtypes/Entities/UserType'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useFlags } from 'launchdarkly-react-client-sdk'

type ShouldDisplayConfigType = {
	shouldDisplay: (UserType) => boolean,
}

type Props = {
	children?: Node,
	targetConfig?: ShouldDisplayConfigType,
}

const ShouldDisplayRender = ({ children, targetConfig = null }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const flags = useFlags()

	return (
		<ConditionalRender
			condition={() =>
				targetConfig?.shouldDisplay
					? targetConfig.shouldDisplay(authUser, flags)
					: true
			}
		>
			{children}
		</ConditionalRender>
	)
}

export default ShouldDisplayRender
