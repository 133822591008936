// @flow
import React from 'react'

const SideMenuSectionHeader = ({ title }) => (
	<>
		<p
			style={{
				fontSize: '0.9em',
				fontWeight: 500,
				color: '#999',
			}}
		>
			{title}
		</p>
	</>
)

export default SideMenuSectionHeader
