// @flow
import React from 'react'
import MultiSelectListDropdown from 'src/components/Lists/Dropdowns/MultiSelectListDropdown'
import Input from 'src/components/_generic/forms/input'
import enums from 'src/configs/enums'
import EmptyFunc from 'src/library/emptyFunc'
import WhoFollowOnLocations from 'src/components/OrderRules/QuestionComponents/WhoFollowOnLocations'
import WhoFollowOnLocationGroups from 'src/components/OrderRules/QuestionComponents/WhoFollowOnLocationGroups'
import MultiSelectProductDropdown from 'src/components/Products/SelectProduct/MultiSelectProductDropdown'
import MultiSelectFormularyDropdown from 'src/components/Organizations/Formularies/MultiSelectFormularyDropdown'
import MultiSelectCategoryDropdown from 'src/components/Categories/Dropdowns/MultiSelectCategoryDropdown'
import MultiSelectOrganizationVendorsDropdown from 'src/components/OrganizationVendors/SelectOrganizationVendors/MultiSelectOrganizationVendorsDropdown'
import MultiSelectManufacturersDropdown from 'src/components/Manufacturers/Dropdowns/MultiSelectManufacturersDropdown'
import QuantityComparatorRadioGroup from 'src/components/OrderRules/QuestionComponents/QuantityComparatorRadioGroup'
import type { OrderRuleComponentType } from 'src/flowtypes/Entities/OrderRuleComponentType'
import type { UpsertOrderRuleType } from 'src/flowtypes/Entities/OrderRuleType'
import WhoFollowOnUsers from 'src/components/OrderRules/QuestionComponents/WhoFollowOnUsers'
import { Col, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import DollarInput from 'src/components/_generic/forms/dollar-input'
import MoneyFormatter from 'src/library/MoneyFormatter'
import MultiSelectLineOfBusinessDropdown from 'src/components/AccountingCodes/Dropdowns/MultiSelectLineOfBusinessDropdown'
import MultiSelectChartOfAccountDropdown from 'src/components/AccountingCodes/Dropdowns/MultiSelectChartOfAccountDropdown'
import ActionFollowOnNotify from 'src/components/OrderRules/QuestionComponents/ActionFollowOnNotify'
import MultiSelectCatalogsDropdown from 'src/components/CatalogAccess/SelectCatalog/MultiSelectCatalogsDropdown'

const formatForDropdown = (entity) => {
	return {
		...entity,
		value: entity?.rulable?.id,
		label: entity?.rulable?.name,
		id: entity?.rulable?.id,
	}
}

const allableComponentArrayValue = (component, allableLabel) => {
	return component?.is_all
		? [{ value: 0, label: allableLabel }]
		: !!component?.component_entities?.length
		? component?.component_entities?.map(formatForDropdown)
		: []
}

export const getOrderRuleComponentFollowOnQuestions = (
	componentType: number,
	component: OrderRuleComponentType = undefined
) => {
	switch (componentType) {
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_LOCATION:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_LOCATION:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_LOCATION: {
			return {
				initialFormValues: {
					locations: allableComponentArrayValue(
						component,
						'All Locations'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.locations?.filter(
							(location) => +location?.value === 0
						)?.length > 0
					return {
						...form,
						who_is_all: didSelectAll,
						locations: didSelectAll ? [] : form?.locations,
					}
				},
				renderQuestionComponent: () => {
					return <WhoFollowOnLocations />
				},
			}
		}

		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_PURCHASER_GROUP:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_ADMIN_GROUP:
		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_GROUP: {
			return {
				initialFormValues: {
					location_groups: allableComponentArrayValue(
						component,
						'All Location Groups'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.location_groups?.filter(
							(locationGroup) => +locationGroup?.value === 0
						)?.length > 0
					return {
						...form,
						who_is_all: didSelectAll,
						location_groups: didSelectAll
							? []
							: form?.location_groups,
					}
				},
				renderQuestionComponent: (onComplete?: (any) => any) => {
					return (
						<WhoFollowOnLocationGroups
							onComplete={onComplete}
							includeAllOption={true}
						/>
					)
				},
			}
		}

		case enums.ORDER_RULE_COMPONENT_TYPE.WHO_USER: {
			return {
				initialFormValues: {
					users: allableComponentArrayValue(component, 'All Users'),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.users?.filter((user) => +user?.value === 0)
							?.length > 0
					return {
						...form,
						who_is_all: didSelectAll,
						users: didSelectAll ? [] : form?.users,
					}
				},
				renderQuestionComponent: () => {
					return <WhoFollowOnUsers />
				},
			}
		}

		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_PRODUCT: {
			return {
				initialFormValues: {
					products: allableComponentArrayValue(
						component,
						'All Products'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.products?.filter(
							(product) => +product?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						products: didSelectAll ? [] : form?.products,
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectProductDropdown
							name={'products'}
							label={'Choose Product(s)'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_FORMULARY: {
			return {
				initialFormValues: {
					formularies: allableComponentArrayValue(
						component,
						'All Price Books'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.formularies?.filter(
							(formulary) => +formulary?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						formularies: didSelectAll ? [] : form?.formularies,
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectFormularyDropdown
							name={'formularies'}
							label={'Choose price book(s)'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_LIST: {
			return {
				initialFormValues: {
					shopping_lists: allableComponentArrayValue(
						component,
						'All Lists'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.shopping_lists?.filter(
							(list) => +list?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						shopping_lists: didSelectAll
							? []
							: form?.shopping_lists,
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectListDropdown
							name={'shopping_lists'}
							label={'Choose list(s)'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_VENDOR: {
			return {
				initialFormValues: {
					vendors: allableComponentArrayValue(
						component,
						'All Vendors'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.vendors?.filter((vendor) => +vendor?.value === 0)
							?.length > 0

					return {
						...form,
						condition_is_all: didSelectAll,
						vendors: didSelectAll
							? []
							: form?.vendors?.map((vendor) => vendor?.value),
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectOrganizationVendorsDropdown
							name={'vendors'}
							label={'Choose vendor(s)'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_MANUFACTURER: {
			return {
				initialFormValues: {
					manufacturers: allableComponentArrayValue(
						component,
						'All Manufacturers'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.manufacturers?.filter(
							(manufacturer) => +manufacturer?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						manufacturers: didSelectAll ? [] : form?.manufacturers,
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectManufacturersDropdown
							name={'manufacturers'}
							label={'Choose manufacturer(s)'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PURCHASED_CATALOG: {
			return {
				initialFormValues: {
					catalogs: allableComponentArrayValue(
						component,
						'All Catalogs'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.catalogs?.filter(
							(catalog) => +catalog?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						catalogs: didSelectAll ? [] : form?.catalogs,
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectCatalogsDropdown
							name={'catalogs'}
							label={'Choose catalog(s)'}
							includeAllOptions={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_BUDGET: {
			return {
				initialFormValues: {
					budget_percentage: component?.value || '',
				},
				isAllable: false,
				reformatForm: (form: UpsertOrderRuleType) => {
					return { ...form }
				},
				renderQuestionComponent: () => {
					return (
						<Input
							name={'budget_percentage'}
							label={'Choose percentage of any budget'}
							required
							addOn={
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>%</InputGroupText>
								</InputGroupAddon>
							}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_PRODUCT: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					products: allableComponentArrayValue(
						component,
						'All Products'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.products?.filter(
							(product) => +product?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						products: didSelectAll ? [] : form?.products,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectProductDropdown
								name={'products'}
								label={'Choose product(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_FORMULARY: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					formularies: allableComponentArrayValue(
						component,
						'All Price Books'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.formularies?.filter(
							(formulary) => +formulary?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						formularies: didSelectAll ? [] : form?.formularies,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectFormularyDropdown
								name={'formularies'}
								label={'Choose price book(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATEGORY: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					categories: allableComponentArrayValue(
						component,
						'All Categories'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.categories?.filter(
							(category) => +category?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						categories: didSelectAll ? [] : form?.categories,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectCategoryDropdown
								name={'categories'}
								label={'Choose category(ies)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_LIST: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					shopping_lists: allableComponentArrayValue(
						component,
						'All Lists'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.shopping_lists?.filter(
							(list) => +list?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						shopping_lists: didSelectAll
							? []
							: form?.shopping_lists,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectListDropdown
								name={'shopping_lists'}
								label={'Choose list(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR:
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_VENDOR_ORDER: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					vendors: allableComponentArrayValue(
						component,
						'All Vendors'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.vendors?.filter((vendor) => +vendor?.value === 0)
							?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						vendors: didSelectAll ? [] : form?.vendors,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectOrganizationVendorsDropdown
								name={'vendors'}
								label={'Choose vendor(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_MANUFACTURER: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					manufacturers: allableComponentArrayValue(
						component,
						'All Manufacturers'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.manufacturers?.filter(
							(manufacturer) => +manufacturer?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						manufacturers: didSelectAll ? [] : form?.manufacturers,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectManufacturersDropdown
								name={'manufacturers'}
								label={'Choose manufacturer(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_QUANTITY_CATALOG: {
			return {
				initialFormValues: {
					catalogs: allableComponentArrayValue(
						component,
						'All Catalogs'
					),
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.catalogs?.filter(
							(catalog) => +catalog?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						catalogs: didSelectAll ? [] : form?.catalogs,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectCatalogsDropdown
								name={'catalogs'}
								label={'Choose catalog(s)'}
								includeAllOptions={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_ORDER: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					spend: MoneyFormatter.forceDecimals(component?.value) || '',
				},
				isAllable: false,
				reformatForm: (form: UpsertOrderRuleType) => {
					return {
						...form,
						spend: MoneyFormatter.cleanMoney(form?.spend),
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'spend'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_VENDOR: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					spend: MoneyFormatter.forceDecimals(component?.value) || '',
					vendors: allableComponentArrayValue(
						component,
						'All Vendors'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.vendors?.filter((vendor) => +vendor?.value === 0)
							?.length > 0
					return {
						...form,
						spend: MoneyFormatter.cleanMoney(form?.spend),
						condition_is_all: didSelectAll,
						vendors: didSelectAll ? [] : form?.vendors,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectOrganizationVendorsDropdown
								name={'vendors'}
								label={'Choose vendor(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'spend'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_MANUFACTURER: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					spend: MoneyFormatter.forceDecimals(component?.value) || '',
					manufacturers: allableComponentArrayValue(
						component,
						'All Manufacturers'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.manufacturers?.filter(
							(manufacturer) => +manufacturer?.value === 0
						)?.length > 0
					return {
						...form,
						spend: MoneyFormatter.cleanMoney(form?.spend),
						condition_is_all: didSelectAll,
						manufacturers: didSelectAll ? [] : form?.manufacturers,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectManufacturersDropdown
								name={'manufacturers'}
								label={'Choose manufacturer(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'spend'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_PRODUCT: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					spend: MoneyFormatter.forceDecimals(component?.value) || '',
					products: allableComponentArrayValue(
						component,
						'All Products'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.products?.filter(
							(product) => +product?.value === 0
						)?.length > 0
					return {
						...form,
						spend: MoneyFormatter.cleanMoney(form?.spend),
						condition_is_all: didSelectAll,
						products: didSelectAll ? [] : form?.products,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectProductDropdown
								name={'products'}
								label={'Choose product(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'spend'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_PRICE_PRODUCT: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					price: MoneyFormatter.forceDecimals(component?.value) || '',
				},
				isAllable: false,
				reformatForm: (form: UpsertOrderRuleType) => {
					return {
						...form,
						price: MoneyFormatter.cleanMoney(form?.price),
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'price'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_PURCHASED_LINE_OF_BUSINESS: {
			return {
				initialFormValues: {
					lines_of_business: allableComponentArrayValue(
						component,
						'All Lines of Business'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.lines_of_business?.filter(
							(code) => +code?.value === 0
						)?.length > 0

					return {
						...form,
						condition_is_all: didSelectAll,
						lines_of_business: didSelectAll
							? []
							: form?.lines_of_business?.map(
									(code) => code?.value
							  ),
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectLineOfBusinessDropdown
							name={'lines_of_business'}
							label={'Choose line(s) of business'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_QUANTITY_LINE_OF_BUSINESS: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					lines_of_business: allableComponentArrayValue(
						component,
						'All Lines of Business'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.lines_of_business?.filter(
							(code) => +code?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						lines_of_business: didSelectAll
							? []
							: form?.lines_of_business,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectLineOfBusinessDropdown
								name={'lines_of_business'}
								label={'Choose line(s) of business'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_LINE_OF_BUSINESS: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					spend: MoneyFormatter.forceDecimals(component?.value) || '',
					lines_of_business: allableComponentArrayValue(
						component,
						'All Lines of Business'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.lines_of_business?.filter(
							(code) => +code?.value === 0
						)?.length > 0
					return {
						...form,
						spend: MoneyFormatter.cleanMoney(form?.spend),
						condition_is_all: didSelectAll,
						lines_of_business: didSelectAll
							? []
							: form?.lines_of_business,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectLineOfBusinessDropdown
								name={'lines_of_business'}
								label={'Choose line(s) of business'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'spend'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_PURCHASED_CHART_OF_ACCOUNT: {
			return {
				initialFormValues: {
					chart_of_accounts: allableComponentArrayValue(
						component,
						'All Chart of Accounts'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.chart_of_accounts?.filter(
							(code) => +code?.value === 0
						)?.length > 0

					return {
						...form,
						condition_is_all: didSelectAll,
						lines_of_business: didSelectAll
							? []
							: form?.chart_of_accounts?.map(
									(code) => code?.value
							  ),
					}
				},
				renderQuestionComponent: () => {
					return (
						<MultiSelectChartOfAccountDropdown
							name={'chart_of_accounts'}
							label={'Choose chart of account(s)'}
							includeAllOption={true}
						/>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE
			.CONDITION_QUANTITY_CHART_OF_ACCOUNT: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					quantity: component?.value || '',
					chart_of_accounts: allableComponentArrayValue(
						component,
						'All Chart of Accounts'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.chart_of_accounts?.filter(
							(code) => +code?.value === 0
						)?.length > 0
					return {
						...form,
						condition_is_all: didSelectAll,
						chart_of_accounts: didSelectAll
							? []
							: form?.chart_of_accounts,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectChartOfAccountDropdown
								name={'chart_of_accounts'}
								label={'Choose chart of account(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<Input
										name={'quantity'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}
		case enums.ORDER_RULE_COMPONENT_TYPE.CONDITION_SPEND_CHART_OF_ACCOUNT: {
			return {
				initialFormValues: {
					comparison_type: component?.comparison_type || '',
					spend: MoneyFormatter.forceDecimals(component?.value) || '',
					chart_of_accounts: allableComponentArrayValue(
						component,
						'All Chart of Accounts'
					),
				},
				isAllable: true,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.chart_of_accounts?.filter(
							(code) => +code?.value === 0
						)?.length > 0
					return {
						...form,
						spend: MoneyFormatter.cleanMoney(form?.spend),
						condition_is_all: didSelectAll,
						chart_of_accounts: didSelectAll
							? []
							: form?.chart_of_accounts,
					}
				},
				renderQuestionComponent: () => {
					return (
						<>
							<MultiSelectChartOfAccountDropdown
								name={'chart_of_accounts'}
								label={'Choose chart of account(s)'}
								includeAllOption={true}
							/>
							<Row className='cm-mt-normal'>
								<Col xs={7}>
									<QuantityComparatorRadioGroup
										name={'comparison_type'}
									/>
								</Col>
								<Col xs={5}>
									<DollarInput
										name={'spend'}
										label={'Value'}
										required
									/>
								</Col>
							</Row>
						</>
					)
				},
			}
		}

		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY:
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY_USER: {
			const initialFormValues =
				componentType === enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY
					? {
							permissions:
								component?.component_entities?.length > 0
									? component?.component_entities[0]?.rulable
											?.id
									: '1',
							approvers: [
								{ id: '0', label: 'All Users', value: '0' },
							],
					  }
					: {
							permissions:
								'' +
								JSON.parse(component?.meta ?? '{}')?.permission,
							approvers: component?.component_entities?.map(
								(entity) => {
									return {
										id: entity?.rulable?.id,
										label: entity?.rulable?.name,
										value: entity?.rulable?.id,
									}
								}
							),
					  }

			return {
				initialFormValues: initialFormValues,
				isAllable: false,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.approvers?.filter((code) => +code?.value === 0)
							?.length > 0 || form?.approvers?.length === 0

					return {
						...form,
						action_component_type: didSelectAll
							? enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NOTIFY
							: enums.ORDER_RULE_COMPONENT_TYPE
									.ACTION_NOTIFY_USER,
						approvers: didSelectAll ? undefined : form.approvers,
					}
				},
				renderQuestionComponent: () => {
					return (
						<ActionFollowOnNotify
							permissionLabel={'Choose who should be notified'}
						/>
					)
				},
			}
		}

		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL:
		case enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL_USER: {
			const initialFormValues =
				componentType ===
				enums.ORDER_RULE_COMPONENT_TYPE.ACTION_NEEDS_APPROVAL
					? {
							permissions:
								component?.component_entities?.length > 0
									? component?.component_entities[0]?.rulable
											?.id
									: '1',
							approvers: [
								{ id: '0', label: 'All Users', value: '0' },
							],
					  }
					: {
							permissions:
								'' +
								JSON.parse(component?.meta ?? '{}')?.permission,
							approvers: component?.component_entities?.map(
								(entity) => {
									return {
										id: entity?.rulable?.id,
										label: entity?.rulable?.name,
										value: entity?.rulable?.id,
									}
								}
							),
					  }

			return {
				initialFormValues: initialFormValues,
				isAllable: false,
				reformatForm: (form: UpsertOrderRuleType) => {
					const didSelectAll =
						form?.approvers?.filter((code) => +code?.value === 0)
							?.length > 0 || form?.approvers?.length === 0

					return {
						...form,
						action_component_type: didSelectAll
							? enums.ORDER_RULE_COMPONENT_TYPE
									.ACTION_NEEDS_APPROVAL
							: enums.ORDER_RULE_COMPONENT_TYPE
									.ACTION_NEEDS_APPROVAL_USER,
						approvers: didSelectAll ? undefined : form.approvers,
					}
				},
				renderQuestionComponent: () => {
					return (
						<ActionFollowOnNotify
							permissionLabel={
								'Choose who should approve the order'
							}
						/>
					)
				},
			}
		}

		default: {
			return {
				initialFormValues: {},
				isAllable: false,
				reformatForm: (form: UpsertOrderRuleType) => {
					return form
				},
				renderQuestionComponent: EmptyFunc,
			}
		}
	}
}
