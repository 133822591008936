// @flow
import React, { useState } from 'react'
import MultiSelect from 'src/components/_generic/forms/multi-select'
import { useGetShoppingLists } from 'src/api/shopping_lists/useGetShoppingLists'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import { useUpsertShoppingList } from 'src/api/shopping_lists/useUpsertShoppingList'
import { useField } from 'formik'
import ShoppingList from 'src/api/shopping_lists/_entities/ShoppingList'
import { useQueryModifiers } from 'src/components/Search/useQueryModifiers'
import useListCanEdit from 'src/components/Lists/useListCanEdit'
import { useShowToast } from 'src/_boot/ToastContext'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	includeAllOption?: boolean,
	excludeIds?: number[],
}
const MultiSelectListDropdown = ({
	includeAllOption,
	excludeIds = [],
	name,
	...rest
}: Props) => {
	const { modifiers, setters } = useQueryModifiers('shopping-list-filters')
	const { lists: unformatted } = useGetShoppingLists({
		...modifiers,
		filters: {
			flags: [
				'my-lists',
				'organization-lists',
				'location-lists',
				'location-group-lists',
			],
		},
	})
	const { authUser } = useGetAuthenticatedUser()
	const { showSuccessToast } = useShowToast()
	const { reportError } = useErrorHandler()
	const { mutate: upsertShoppingList } = useUpsertShoppingList()
	const [{ value }, , { setValue }] = useField(name)
	const { getEditableLists } = useListCanEdit()

	const [inputValue, setInputValue] = useState('')

	const handleSubmit = (name) => {
		upsertShoppingList(
			{
				input: {
					name,
					is_editable: false,
					owner_type: 'user',
					owner_id: authUser?.id,
				},
			},
			{
				onSuccess: (newList) => {
					setValue([...value, ShoppingList(newList)])
					setInputValue('')

					return showSuccessToast(
						`Successfully created a new list! - ${name}`
					)
				},
				onError: (err) =>
					reportError(err, 'There was a problem creating this list!'),
			}
		)
	}

	const selectAllOption = { value: 0, label: 'All Lists' }
	const editableLists = getEditableLists(unformatted)
	const lists = editableLists.map((list) => ShoppingList(list)) ?? []
	const filteredList = lists?.filter((list) => {
		return !excludeIds.includes(list.id)
	})
	if (includeAllOption) {
		filteredList.unshift(selectAllOption)
	}
	return (
		<MultiSelect
			options={filteredList}
			name={name}
			{...rest}
			inputValue={inputValue}
			onInputChange={(newValue) => {
				setters.setSearchTarget(newValue)
				setInputValue(newValue)
			}}
			noOptionsMessage={() => {
				return filteredList?.length === 0 && lists?.length === 0 ? (
					<div>
						<p>You haven't created any lists yet!</p>
						<p>
							Add your{' '}
							<Link to='/procure/store/lists'>first list</Link>.
						</p>
					</div>
				) : (
					<div>
						<p>
							No more lists!
							<br />
							<Button
								style={styles.buttonStyle}
								onClick={() => handleSubmit(inputValue)}
							>
								CREATE LIST
							</Button>
						</p>
					</div>
				)
			}}
		/>
	)
}

export default MultiSelectListDropdown

const styles = {
	buttonStyle: {
		background: 'transparent',
		border: 'none',
		color: '#19c6f7',
		padding: 0,
		paddingBottom: 2,
		paddingLeft: 2,
	},
}
