// @flow
import React from 'react'

type Props = {
	id: string,
}

const ProgressBarChildDiv = ({ id }: Props) => {
	return <div id={id} style={{ width: '100%', height: '100%' }} />
}

export default ProgressBarChildDiv
