import { useEffect } from 'react'
import useGlobalState from 'src/library/useGlobalState/useGlobalState'
import { useGlobalSearch } from 'src/components/TopNav/GlobalSearch/GlobalSearchProvider'

export const usePersistantSearchState = (persistKey) => {
	const { modifiers } = useGlobalSearch()
	const searchTarget = modifiers?.searchTarget
	const [searchState, setSearchState] = useGlobalState(persistKey, {})

	function onSearchStateChange(updatedSearchState) {
		setSearchState(updatedSearchState)
	}

	useEffect(() => {
		if (
			persistKey !== 'global-search-state' &&
			searchState?.query !== searchTarget
		) {
			setSearchState({ ...searchState, query: searchTarget })
		}
	}, [searchTarget])

	return {
		searchState,
		onSearchStateChange,
	}
}
