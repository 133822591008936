import { gql } from 'graphql-request'
import UpsertCartItemDTO from 'src/pages/store/_dtos/UpsertCartItemDTO'

export const UpsertCartItemMutation = {
	identifier: 'upsert_cart_item',
	gql: gql`
		mutation upsertCartItem($id: ID, $input: UpsertCartItemInput!) {
			upsertCartItem(id: $id, input: $input) {
				id
				quantity
				unit_price
				cart {
					id
					location_id
				}
			}
		}
	`,
	variables: ({ id, input }) => ({
		id: id ?? undefined,
		input: UpsertCartItemDTO(input),
	}),
	formatResults: (data) => {
		return data?.upsertCartItem
	},
	onSuccess: (key, queryClient) => {
		queryClient.invalidateQueries(['get_cart'])
		queryClient.invalidateQueries(['my-cart-violations'])
		queryClient.invalidateQueries(['projected_spend_by_budget'])
		queryClient.invalidateQueries(['get_cart_list'])
		queryClient.invalidateQueries(['get_my_saved_items_cart'])
	},
}
