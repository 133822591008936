// @flow

import { useQuery } from 'src/api/index'
import { GetOrderIssuesCountQuery } from 'src/api/orders/get-order-issues-count-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'

export const useGetOrderIssuesCount = (modifiers: QueryModifierType) => {
	const { data: orderIssuesCount, isLoading } = useQuery({
		key: ['order-issues-count', { modifiers }],
		request: GetOrderIssuesCountQuery,
		options: {
			enabled: !!modifiers,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { orderIssuesCount, isLoading }
}
