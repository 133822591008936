// @flow
import type { UpsertBudgetRuleType } from 'src/flowtypes/Entities/Budgets/BudgetRuleType'

export default {
	// form field: operator_type
	forOperatorType: (entityType: number, form: UpsertBudgetRuleType) => {
		return form?.operator_type
	},
	// form field: rulable_type
	forPropertyType: (entityType: number, form: UpsertBudgetRuleType) => {
		return form?.rulable_type
	},
	// form field: rulable_id
	forValueType: (entityType: number, form: UpsertBudgetRuleType) => {
		return form?.rulable_id
	},
}
