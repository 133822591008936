import { useMemo } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useGetOrgHasProductTags } from 'src/api/tags/useGetOrgHasProductTags'

export const useInitAlgoliaSearch = (persistKey, shouldBrowse) => {
	const { authUser } = useGetAuthenticatedUser()
	const { orgHasProductTags } = useGetOrgHasProductTags(
		authUser?.organization.id
	)
	const searchKey = authUser?.algolia_search_key

	const searchClient = useMemo(() => {
		const algoliaClient = algoliasearch(
			process.env.REACT_APP_ALGOLIA_APP_ID,
			searchKey
		)

		const restrictableSearchAttributes = [
			'sku',
			'sku_variants',
			'name',
			'vendor_name',
			'manufacturer_name',
			'primary_category',
			'parent_category',
			'siblings',
			'global_tags',
		]

		if (orgHasProductTags)
			restrictableSearchAttributes.push(
				`org_tags.${authUser?.organization?.id}.name`
			)

		return {
			...algoliaClient,
			search(requests) {
				let theRequest = requests
				theRequest[0].params = {
					...requests[0]?.params,
					restrictSearchableAttributes: restrictableSearchAttributes,
				}

				const isEmptySearch = requests.every(
					({ params }) => !params.query
				)

				const searchStringIsLongEnough = requests.every(
					({ params }) => params.query.length > 3
				)

				if (isEmptySearch && shouldBrowse) {
					return algoliaClient.search(requests)
				}

				if (searchStringIsLongEnough) {
					return algoliaClient.search(theRequest)
				}

				return Promise.resolve({
					results: requests.map(() => ({
						hits: [],
						nbHits: 0,
						nbPages: 0,
						page: 0,
						processingTimeMS: 0,
					})),
				})
			},
		}
	}, [persistKey, shouldBrowse, searchKey, authUser, orgHasProductTags])

	return {
		searchClient,
	}
}
