// @flow
import React from 'react'
import SideMenuContentItem from 'src/components/SideMenu/SideMenuContent/SideMenuItem/side-menu-content-item'
import { useGetOrderIssuesCount } from 'src/api/orders/useGetOrderIssuesCount'
import { useLocation } from 'react-router-dom'
import SideMenuContentItemLink from 'src/components/SideMenu/SideMenuContent/SideMenuItem/side-menu-content-item-link'
import LDRender from 'src/components/_generic/ld-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

type Props = {
	section: Object,
}

const SideMenuContentItemList = ({ section }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	let flags = authUser?.isOrganizationAdmin() ? [] : ['my-orders']
	const { orderIssuesCount } = useGetOrderIssuesCount({
		page: 1,
		searchTarget: '',
		resultsPerPage: 12,
		filters: { flags: flags },
	})
	let location = useLocation()

	return (
		<>
			{section.items.map((item) => (
				<LDRender
					flagNames={!!item?.ldFlags ? item?.ldFlags : []}
					failedRender={''}
					key={`ld-render-${item.name}`}
				>
					<SideMenuContentItemLink
						item={item}
						key={`side-bar-item-${section.title}-${item.name}`}
					>
						<SideMenuContentItem
							item={item}
							isActive={
								!!item?.isActive
									? item?.isActive(location?.pathname)
									: false
							}
							issuesCount={orderIssuesCount}
						/>
					</SideMenuContentItemLink>
				</LDRender>
			))}
		</>
	)
}

export default SideMenuContentItemList
