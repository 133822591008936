// @flow
import React from 'react'
import SplitLayout from 'src/components/_layouts/split-layout'
import type { CartItemType } from 'src/flowtypes/Entities/CartItemType'
import MoneyFormatter from 'src/library/MoneyFormatter'

type Props = {
	cart_items: CartItemType[],
}

const CartPreviewSubtotal = ({ cart_items }: Props) => {
	const subtotal = cart_items?.length
		? cart_items
				.map((cartItem) => cartItem?.unit_price * cartItem.quantity)
				.reduce((accum, current) => accum + current)
		: 0

	return (
		<div style={Style.container}>
			<SplitLayout
				leftContent={<p>Subtotal</p>}
				rightContent={
					<p style={{ fontWeight: 'bold' }}>
						${MoneyFormatter.toDollars(subtotal)}
					</p>
				}
			/>
		</div>
	)
}

export default CartPreviewSubtotal

const Style = {
	container: {
		margin: 0,
		padding: 0,
	},
}
