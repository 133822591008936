// @flow
import type { Node } from 'react'
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import SkeletonText from 'src/components/_generic/skeletons/skeleton-text'
import VerticalLayout from 'src/components/_layouts/vertical-layout'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import Spacer from 'src/components/_generic/spacer'
import PaddedLayout from 'src/components/_generic/padded-layout'

type Props = {
	isLoading: boolean,
	children?: Node,
	card?: boolean,
}

const SkeletonProductDropdown = ({ isLoading, children, card }: Props) => {
	return (
		<ConditionalRender condition={isLoading} failedRender={children}>
			<div style={{ height: 100 }}>
				<PaddedLayout>
					<HorizontalLayout>
						<SkeletonText
							width={50}
							height={50}
							isLoading={true}
							circle={card}
						/>
						<VerticalLayout className='cm-ml-double'>
							<SkeletonText width={250} isLoading={true} />
							<Spacer />
							<SkeletonText width={100} isLoading={true} />
						</VerticalLayout>
					</HorizontalLayout>
				</PaddedLayout>
			</div>
		</ConditionalRender>
	)
}

export default SkeletonProductDropdown
