// @flow
import React from 'react'
import { Badge, Row } from 'reactstrap'

type Props = {
	iconClassName: string,
	acceptedTypes: Array<string>,
}

const ImportUploadPromptView = ({ iconClassName, acceptedTypes }: Props) => (
	<>
		<i
			className={iconClassName}
			style={{ color: '#46E590', fontSize: 40 }}
		/>
		<h3> Drag files or click to upload</h3>
		<p>Accepted Types:</p>
		<Row>
			{acceptedTypes.map((type) => (
				<Badge key={`atype-${type}`} className='mr-1 ml-1'>
					{type}
				</Badge>
			))}
		</Row>
	</>
)

export default ImportUploadPromptView
