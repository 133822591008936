// @flow
import React from 'react'
import { Progress } from 'reactstrap'
import CurrentSpendPopover from 'src/components/Budgets/Checkout/ProgressBar/Popovers/current-spend-popover'
import ProgressBarChildDiv from 'src/components/_generic/progress-bars/progress-bar-child-div'
import ProjectedSpendPopover from 'src/components/Budgets/Checkout/ProgressBar/Popovers/projected-spend-popover'

type Props = {
	budget: Object,
	maxValue: number,
}

const DangerProgressBar = ({ budget, maxValue }: Props) => {
	const { budget_allotment_id, current_spend, projected_spend } = budget
	return (
		<>
			<CurrentSpendPopover
				key={`current-spend-popover-${budget_allotment_id}`}
				budget={budget}
				targetId={`danger-spend-bar-current-${budget_allotment_id}`}
			/>
			<Progress
				key={`current-spend-progress-bar-${budget_allotment_id}`}
				bar
				barClassName={'bg-danger'}
				max={maxValue}
				value={current_spend}
			>
				<ProgressBarChildDiv
					key={`current-spend-div-${budget_allotment_id}`}
					id={`danger-spend-bar-current-${budget_allotment_id}`}
				/>
			</Progress>

			<ProjectedSpendPopover
				key={`projected-spend-popover-${budget_allotment_id}`}
				budget={budget}
				targetId={`danger-spend-bar-projected-${budget_allotment_id}`}
			/>
			<Progress
				key={`projected-spend-progress-bar-${budget_allotment_id}`}
				bar
				barClassName={'cm-progress-bar-extra-danger'}
				max={maxValue}
				value={projected_spend}
			>
				<ProgressBarChildDiv
					key={`projected-spend-div-${budget_allotment_id}`}
					id={`danger-spend-bar-projected-${budget_allotment_id}`}
				/>
			</Progress>
		</>
	)
}

export default DangerProgressBar
