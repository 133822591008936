import styled, { css } from 'styled-components'
import { Button } from 'reactstrap'

export const ButtonIcon = styled.span`
	margin-right: 8px;
	font-size: inherit;
`

export const StyledButton = styled(Button)`
	font-size: ${(props) => (props?.size === 'lg' ? '16px' : '14px')};
	white-space: nowrap;
	min-width: max-content;
	display: flex;
	justify-content: center;

	&.btn {
		text-transform: none;
	}

	&.btn-rounded {
		border-radius: 8px;
	}

	${(props) =>
		props.noPadding
			? css`
					padding: 0;
			  `
			: null}
`
