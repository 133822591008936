// @flow

const AccountingCode = (accountingCodeData) => {
	if (!accountingCodeData) return null

	return {
		...accountingCodeData,
		value: accountingCodeData?.id,
		label: !!accountingCodeData?.name
			? `${accountingCodeData?.name} ${accountingCodeData?.description}`
			: accountingCodeData?.description,
		code: accountingCodeData?.name,
		codeLabel: accountingCodeData?.description,
	}
}

export default AccountingCode
