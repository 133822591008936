// @flow

export default {
	fixedWidth: (data) => {
		const popoverWidth =
			window.innerWidth > 768
				? 400
				: window.innerWidth > 575
				? 320
				: window.innerWidth * 0.75

		data.arrowStyles = {
			right: data.offsets.reference.width / 2,
			width: 0,
		}
		data.offsets.popper.width = data.styles.width = popoverWidth
		data.offsets.popper.maxWidth = data.styles.maxWidth = popoverWidth
		data.offsets.popper.zIndex = data.styles.zIndex = 100
		data.offsets.popper.transform = data.styles.transform =
			'translate3d(' +
			(data.offsets.reference.right - popoverWidth) +
			'px, ' +
			(data.offsets.reference.bottom - 5) +
			'px, 0px)'
		return data
	},
}
