// @flow
import React from 'react'
import s3Image from 'src/library/s3Image'
import ImagePicker from 'src/components/_generic/image-picker/ImagePicker'
import ConditionalRender from 'src/components/_generic/conditional-render'
import Image from 'src/components/_generic/image'

type Props = {
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge',
	alt?: string,
	className?: string,
	imagePath: string,
	editable?: boolean,
	fallbackSrc?: string,
	onImageUploaded?: (string) => any,
}

const UserProfileImage = ({
	alt = 'Profile',
	imagePath,
	className = '',
	size = 'xlarge',
	editable = false,
	fallbackSrc = 'profile_placeholder.jpg',
	onImageUploaded = () => {},
	...rest
}: Props) => {
	return (
		<ConditionalRender
			condition={editable}
			failedRender={
				<Image
					alt={alt}
					className={`${className} border-white img-circle img-responsive img-no-padding no-spacing cm-profile-image-${size}`}
					src={s3Image(imagePath)}
					fallbackSrc={s3Image(fallbackSrc)}
					{...rest}
				/>
			}
		>
			<ImagePicker
				small
				alt={alt}
				className={`${className} border-white img-circle img-responsive img-no-padding no-spacing cm-profile-image-${size}`}
				imagePath={imagePath}
				fallbackImagePath={fallbackSrc}
				onImageUploaded={onImageUploaded}
				{...rest}
			/>
		</ConditionalRender>
	)
}

export default UserProfileImage
