// @flow
import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { useFormikContext } from 'formik'
import enums from 'src/configs/enums'
import ConditionalRender from 'src/components/_generic/conditional-render'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'
import DisabledOverlayWithTooltip from 'src/components/_generic/disabled-overlay-with-tooltip'
import useUserCanAccessProduct from 'src/components/CatalogAccess/useUserCanAccessProduct'
import { useQueryClient } from 'react-query'
import { useAddToCartForm } from 'src/components/Store/MarketplaceProductList/Quickshop/withAddToCartForm'

type Props = {
	product: ProductType,
	isLoading?: boolean,
	addedToCart?: boolean,
	className?: string,
	size?: string,
}

const AddToCartButton = ({
	product,
	isLoading = false,
	addedToCart = false,
	className = 'text-overflow-ellipsis',
	size = 'md',
}: Props) => {
	const queryClient = useQueryClient()
	const { values } = useFormikContext()
	const { hasAccess } = useUserCanAccessProduct(product?.id)
	const { addedToCart: addedToCartState } = useAddToCartForm()
	const [onClickDisabled, setOnClickDisabled] = useState(false)

	const isUpserting = !!queryClient?.isMutating({
		predicate: (mutation) =>
			mutation.options?.mutationKey?.[0] === 'upsert_cart_item' &&
			'' + mutation.options.variables?.input?.product_id ===
				'' + product?.id,
	})

	useEffect(() => {
		if (isUpserting) {
			setOnClickDisabled(true)
			setTimeout(() => setOnClickDisabled(false), 250)
		}
	}, [isUpserting])

	const disabled =
		(values?.loaded_price === undefined || isNaN(values?.loaded_price)) &&
		!values?.price_selection?.price_id

	const isAddedToCart = addedToCart || addedToCartState
	const isQuerying = isLoading || isUpserting

	const buttonText = () => {
		return !isQuerying && !isAddedToCart
			? 'Add to Cart'
			: isQuerying && !isAddedToCart
			? 'Adding to Cart...'
			: 'Added to Cart!'
	}

	return (
		<ConditionalRender
			condition={
				product?.current_stock_level?.status !==
				enums.PRODUCT_STOCK_STATUS.DISCONTINUED
			}
		>
			<DisabledOverlayWithTooltip
				disabled={!hasAccess}
				tooltipText={
					'You do not have access to this product. Please contact your organization administrator.'
				}
			>
				<Button
					className={`${className}`}
					color='primary'
					type={'submit'}
					size={size}
					disabled={
						disabled ||
						isQuerying ||
						isAddedToCart ||
						onClickDisabled
					}
				>
					{buttonText()}
				</Button>
			</DisabledOverlayWithTooltip>
		</ConditionalRender>
	)
}

export default AddToCartButton
