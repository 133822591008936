// @flow
import enums from 'src/configs/enums'
import { getHumanReadableEntityType } from 'src/configs/Enums/BudgetRules/GetHumanReadableEntityType'
import { getEntityTypeFromHumanReadable } from 'src/configs/Enums/BudgetRules/GetEntityTypeFromHumanReadable'
import BudgetRuleFormValidators from 'src/configs/Enums/BudgetRules/BudgetRuleFormValidators'

export default {
	OPERATOR_TYPE_INCLUDE: 0,
	OPERATOR_TYPE_EXCLUDE: 1,

	PROPERTY_TYPE_CHART_OF_ACCOUNT: 2,
	PROPERTY_TYPE_LINE_OF_BUSINESS: 3,
	PROPERTY_TYPE_VENDOR: 4,

	getOperatorTypes: (): number[] => {
		return [
			enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_INCLUDE,
			enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_EXCLUDE,
		]
	},

	getPropertyTypes: (): number[] => {
		return [
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CHART_OF_ACCOUNT,
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_LINE_OF_BUSINESS,
			enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_VENDOR,
		]
	},

	getVendorPropertyType: (): number[] => {
		return [enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_VENDOR]
	},

	getAccountingCodeTypeFromEntityType: (entity) => {
		switch (entity) {
			case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CHART_OF_ACCOUNT:
				return enums.accountingCodeType.CHART_OF_ACCOUNT
			case enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_LINE_OF_BUSINESS:
				return enums.accountingCodeType.LINE_OF_BUSINESS
			default:
				return null
		}
	},

	getHumanReadableEntityType: getHumanReadableEntityType,
	getEntityTypeFromHumanReadable: getEntityTypeFromHumanReadable,
	formValidation: {
		...BudgetRuleFormValidators,
	},
}
