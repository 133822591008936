// @flow
import { format, formatDistance, getTime, getUnixTime } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

class DateFormatter {
	standard(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(utcToZonedTime(day, 'Europe/London'), 'MMM d, yyyy')
	}

	condensed(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(utcToZonedTime(day, 'Europe/London'), 'MM/dd/yy')
	}

	extended(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(
			utcToZonedTime(day, 'Europe/London'),
			'MMM d, yyyy hh:mm:ss'
		)
	}

	extendedUserLocale(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(day, "MMMM d, yyyy 'at' h:mm aaaaa'm'")
	}

	input(timestampMS) {
		if (!timestampMS) {
			return null
		}
		let day = new Date(+timestampMS)

		return format(utcToZonedTime(day, 'Europe/London'), 'yyyy-MM-dd')
	}

	notAdjusted(timestampMs) {
		let day = new Date(+timestampMs)

		return format(day, 'MMM d, yyyy')
	}

	custom(timestampMS, customFormat) {
		if (!timestampMS) {
			return null
		}
		let day = new Date(+timestampMS)

		return format(utcToZonedTime(day, 'Europe/London'), customFormat)
	}

	monthFromTimestampInSeconds(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}

		let day = new Date(
			+timestampMs - new Date().getTimezoneOffset() * 60 * 1000
		)
		return format(day, 'MMMM yyyy')
	}

	timestampToRelativeTimeString(timestampMs) {
		if (!timestampMs || timestampMs === 'false') {
			return 'never'
		}

		return formatDistance(new Date(+timestampMs), new Date()) + ' ago'
	}

	toTimestamp(date) {
		return getUnixTime(Date.parse(date)) * 1000
	}

	toUserTimestamp(date) {
		return Date.parse(date)
	}

	toDate(timestampMs) {
		return new Date(+timestampMs)
	}

	endOfDay(timestampMs: number): number {
		let date = new Date(+timestampMs)

		return this.toTimestamp(
			format(utcToZonedTime(date, 'Europe/London'), `yyyy-MM-dd 23:59:59`)
		)
	}

	humanReadableDate(date) {
		if (!date) {
			return null
		}

		return format(date, 'MMM d, y')
	}

	humanReadableDateAndTime(date) {
		if (!date) {
			return null
		}

		return format(date, "MMM d, y 'at' h:mm a")
	}

	humanReadableDateWithSeparator(date) {
		if (!date || date === 'Invalid Date') {
			return null
		}

		return format(date, 'MMM d, y')
	}

	humanReadableMonthYear(date) {
		if (!date) {
			return null
		}

		return format(date, 'MMMM yyyy')
	}

	secondsToTimeFormatter(timestamp) {
		const minutes = timestamp / 60

		if (minutes > 59) {
			const hours = minutes / 60

			if (hours > 23) {
				const days = hours / 24
				return `${Math.round(days)} Days`
			}

			return `${Math.round(hours)} Hours`
		}

		if (minutes === 0) {
			return `-`
		}
		if (minutes < 1) {
			return `< 1 Minute`
		}

		return `${Math.round(minutes)} Minutes`
	}

	millisecondsToTimeFormatter(timestamp) {
		const seconds = timestamp / 1000

		if (seconds >= 60) {
			let minutes = seconds / 60

			if (minutes >= 60) {
				let hours = minutes / 60

				if (hours >= 24) {
					const days = Math.floor(hours / 24)

					return `${days} ${days > 1 ? 'days' : 'day'} ago...`
				}

				hours = Math.floor(hours)
				return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago...`
			}

			minutes = Math.floor(minutes)
			return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago...`
		}

		return 'Just now'
	}

	firstOfTheMonth(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(
			utcToZonedTime(day, 'Europe/London'),
			'yyyy-MM-01 00:00:00'
		)
	}

	endOfTheMonth(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let date = new Date(+timestampMs)
		let lastDay = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)

		return format(
			utcToZonedTime(lastDay, 'Europe/London'),
			`yyyy-MM-dd 23:59:59`
		)
	}

	firstDayOfYear(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}

		return format(
			utcToZonedTime(
				new Date(new Date().getFullYear(), 0, 1),
				'Europe/London'
			),
			`yyyy-MM-dd 23:59:59`
		)
	}

	convertUnitFormattedDateStrToCMStandard(dateStr) {
		if (!!dateStr) {
			return format(
				utcToZonedTime(new Date(dateStr), 'Europe/London'),
				'MMM d, yyyy'
			)
		}
	}

	localDateToUtc(date) {
		const dateUtc = new Date(
			Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
		)
		return getTime(dateUtc)
	}

	utcDateToLocalDate(date) {
		if (!date) return null
		return new Date(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate()
		)
	}
}

export default new DateFormatter()
