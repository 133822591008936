// @flow
import * as Yup from 'yup'

export const passwordRegex =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/

export default {
	auth: {
		login: Yup.object().shape({
			email: Yup.string().required('An email address is required'),
			password: Yup.string().required('A password is required'),
		}),
		register: Yup.object().shape({
			organization: Yup.string().required(
				'The organization field is required.'
			),
			name: Yup.string().required('A name is required.'),
			email: Yup.string().required('An email address is required.'),
			password: Yup.string()
				.required('A password is required')
				.min(8, 'Password is too short - should be 8 chars minimum.'),
		}),
		confirmRegister: Yup.object().shape({
			code: Yup.string().required('A verification code is required.'),
		}),
		forgotPasswordRequest: Yup.object().shape({
			email: Yup.string().required('An email address is required.'),
		}),
		forgotPasswordVerifyCode: Yup.object().shape({
			code: Yup.string().required('A verification code is required.'),
			new_password: Yup.string()
				.required('A password is required')
				.matches(
					passwordRegex,
					'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
				),
		}),
	},
}
