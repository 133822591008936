// @flow
import { useState } from 'react'
import useComponentDidMount from 'src/hooks/useComponentDidMount'

// Hook
export default function useWindowSize() {
	const isClient = typeof window === 'object'

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		}
	}

	const [windowSize, setWindowSize] = useState(getSize())

	useComponentDidMount(() => {
		if (!isClient) {
			return false
		}

		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, null)

	return {
		windowSize,
		windowWidth: windowSize?.width,
		windowHeight: windowSize?.height,
	}
}
