// @flow
import CartItem from 'src/apollo/_entities/cartItem'
import Location from 'src/apollo/_entities/location'
import User from 'src/apollo/_entities/user'
import AccountingCode from 'src/apollo/_entities/accountingCode'

const Cart = (cartData) => {
	if (!cartData) return null

	const itemCount = cartData?.items?.reduce((accum, curr) => {
		return accum + (curr?.quantity ?? 0)
	}, 0)

	return {
		...cartData,
		value: cartData?.id,
		label: `${cartData?.name}`,
		items: cartData?.items?.map((item) => CartItem(item)) ?? null,
		user: User(cartData?.user) ?? null,
		location: Location(cartData?.location) ?? null,
		chart_of_account: AccountingCode(cartData?.chart_of_account),
		line_of_business: AccountingCode(cartData?.line_of_business),
		itemCount,
	}
}

export default Cart
