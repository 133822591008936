// @flow
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap'
import useWindowSize from 'src/hooks/useWindowSize'
import CurrentLocationNavItem from 'src/components/TopNav/SecondaryNav/SelectCurrentLocation/CurrentLocationNavItem'

const SecondaryNavBar = () => {
	const { authUser } = useGetAuthenticatedUser()
	const { windowSize } = useWindowSize()
	const collapsed1 = windowSize?.width < 1168
	const collapsed2 = windowSize?.width < 1024
	const history = useHistory()

	const handleMyFavoritesClick = () => {
		window.scrollTo(0, 0)

		history.push({
			pathname: '/procure/store/explore',
			state: { flags: ['favorite-products'] },
		})
	}

	return (
		<div className='cm-topnav-secondary'>
			<div className='cm-ml-double cm-mr-normal'>
				<div className='d-flex flex-row'>
					<HorizontalLayout>
						<Link
							to={'/procure/store/explore'}
							className='cm-mr-double cm-topnav-secondary-link'
						>
							CATALOG
						</Link>
						<Link
							to={'/procure/store/vendors'}
							className='cm-mr-double cm-topnav-secondary-link'
						>
							VENDORS
						</Link>
						<Link
							to={'/procure/store/lists'}
							className='cm-mr-double cm-topnav-secondary-link'
						>
							SHOPPING LISTS
						</Link>
						<ConditionalRender condition={!collapsed2}>
							<Link
								to={'/procure/store/explore'}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									handleMyFavoritesClick()
								}}
								className='cm-mr-double cm-topnav-secondary-link'
							>
								MY FAVORITES
							</Link>
						</ConditionalRender>
						<ConditionalRender condition={!collapsed1}>
							<ConditionalRender
								condition={
									!!authUser?.organization
										?.off_catalog_status &&
									authUser?.organization?.hasTags([
										'module:Procure',
									])
								}
							>
								<Link
									to={'/procure/store/off_catalog'}
									className='cm-mr-double cm-topnav-secondary-link'
								>
									OFF CATALOG
								</Link>
							</ConditionalRender>
						</ConditionalRender>
						<ConditionalRender condition={collapsed1}>
							<UncontrolledDropdown>
								<DropdownToggle
									aria-expanded={false}
									aria-haspopup={true}
									caret
									nav
									color='default'
									className='cm-topnav-secondary-link'
									style={{ padding: '0 40px 0 0' }}
									data-toggle='dropdown'
									id='shop-by-nav-link'
								>
									MORE
								</DropdownToggle>
								<DropdownMenu
									className='dropdown-primary no-dropdown-arrow p-0 m-0'
									aria-labelledby='shop-by-nav-link'
								>
									<ConditionalRender condition={collapsed2}>
										<Link
											to={'/procure/store/explore'}
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
												handleMyFavoritesClick()
											}}
										>
											<DropdownItem>
												My Favorites
											</DropdownItem>
										</Link>
									</ConditionalRender>
									<ConditionalRender condition={collapsed1}>
										<ConditionalRender
											condition={
												!!authUser?.organization
													?.off_catalog_status &&
												authUser?.organization?.hasTags(
													['module:Procure']
												)
											}
										>
											<Link
												to={
													'/procure/store/off_catalog'
												}
											>
												<DropdownItem>
													Off Catalog
												</DropdownItem>
											</Link>
										</ConditionalRender>
									</ConditionalRender>
								</DropdownMenu>
							</UncontrolledDropdown>
						</ConditionalRender>
					</HorizontalLayout>
					<CurrentLocationNavItem />
				</div>
			</div>
		</div>
	)
}

export default SecondaryNavBar
