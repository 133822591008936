// @flow
import React, { useState } from 'react'
import type { CartItemType } from 'src/flowtypes/Entities/CartItemType'
import { Popover } from 'reactstrap'
import CartPreviewHeader from 'src/components/Cart/CartPreview/CartPreviewHeader'
import CartPreviewBody from 'src/components/Cart/CartPreview/CartPreviewBody'
import CartPreviewItem from 'src/components/Cart/CartPreview/CartPreviewItem'
import CartPreviewSubtotal from 'src/components/Cart/CartPreview/CartPreviewSubtotal'
import ConditionalRender from 'src/components/_generic/conditional-render'
import CartPreviewEmptyView from 'src/components/Cart/CartPreview/CartPreviewEmptyView'
import PrimaryButton from 'src/components/_generic/buttons/primary-button'
import { useHistory } from 'react-router-dom'
import Spacer from 'src/components/_generic/spacer'
import popoverSizeConfigs from 'src/configs/popover-sizing'
import ProspectiveOrderBudgetsBreakdown from 'src/components/Budgets/Checkout/ProspectiveOrderBudgetsBreakdown'
import { useIsFetching } from 'react-query'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useGetProjectedSpendByBudget } from 'src/api/cart_items/useGetProjectedSpendByBudget'

type Props = {
	cart_items: CartItemType[],
	isOpen: boolean,
	targetId: string,
}

const CartPreview = ({ cart_items, isOpen, targetId }: Props) => {
	const [isHoveringOnPreview, setIsHoveringOnPreview] = useState(false)
	const isFetchingBudgets = useIsFetching(['projected_spend_by_budget'])

	const { authUser } = useGetAuthenticatedUser()
	const { budgets } = useGetProjectedSpendByBudget(
		{
			location_id: authUser?.current_location_id,
			should_reassociate: true,
		},
		!!authUser?.current_location_id
	)

	let history = useHistory()

	const onMouseEnter = () => setIsHoveringOnPreview(true)
	const onMouseLeave = () => setIsHoveringOnPreview(false)

	return (
		<Popover
			trigger={'hover'}
			placement={'bottom'}
			isOpen={isOpen || isHoveringOnPreview}
			target={targetId}
			className={'cm-cart-preview-wrapper'}
			modifiers={{
				computeStyle: {
					fn: (data) => popoverSizeConfigs.fixedWidth(data),
				},
			}}
		>
			<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<div className='cm-p-normal'>
					<CartPreviewHeader
						cart_items={cart_items}
						onClose={onMouseLeave}
					/>
					<hr />
					<ConditionalRender
						condition={!isFetchingBudgets && budgets?.length > 0}
					>
						<ProspectiveOrderBudgetsBreakdown
							isLoading={isFetchingBudgets}
							budgets={budgets}
						/>
					</ConditionalRender>
					<CartPreviewBody>
						<ConditionalRender
							condition={!!cart_items?.length}
							failedRender={<CartPreviewEmptyView />}
						>
							{cart_items.map((cartItem) => {
								return (
									<CartPreviewItem
										key={`cart-item-${cartItem.id}`}
										cart_item={cartItem}
									/>
								)
							})}
						</ConditionalRender>
					</CartPreviewBody>
					<hr style={{ margin: 0 }} />
					<Spacer />
					<CartPreviewSubtotal cart_items={cart_items} />
					<Spacer />
					<PrimaryButton
						onClick={() => history.push('/procure/store/cart')}
						style={{ width: '100%' }}
						title='View Cart'
					/>
				</div>
			</div>
		</Popover>
	)
}

export default CartPreview
