// @flow
// react-dropzone accepts maxSize in bytes, so we default it to 10MB, or 10,000,000 bytes
import { useState } from 'react'

export function useDropzoneError(acceptFileTypes = [], maxFileSize = 10000000) {
	const [sizeError, setSizeError] = useState()
	const [fileTypeError, setFileTypeError] = useState()

	const isSizeError = (files) => {
		if (files?.length) {
			const afile = files[0]
			setSizeError(afile?.file?.size > maxFileSize)
		}
	}

	const isFileTypeError = (files) => {
		if (files?.length) {
			const afile = files[0]
			const fileExtensions = acceptFileTypes?.map((fileExtension) =>
				fileExtension?.substr(1)
			)

			const isAcceptedFileType = fileExtensions
				?.map((fileExtension) =>
					afile?.file?.type?.includes(fileExtension)
				)
				?.some((value) => value === true)
			setFileTypeError(!isAcceptedFileType)
		}
	}

	const hasErrored = (files, event) => {
		isSizeError(files, event)
		isFileTypeError(files, event)
	}

	const errorMessage = () => {
		return sizeError && fileTypeError
			? `The file you are trying to upload is too large and not of an accepted file type. Please choose a file that is smaller and of a supported type.`
			: sizeError
			? `The file you are trying to upload is too large. Please choose a file that is ${
					maxFileSize / 1000000
			  }MB or smaller.`
			: fileTypeError
			? `The file you are trying to upload is not of a supported type. Please choose another file.`
			: 'Something went wrong!'
	}

	const clearErrors = () => {
		setSizeError()
		setFileTypeError()
	}

	return { hasErrored, errorMessage, sizeError, fileTypeError, clearErrors }
}
