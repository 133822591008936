import { gql } from 'graphql-request'

export const GetProjectedSpendByBudgetQuery = {
	identifier: 'get_projected_spend_by_budget',
	gql: gql`
		query projectedSpendByBudget(
			$location_id: ID!
			$should_reassociate: Boolean
		) {
			projectedSpendByBudget(
				location_id: $location_id
				should_reassociate: $should_reassociate
			) {
				budget_allotment_id
				budget_amount
				budget_name
				current_spend
				projected_spend
				spend_percentage
			}
		}
	`,
	variables: ({ location_id, should_reassociate }) => ({
		location_id,
		should_reassociate,
	}),
	formatResults: (data) => {
		return data?.projectedSpendByBudget ?? []
	},
}
