// @flow
import { useMutation } from 'src/api/index'
import { DeleteCartItemMutation } from 'src/api/cart_items/delete-cart-item-mutation'
import { useQueryClient } from 'react-query'

export const useDeleteCartItem = () => {
	const queryClient = useQueryClient()

	return useMutation({
		key: 'delete_cart_item',
		request: DeleteCartItemMutation,
		options: {
			onSuccess: async (data) => {
				await DeleteCartItemMutation?.onSuccess(
					'delete_cart_item',
					queryClient,
					data
				)
			},
		},
	})
}
