// @flow
import React from 'react'
import { config } from 'dotenv'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

config()

const bugsnagClient = Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact(React)],
})

export default bugsnagClient
