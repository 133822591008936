// @flow
import React, { useEffect, useState } from 'react'
import AsyncMultiSelect from 'src/components/_generic/forms/async-select/async-multi-select'
import type { ManufacturerType } from 'src/flowtypes/Entities/ManufacturerType'
import { useGetManufacturersByPopularity } from 'src/api/manufacturers/useGetManufacturersByPopularity'

type Props = {
	name: string,
	label: string,
	required?: boolean,
	disabled?: boolean,
	includeAllOption?: boolean,
}

const MultiSelectManufacturersDropdown = (props: Props) => {
	const { includeAllOption } = props
	const [savingNextSet, setSavingNextSet] = useState(false)
	const [selectOptions, setSelectOptions] = useState([])
	const [searchTarget, setSearchTarget] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const {
		manufacturers,
		isLoading: loading,
		getManufacturersByPopularity,
		paginatorInfo,
	} = useGetManufacturersByPopularity(currentPage, searchTarget)

	const formatManufacturer = (manufacturer: ManufacturerType) => {
		return {
			value: manufacturer.id,
			label: `${manufacturer.name}`,
			...manufacturer,
		}
	}

	const mergeOptions = async () => {
		const selectAllOption = { value: 0, label: 'All Manufacturers' }
		const optionsArr = [...selectOptions, ...manufacturers]
		if (
			includeAllOption &&
			!selectOptions.find((item) => item.value === 0)
		) {
			optionsArr.unshift(selectAllOption)
		}
		await setSelectOptions(optionsArr.map(formatManufacturer))
		await setSavingNextSet(false)
	}

	useEffect(() => {
		setSavingNextSet(true)
	}, [currentPage])

	useEffect(() => {
		mergeOptions()
	}, [paginatorInfo?.currentPage])

	const handleLoadOptions = async (inputValue) => {
		let fetched = await getManufacturersByPopularity()
		let queryData =
			(await fetched.data?.manufacturers_by_popularity?.data) || []
		let filtered = queryData
			.map(formatManufacturer)
			.filter((mfg) =>
				mfg.label.toLowerCase().includes(inputValue.toLowerCase())
			)
		return Promise.resolve(filtered)
	}

	const handleInputChange = (searchVal) => {
		setCurrentPage(1)
		setSearchTarget(searchVal)
	}

	const onMenuScrollToBottom = () =>
		!savingNextSet && paginatorInfo.lastPage > currentPage
			? setCurrentPage(currentPage + 1)
			: {}

	return (
		<AsyncMultiSelect
			options={selectOptions}
			loading={loading}
			closeMenuOnSelect={manufacturers?.length === 1}
			onLoadMore={handleLoadOptions}
			searchTarget={searchTarget}
			onInputChange={handleInputChange}
			onMenuScrollToBottom={onMenuScrollToBottom}
			{...props}
		/>
	)
}

export default MultiSelectManufacturersDropdown
