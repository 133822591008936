// @flow
import React from 'react'
import IconButton from 'src/components/_generic/buttons/icon-button'
import type { CartItemType } from 'src/flowtypes/Entities/CartItemType'

type Props = {
	cart_items: CartItemType[],
	onClose: () => void,
}

const CartPreviewHeader = ({ cart_items, onClose }: Props) => {
	const itemCount = cart_items?.length
		? cart_items
				.map((cartItem) => cartItem?.quantity)
				.reduce((accum, current) => accum + current)
		: 0

	return (
		<>
			<div style={Style.container}>
				<p style={{ flex: 3, textAlign: 'left' }}>
					<span style={{ fontWeight: 'bold' }}>My Cart,</span>{' '}
					{itemCount} item
					{itemCount === 1 ? '' : 's'}
				</p>
				<IconButton
					icon={
						<i
							className={'cm-icon cm-icon-close'}
							style={{ fontSize: '20px' }}
						/>
					}
					onClick={onClose}
					color={'transparent'}
					className={'cm-close-cart-preview'}
				/>
			</div>
		</>
	)
}

export default CartPreviewHeader

const Style = {
	container: {
		padding: '0 8px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}
