import styled from 'styled-components'

export const DropzoneRoot = styled.div`
	display: contents;
`

export const DropzoneContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f9f9f9;
	padding: 16px;
	min-height: 250px;
`
