// @flow
import React from 'react'
import { Collapse, Input } from 'reactstrap'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import EmptyFunc from 'src/library/emptyFunc'
import { useSearchBar } from 'src/components/Search/use-search-bar'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SearchIcon } from 'src/components/Search/search.styles'
import LinkButton from 'src/components/_generic/buttons/link-button'

type Props = {
	title: string,
	onSearch?: (v: string) => any,
	onChange?: (v?: string) => any,
	initialSearchTarget?: string,
	className?: string,
	hideSubmit?: boolean,
	submitOnEnter?: boolean,
}

const SearchBar = ({
	title,
	onSearch = EmptyFunc,
	initialSearchTarget = '',
	className = '',
	hideSubmit = false,
	onChange,
	submitOnEnter,
}: Props) => {
	const [{ currentTarget }, dispatch] = useSearchBar(
		{ currentTarget: initialSearchTarget },
		onSearch
	)

	const search = (e) => {
		dispatch({
			type: 'setSearchTarget',
			payload: { currentTarget: e.target.value },
		})

		if (typeof onChange === 'function') {
			onChange(e.target.value)
		}
	}

	const onKeyDown = (e) => {
		if (submitOnEnter && e.key === 'Enter') {
			onSearch(currentTarget)
		}
	}

	const handleSearchButtonClick = () => {
		onSearch(currentTarget)
	}

	return (
		<>
			<div className={`cm-table-search-container ${className}`}>
				<HorizontalLayout>
					<div className='cm-table-search'>
						<Input
							className='cm-table-search-input'
							placeholder={title}
							type='text'
							onChange={search}
							value={currentTarget ?? ''}
							onKeyDown={onKeyDown}
							onFocus={() => dispatch({ type: 'onFocus' })}
							onBlur={() => dispatch({ type: 'onBlur' })}
						/>
						<SearchIcon icon={regular('magnifying-glass')} />
					</div>
					{!hideSubmit ? (
						<Collapse isOpen={!!currentTarget}>
							<LinkButton
								className='align-self-start'
								onClick={handleSearchButtonClick}
								title={'Search'}
							/>
						</Collapse>
					) : null}
				</HorizontalLayout>
			</div>
		</>
	)
}

export default SearchBar
