// @flow
import React from 'react'
import MenuConfigs from 'src/configs/side-menu'
import SideMenuSectionHeader from 'src/components/SideMenu/SideMenuContent/SideMenuHeader/side-menu-section-header'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import ShouldDisplayRender from 'src/components/SideMenu/_generic/ShouldDisplayRender'
import SideMenuContentItemList from 'src/components/SideMenu/SideMenuContent/SideMenuItem/side-menu-content-item-list'
import LDRender from 'src/components/_generic/ld-render'

const SideMenuContent = () => {
	const { authUser } = useGetAuthenticatedUser()

	if (!authUser) return null

	return (
		<>
			{MenuConfigs.sections.map((section) => (
				<LDRender
					flagNames={section?.ldFlags ?? []}
					key={`sidebar-section-ld-flag-${section.title}`}
				>
					<div
						style={Styles.section}
						key={`sidebar-section-${section.title}`}
					>
						<ShouldDisplayRender targetConfig={section}>
							<SideMenuSectionHeader title={section.title} />
							<div style={Styles.sectionContent}>
								<SideMenuContentItemList section={section} />
							</div>
						</ShouldDisplayRender>
					</div>
				</LDRender>
			))}
		</>
	)
}

export default SideMenuContent

const Styles = {
	section: {
		margin: 20,
	},
	sectionContent: {
		paddingLeft: 10,
		marginTop: 10,
		marginBottom: 20,
	},
}
