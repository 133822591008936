// @flow
import { Auth } from 'aws-amplify'
import { useShowToast } from 'src/_boot/ToastContext'

const Authenticator = (onStateChange) => {
	const { showSuccessToast, showErrorToast } = useShowToast()

	const signIn = ({ email, password }) => {
		return Auth.signIn({ username: email?.trim(), password: password })
			.then(() => onStateChange('signedIn'))
			.catch((error) => {
				if (error.code === 'UserNotConfirmedException') {
					onStateChange('confirmSignUp', {
						email: email?.trim(),
						password: password,
					})
					// eslint-disable-next-line no-empty
				} else if (error.code === 'PasswordResetRequiredException') {
				} else if (
					error.code === 'NotAuthorizedException' ||
					error.code === 'UserNotFoundException'
				) {
					showErrorToast('Invalid email and/or password.')
					onStateChange('signIn', { email: email?.trim() })
				} else {
					showErrorToast(error.message)
				}
			})
	}

	const signUp = (account, onSignUpSucccess) => {
		return Auth.signUp({
			username: account.email?.trim(),
			password: account.password,
			attributes: { email: account.email, name: account.name },
		})
			.then(async (user) => {
				await onSignUpSucccess(user)
				return user
			})
			.then((user) => {
				if (!user.userConfirmed) {
					onStateChange('confirmSignUp', {
						email: account.email?.trim(),
						password: account.password,
					})
				} else {
					onStateChange('signedIn')
				}
			})
			.catch((error) => {
				showErrorToast(error.message)
			})
	}

	const resendSignUp = (email) =>
		Auth.resendSignUp(email?.trim())
			.then(() => {
				showSuccessToast(
					'A new verification code has been sent to the email address associated with this account.'
				)
			})
			.catch(() => {})

	const confirmSignUp = ({ email, password, code }) => {
		return Auth.confirmSignUp(email?.trim(), code)
			.then((status) => {
				if (status === 'SUCCESS') {
					return signIn({ email: email?.trim(), password })
				} else {
					showErrorToast('Unable to confirm please try again later.')
				}
			})
			.catch((error) => {
				showErrorToast(error.message)
			})
	}

	const forgotPassword = (email) => {
		return Auth.forgotPassword(email?.trim()).catch((error) => {
			showErrorToast(error.message)
		})
	}

	const forgotPasswordSubmit = ({ code, new_password, email }) => {
		Auth.forgotPasswordSubmit(email?.trim(), code, new_password)
			.then(() => {
				showSuccessToast('Your password has been updated.')
				return signIn({ email: email?.trim(), password: new_password })
			})
			.catch((error) => {
				showErrorToast(error.message)
			})
	}

	return {
		signIn,
		signUp,
		confirmSignUp,
		resendSignUp,
		forgotPassword,
		forgotPasswordSubmit,
	}
}
export default Authenticator
