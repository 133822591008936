// @flow
import React from 'react'
import { Progress } from 'reactstrap'
import CurrentSpendPopover from 'src/components/Budgets/Checkout/ProgressBar/Popovers/current-spend-popover'
import ProgressBarChildDiv from 'src/components/_generic/progress-bars/progress-bar-child-div'
import ProjectedSpendPopover from 'src/components/Budgets/Checkout/ProgressBar/Popovers/projected-spend-popover'
import RemainingSpendPopover from 'src/components/Budgets/Checkout/ProgressBar/Popovers/remaining-spend-popover'

type Props = {
	budget: Object,
	maxValue: number,
}

const HappyProgressBar = ({ budget, maxValue }: Props) => {
	const {
		budget_allotment_id,
		budget_amount,
		current_spend,
		projected_spend,
	} = budget

	return (
		<>
			<CurrentSpendPopover
				key={`current-spend-popover-${budget_allotment_id}`}
				budget={budget}
				targetId={`happy-spend-bar-current-${budget_allotment_id}`}
			/>
			<Progress
				key={`current-spend-progress-bar-${budget_allotment_id}`}
				bar
				color={'info'}
				max={maxValue}
				value={current_spend}
			>
				<ProgressBarChildDiv
					key={`current-spend-div-${budget_allotment_id}`}
					id={`happy-spend-bar-current-${budget_allotment_id}`}
				/>
			</Progress>

			<ProjectedSpendPopover
				key={`projected-spend-popover-${budget_allotment_id}`}
				budget={budget}
				targetId={`happy-spend-bar-projected-${budget_allotment_id}`}
			/>
			<Progress
				key={`projected-spend-progress-bar-${budget_allotment_id}`}
				bar
				barClassName={'cm-progress-bar-purple'}
				max={maxValue}
				value={projected_spend}
			>
				<ProgressBarChildDiv
					key={`projected-spend-div-${budget_allotment_id}`}
					id={`happy-spend-bar-projected-${budget_allotment_id}`}
				/>
			</Progress>

			<RemainingSpendPopover
				key={`remaining-spend-popover-${budget_allotment_id}`}
				budget={budget}
				targetId={`happy-spend-bar-remaining-${budget_allotment_id}`}
			/>
			<Progress
				key={`remaining-spend-progress-bar-${budget_allotment_id}`}
				bar
				barClassName={'cm-progress-bar-grey'}
				max={maxValue}
				value={budget_amount - (current_spend + projected_spend)}
			>
				<ProgressBarChildDiv
					key={`remaining-spend-div-${budget_allotment_id}`}
					id={`happy-spend-bar-remaining-${budget_allotment_id}`}
				/>
			</Progress>
		</>
	)
}

export default HappyProgressBar
