// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import UserProfileImage from 'src/components/Users/user-profile-image'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { useHistory } from 'react-router-dom'
import DropdownItem from 'src/pages/_components/Dropdowns/dropdown-item'
import { HorizontalDivider } from 'src/pages/_styles/spacing.styles'

const TopNavbarUserMenu = () => {
	const [logout, setLogout] = useState()
	const { authUser } = useGetAuthenticatedUser()
	const history = useHistory()

	useEffect(() => {
		if (logout && authUser) {
			handleLogout()
		}
	}, [logout, authUser])

	const handleLogout = useCallback(async () => {
		if (authUser) {
			await authUser.signOut()
			history.push('/')
			window.location.reload(true)
			return
		}
		setLogout(true)
	}, [authUser])

	return (
		<UncontrolledDropdown nav inNavbar>
			<DropdownToggle
				className='mr-1 ml-5 no-spacing'
				color='primary'
				nav
				style={{ margin: '15px 0', padding: 0 }}
			>
				<div className='no-spacing cm-topnav-user-section'>
					<div className='d-inline-block cm-topnav-user-name-label'>
						{authUser?.name}
						<div>{authUser?.organization?.name}</div>
					</div>
					<div className='float-right cm-profile-image-medium mr-0'>
						<UserProfileImage
							imagePath={authUser?.image_path}
							size='medium'
						/>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu className='dropdown-primary' right>
				<DropdownItem
					title={'My Account'}
					onClick={() => history.push('/my/account')}
				/>
				<HorizontalDivider />

				<DropdownItem title={'Log Out'} onClick={handleLogout} />
			</DropdownMenu>
		</UncontrolledDropdown>
	)
}

export default TopNavbarUserMenu
