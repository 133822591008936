// @flow
import React from 'react'
import { DropzonePromptIcon } from 'src/components/_generic/dropzone/dropzone.styles'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

type Props = {
	message?: string,
}

const DropzonePromptError = ({
	message = `Something is wrong with the file you're trying to import`,
}: Props) => (
	<>
		<DropzonePromptIcon
			icon={solid('circle-exclamation')}
			color={'red'}
			size={'xl'}
		/>
		<h3 className={'text-center'}>{message}</h3>
	</>
)

export default DropzonePromptError
