// @flow
import React, { useEffect, useState } from 'react'
import { Badge } from 'src/components/_generic/badge'
import { FlexRow } from 'src/pages/_styles/container.styles'

type Props = {
	isActive?: boolean,
	item: Object,
	issuesCount: number,
	svg?: Node,
}

const SideMenuContentItem = ({
	isActive = false,
	item,
	issuesCount,
}: Props) => {
	const [alertCount, setAlertCount] = useState(0)
	const iconType = !!item?.svg ? 'svg' : !!item?.fontAwesome ? 'fa' : 'nucleo'

	useEffect(() => {
		if (item?.link === '/procure/store/orders') {
			setAlertCount(issuesCount)
		}
	}, [item, issuesCount])

	const tooltipMessage = () =>
		alertCount > 0 && item?.alertReason ? item.alertReason : ''

	const icon = () => {
		switch (iconType) {
			case 'svg':
				return <i style={Styles.iconContainer}>{item?.svg}</i>
			case 'fa':
				return <i style={Styles.iconContainer}>{item?.fontAwesome}</i>
			case 'nucleo':
				return (
					<i
						className={`cm-icon ${item.icon} liIcon`}
						style={{ color: item.iconColor }}
					/>
				)
			default:
				break
		}
	}

	return (
		<>
			<FlexRow className='listItemContainer'>
				{icon()}
				<div style={{ position: 'relative' }}>
					<p
						className={`listItem ${
							isActive ? 'cm-list-item-active' : ''
						}`}
					>
						{item.name}
					</p>
				</div>
				<Badge
					badgeCount={alertCount}
					type={item?.alertType === 'warning' ? 'warning' : 'danger'}
					message={tooltipMessage}
				/>
			</FlexRow>
		</>
	)
}

export default SideMenuContentItem

const Styles = {
	iconContainer: {
		lineHeight: '0',
	},
}
