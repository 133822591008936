// @flow

import MoneyFormatter from 'src/library/MoneyFormatter'
import ObjectUtils from 'src/library/ObjectUtils/ObjectUtils'

const UpsertCartItemDTO = (cartItemData) => {
	const cartItem = ObjectUtils(cartItemData)
	if (cartItem.isEmpty) return null

	const emptyStringOptions = { allowEmptyStrings: false }
	const moneyOptions = {
		transform: (dollars) => MoneyFormatter.toPennies(dollars),
	}
	const intOptions = {
		transform: (int) => parseInt(int),
	}

	return {
		cart_id: cartItem.getKeyValue('cart_id'),
		product_id: cartItem.getKeyValue('product_id'),
		formulary_id: cartItem.getKeyValue('formulary_id'),
		price_id: cartItem.getKeyValue('price_id'),
		vendor_id: cartItem.getKeyValue('vendor_id', emptyStringOptions),
		sku: cartItem.getKeyValue('sku', emptyStringOptions),
		chart_of_account: cartItem.getKeyValue('chart_of_account'),
		chart_of_account_id: cartItem.getKeyValue('chart_of_account_id'),
		name: cartItem.getKeyValue('name'),
		units_of_measure_id: cartItem.getKeyValue('units_of_measure_id'),
		quantity: cartItem.getKeyValue('quantity', intOptions),
		unit_price: !!cartItemData?.price
			? cartItem.getKeyValue('price', moneyOptions)
			: cartItem.getKeyValue('unit_price'),
		total: undefined,
		location_id: cartItem.getKeyValue('location_id'),
		line_of_business: cartItem.getKeyValue('line_of_business'),
		line_of_business_id: cartItem.getKeyValue('line_of_business_id'),
	}
}

export default UpsertCartItemDTO
