// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { UncontrolledPopover } from 'reactstrap'

type Props = {
	className?: string,
	type: 'warning' | 'danger' | 'success' | 'default',
	message?: string,
}

const BadgeDot = ({ type = 'warning', message = null, className }: Props) => {
	return (
		<>
			<span
				id={`badge-${message}`}
				className={`badge badge-${type} ${
					className ? ` ${className}` : ''
				}`}
				style={Styles.badge}
			/>
			<ConditionalRender condition={message}>
				<UncontrolledPopover
					trigger={'hover'}
					placement={'bottom'}
					target={`badge-${message}`}
				>
					<div style={{ padding: '5px' }}>{message}</div>
				</UncontrolledPopover>
			</ConditionalRender>
		</>
	)
}

export { BadgeDot }

const Styles = {
	badge: {
		display: 'inline-block',
		width: 10,
		height: 10,
		padding: 0,
		margin: 0,
	},
}
