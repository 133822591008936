// @flow

export default {
	UNKNOWN: 1,
	RECEIVED: 10,
	REJECTED: 20,
	PARTIALLY_RECEIVED: 30,
	SENDING: 37,
	SHIPPED: 38,
	VENDOR_CONFIRMED: 39,
	SENT_TO_VENDOR: 40,
	AWAITING_VENDOR_RESPONSE: 41,
	PENDING_APPROVAL: 50,
	WAITING_TO_BE_SENT: 60,
	PARTIALLY_APPROVED: 70,
	PARTIALLY_SHIPPED: 71,
	DENIED: 80,

	UNKNOWN_HUMAN_READABLE: 'Unknown',
	RECEIVED_HUMAN_READABLE: 'Received',
	REJECTED_HUMAN_READABLE: 'Rejected',
	PARTIALLY_RECEIVED_HUMAN_READABLE: 'Partially Received',
	SHIPPED_HUMAN_READABLE: 'Shipped',
	VENDOR_CONFIRMED_HUMAN_READABLE: 'Vendor Confirmed',
	SENDING_HUMAN_READABLE: 'Sending',
	AWAITING_VENDOR_RESPONSE_HUMAN_READABLE: 'Awaiting Vendor Response',
	SENT_TO_VENDOR_HUMAN_READABLE: 'Sent to Vendor',
	PENDING_APPROVAL_HUMAN_READABLE: 'Pending Approval',
	WAITING_TO_BE_SENT_HUMAN_READABLE: 'Waiting to Be Sent',
	PARTIALLY_APPROVED_HUMAN_READABLE: 'Partially Approved',
	PARTIALLY_SHIPPED_HUMAN_READABLE: 'Partially Shipped',
	DENIED_HUMAN_READABLE: 'Denied',

	getOrderJourneySteps: function () {
		return [
			this.PENDING_APPROVAL,
			this.SENT_TO_VENDOR,
			this.VENDOR_CONFIRMED,
			this.SHIPPED,
			this.RECEIVED,
		]
	},

	getOrderJourneyStepForStatus: function (status: number): number {
		switch (status) {
			case this.PENDING_APPROVAL:
			case this.DENIED:
			case this.WAITING_TO_BE_SENT:
				return this.PENDING_APPROVAL
			case this.SENDING:
				return this.SENDING
			case this.AWAITING_VENDOR_RESPONSE:
				return this.AWAITING_VENDOR_RESPONSE
			case this.SENT_TO_VENDOR:
				return this.SENT_TO_VENDOR
			case this.VENDOR_CONFIRMED:
				return this.VENDOR_CONFIRMED
			case this.SHIPPED:
			case this.PARTIALLY_SHIPPED:
			case this.REJECTED:
				return this.SHIPPED
			case this.RECEIVED:
			case this.PARTIALLY_RECEIVED:
				return this.RECEIVED
			default:
				return this.PENDING_APPROVAL
		}
	},

	getHumanReadable: function (status: number): string {
		switch (status) {
			case this.RECEIVED:
				return this.RECEIVED_HUMAN_READABLE
			case this.REJECTED:
				return this.REJECTED_HUMAN_READABLE
			case this.PARTIALLY_RECEIVED:
				return this.PARTIALLY_RECEIVED_HUMAN_READABLE
			case this.SHIPPED:
				return this.SHIPPED_HUMAN_READABLE
			case this.VENDOR_CONFIRMED:
				return this.VENDOR_CONFIRMED_HUMAN_READABLE
			case this.SENDING:
				return this.SENDING_HUMAN_READABLE
			case this.AWAITING_VENDOR_RESPONSE:
				return this.AWAITING_VENDOR_RESPONSE_HUMAN_READABLE
			case this.SENT_TO_VENDOR:
				return this.SENT_TO_VENDOR_HUMAN_READABLE
			case this.PENDING_APPROVAL:
				return this.PENDING_APPROVAL_HUMAN_READABLE
			case this.WAITING_TO_BE_SENT:
				return this.WAITING_TO_BE_SENT_HUMAN_READABLE
			case this.PARTIALLY_APPROVED:
				return this.PARTIALLY_APPROVED_HUMAN_READABLE
			case this.PARTIALLY_SHIPPED:
				return this.PARTIALLY_SHIPPED_HUMAN_READABLE
			case this.DENIED:
				return this.DENIED_HUMAN_READABLE
			default:
				return this.UNKNOWN_HUMAN_READABLE
		}
	},

	getStatusFromHumanReadable: function (status: string): number {
		switch (status) {
			case this.RECEIVED_HUMAN_READABLE:
				return this.RECEIVED
			case this.REJECTED_HUMAN_READABLE:
				return this.REJECTED
			case this.PARTIALLY_RECEIVED_HUMAN_READABLE:
				return this.PARTIALLY_RECEIVED
			case this.SENDING_HUMAN_READABLE:
				return this.SENDING
			case this.AWAITING_VENDOR_RESPONSE_HUMAN_READABLE:
				return this.AWAITING_VENDOR_RESPONSE
			case this.SHIPPED_HUMAN_READABLE:
				return this.SHIPPED
			case this.VENDOR_CONFIRMED_HUMAN_READABLE:
				return this.VENDOR_CONFIRMED
			case this.SENT_TO_VENDOR_HUMAN_READABLE:
				return this.SENT_TO_VENDOR
			case this.PENDING_APPROVAL_HUMAN_READABLE:
				return this.PENDING_APPROVAL
			case this.WAITING_TO_BE_SENT_HUMAN_READABLE:
				return this.WAITING_TO_BE_SENT
			case this.PARTIALLY_APPROVED_HUMAN_READABLE:
				return this.PARTIALLY_APPROVED
			case this.PARTIALLY_SHIPPED_HUMAN_READABLE:
				return this.PARTIALLY_SHIPPED
			case this.DENIED_HUMAN_READABLE:
				return this.DENIED
			default:
				return this.UNKNOWN
		}
	},

	getManuallySettableStatuses: function (): number[] {
		return [this.SENT_TO_VENDOR, this.VENDOR_CONFIRMED, this.SHIPPED]
	},

	getAllOrderStatuses: function () {
		return [
			this.RECEIVED,
			this.REJECTED,
			this.PARTIALLY_RECEIVED,
			this.SHIPPED,
			this.VENDOR_CONFIRMED,
			this.SENT_TO_VENDOR,
			this.PENDING_APPROVAL,
			this.WAITING_TO_BE_SENT,
			this.PARTIALLY_APPROVED,
			this.PARTIALLY_SHIPPED,
			this.SENDING,
			this.AWAITING_VENDOR_RESPONSE,
			this.DENIED,
		]
	},
}
