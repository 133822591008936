// @flow
import React, { createContext, useContext } from 'react'
import useCheckoutStateReducer from 'src/components/Cart/Checkout/_state/useCheckoutStateReducer'
import { useCheckoutStateHelpers } from 'src/components/Cart/Checkout/_state/useCheckoutStateHelpers'
import { useQueryModifiers } from 'src/components/Search/useQueryModifiers'
import { useGetAccountingCodes } from 'src/api/accounting_codes/useGetAccountingCodes'
import enums from 'src/configs/enums'
import { useGetCartList } from 'src/api/carts/useGetCartList'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

const CheckoutStateContext = createContext()
const CheckoutDispatchContext = createContext()

export function useCheckoutState() {
	const checkoutState = useContext(CheckoutStateContext)
	const dispatch = useContext(CheckoutDispatchContext)
	const helpers = useCheckoutStateHelpers(checkoutState, dispatch)
	const { authUser } = useGetAuthenticatedUser()

	const { modifiers } = useQueryModifiers()
	const { accounting_codes: lines_of_business } = useGetAccountingCodes({
		...modifiers,
		filters: {
			...modifiers?.filters,
			accountingCodeTypes: [enums.accountingCodeType.LINE_OF_BUSINESS],
		},
	})

	const { carts: cart, isLoading } = useGetCartList({
		...modifiers,
		filters: {
			...modifiers?.filters,
			locations: [authUser?.current_location_id],
			flags: ['my-carts'],
		},
	})

	return [
		{
			...checkoutState,
			location: authUser?.current_location,
			cart: cart[0],
			isLoading,
			has_lines_of_business: !!lines_of_business.length,
			cart_items_have_lines_of_business:
				cart[0]?.items?.filter((item) => !item.line_of_business_id)
					?.length === 0,
		},
		helpers,
		dispatch,
	]
}

export const withCheckoutState = (Component) => (props) => {
	const [state, dispatch] = useCheckoutStateReducer()

	return (
		<CheckoutStateContext.Provider value={state}>
			<CheckoutDispatchContext.Provider value={dispatch}>
				<Component {...props} />
			</CheckoutDispatchContext.Provider>
		</CheckoutStateContext.Provider>
	)
}
