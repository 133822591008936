// @flow
import { useEffect, useState } from 'react'

const useCumulativeList = (fetchedData) => {
	const [cumulativeList, setCumulativeList] = useState([])
	const seralizedData = JSON.stringify(fetchedData)

	const resetList = (resetData = []) => {
		setCumulativeList(resetData)
	}

	useEffect(() => {
		if (fetchedData?.length > 0) {
			const ids = cumulativeList?.map((item) => item?.id)

			setCumulativeList([
				...new Set([
					...cumulativeList,
					...fetchedData?.filter((item) => !ids?.includes(item?.id)),
				]),
			])
		}
	}, [seralizedData])

	return { cumulativeList, resetList }
}

export default useCumulativeList
