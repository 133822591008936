// @flow
import React, { useState } from 'react'
import type { LocationType } from 'src/flowtypes/Entities/LocationType'
import { useUpdateUser } from 'src/api/users/useUpdateUser'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import LinkButton from 'src/components/_generic/buttons/link-button'

type Props = {
	location: LocationType,
	onClose: () => any,
}

const SelectCurrentLocationRow = ({ location, onClose }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const [currentLocation, setCurrentLocation] = useState(location)

	const { mutate: updateUser } = useUpdateUser()

	const handleClick = async () => {
		onClose()
		setCurrentLocation(location)
		return await updateUser({
			id: authUser?.id,
			input: { current_location_id: currentLocation?.id },
		})
	}
	return (
		<tr>
			<td className='text-left'>
				<LinkButton
					key={`select-location-${location?.id}`}
					title={location?.name}
					onClick={handleClick}
					style={styles.link}
				/>
			</td>
		</tr>
	)
}

export default SelectCurrentLocationRow

const styles = {
	link: {
		fontWeight: 400,
		textTransform: 'capitalize',
		padding: 0,
	},
}
