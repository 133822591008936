import OrganizationVendorContact from 'src/apollo/_entities/organizationVendorContact'
import ShippingRule from 'src/apollo/_entities/shippingRule'

const OrganizationVendor = (orgVendorData) => {
	if (!orgVendorData) return null

	const hasOrderEmail =
		!!orgVendorData?.order_email ||
		!!orgVendorData?.vendor?.default_order_email

	const hasAccountNumber = !!orgVendorData?.account_number

	const showSetup = !hasAccountNumber || !hasOrderEmail

	const canPlaceOrder =
		orgVendorData?.can_place_order ||
		(hasOrderEmail &&
			hasAccountNumber &&
			orgVendorData?.procurement_is_active)

	const shipping_rules = (
		orgVendorData?.shipping_rules?.sort(
			(a, b) => a?.target_total - b?.target_total
		) ?? []
	).map((rule) => ShippingRule(rule))

	const hasShippingRules = shipping_rules?.length > 0

	const secretConfigs = orgVendorData?.secret_configs

	return {
		...orgVendorData,
		value: orgVendorData?.vendor?.id,
		label: orgVendorData?.vendor?.name,
		image_path: orgVendorData?.vendor?.image_path,
		name: orgVendorData?.vendor?.name,
		order_email:
			orgVendorData?.order_email ??
			orgVendorData?.vendor?.default_order_email,
		contacts: orgVendorData?.contacts?.map((contact) =>
			OrganizationVendorContact(contact)
		),
		procurement_is_active: orgVendorData?.procurement_is_active,
		secret_configs: secretConfigs ? JSON.parse(secretConfigs) : null,
		shipping_rules,
		hasOrderEmail,
		hasAccountNumber,
		showSetup,
		canPlaceOrder,
		hasShippingRules,
	}
}

export default OrganizationVendor
