// @flow
import { useCallback, useState } from 'react'
import filesize from 'src/library/filesize'
import { v4 as uuidv4 } from 'uuid'
import useComponentDidMount from 'src/hooks/useComponentDidMount'

const acceptableImageTypes = ['image/png', 'image/jpeg', 'image/jpg']

export default function useImageLoader() {
	const [image, setImage] = useState()
	const [file, setFile] = useState()
	const [fileInfo, setFileInfo] = useState()
	const [loading, setLoading] = useState(false)
	const isClient = typeof window === 'object'

	const handlePasteEvent = (e) => {
		const files = e?.clipboardData?.files
		if (acceptableImageTypes.includes(files[0]?.type)) {
			loadImage(files)
		}
	}

	const addEventListener = () => {
		window.addEventListener('paste', handlePasteEvent)
	}

	const removeEventListener = () => {
		window.removeEventListener('paste', handlePasteEvent)
	}

	useComponentDidMount(() => {
		if (!isClient) {
			return false
		}

		addEventListener()
		return removeEventListener
	}, null)

	const loadImage = useCallback((acceptedFiles) => {
		setLoading(true)
		if (acceptedFiles.length > 0) {
			const afile = acceptedFiles[0]

			const reader = new FileReader()
			reader.onloadend = () => {
				setImage(reader.result)
			}
			reader.readAsDataURL(afile)

			setFile(afile)
			setFileInfo({
				name: `${uuidv4()}-${afile.name.replace(/\s+/g, '')}`,
				display_name: afile.name.replace(/\s+/g, ''),
				type: afile.type,
				size: filesize(afile.size, {
					fixed: 2,
					spacer: ' ',
				}).human('si'),
			})
		}
		setLoading(false)
	}, [])

	const clearImage = () => {
		setImage()
	}

	return { image, file, fileInfo, loadImage, clearImage, loading, setLoading }
}
