// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { Alert } from 'reactstrap'
import ImportUploadPromptView from 'src/components/_generic/dropzone/import-upload-prompt-view'
import useImageLoader from 'src/hooks/useImageLoader'
import cropImage from 'src/components/_generic/image-picker/_cropImage'
import ConditionalRender from 'src/components/_generic/conditional-render'
import ImageCropper from 'src/components/_generic/image-picker/ImageCropper'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import NewModal from 'src/components/_generic/new-modal/new-modal'
import CancelSubmitButtonFooter from 'src/components/_generic/new-modal/footers/cancel-submit-button-footer'
import { useAwsPresignedUpload } from 'src/api/aws/useAwsPresignedUpload'
import ColorTypes from 'src/configs/Enums/ColorTypes'
import { DropzoneContent } from 'src/pages/_components/Dropzone/Dropzone.styles'
import DropzoneDraggingBoundary from 'src/pages/_components/Dropzone/DropzoneDraggingBoundary'
import LoadingBoundary from 'src/pages/_components/Boundaries/LoadingBoundary'
import DropzonePromptLoading from 'src/components/_generic/dropzone/dropzone-prompt-loading'
import DropzoneErrorBoundary from 'src/pages/_components/Dropzone/DropzoneErrorBoundary'
import { DropzoneContextProvider } from 'src/pages/_components/Dropzone/DropzoneContext'

type Props = {
	isOpen: boolean,
	onCloseClick: (any) => any,
	onImageUploaded: (string) => any,
}

const ImagePickerModal = ({
	isOpen = false,
	onCloseClick,
	onImageUploaded,
}: Props) => {
	const [isUploading, setIsUploading] = useState(false)
	const [error, setError] = useState()
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [shouldDisplayCropper, setShouldDisplayCropper] = useState(false)
	const { image, file, fileInfo, loadImage, clearImage } = useImageLoader()
	const { reportError } = useErrorHandler()

	const loadTemporaryImage = (image) => {
		loadImage(image)
		setShouldDisplayCropper(true)
	}

	const { mutate: getPresignedUploadUrl } = useAwsPresignedUpload()

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const showError = () =>
		setError(
			'The server is not currently accepting this upload.  Please try again later.'
		)

	useEffect(() => {
		if (image) {
			setShouldDisplayCropper(true)
		}
	}, [image, file, fileInfo])

	useEffect(() => {
		setIsUploading(false)
		setShouldDisplayCropper(false)
	}, [isOpen])

	const onClose = async () => {
		await clearImage()
		onCloseClick()
	}

	const uploadImage = async () => {
		if (image) {
			const croppedImage = await cropImage(image, croppedAreaPixels)
			const uploadFile = new File([croppedImage], fileInfo?.name, {
				type: 'image/jpeg',
			})
			await loadImage([uploadFile])
			setIsUploading(true)
			getPresignedUploadUrl(
				{
					fileInfo: {
						filename: fileInfo?.name,
						directory: 'images',
						filetype: fileInfo?.type,
					},
				},
				{
					onError: (error) => {
						reportError(error)
						showError()
						setIsUploading(false)
					},
					onSuccess: (data) => {
						fetch(data, {
							method: 'PUT',
							headers: {
								'Content-Type': fileInfo.type,
							},
							body: uploadFile,
						})
							.then((result) => {
								// make api request to save stuff
								if (result.status === 200) {
									onImageUploaded(fileInfo.name)
									onCloseClick()
									clearImage()
								} else {
									showError()
									setIsUploading(false)
								}
							})
							.catch((e) => {
								reportError(e)
								showError()
								setIsUploading(false)
							})
					},
				}
			)
		}
	}
	return (
		<NewModal
			size={'md'}
			title={'Change Image'}
			footer={
				<CancelSubmitButtonFooter
					submitTitle={'Save'}
					onCancel={onClose}
					onSubmit={uploadImage}
					disabled={isUploading}
				/>
			}
			open={isOpen}
			onClose={onClose}
			noScrollHover={true}
			overflowVisible={false}
		>
			<div style={{ padding: '20px' }}>
				{error ? (
					<Alert color={ColorTypes.DANGER}>{error}</Alert>
				) : null}
				<ConditionalRender condition={!shouldDisplayCropper}>
					<DropzoneContextProvider onDrop={loadTemporaryImage}>
						<DropzoneContent>
							<DropzoneDraggingBoundary>
								<LoadingBoundary
									isLoading={
										isUploading || shouldDisplayCropper
									}
									component={DropzonePromptLoading}
								>
									<DropzoneErrorBoundary>
										<ImportUploadPromptView
											iconClassName='cm-icon cm-icon-cloud-upload'
											acceptedTypes={['.jpg', '.png']}
										/>
									</DropzoneErrorBoundary>
								</LoadingBoundary>
							</DropzoneDraggingBoundary>
						</DropzoneContent>
					</DropzoneContextProvider>
				</ConditionalRender>
				<ConditionalRender
					condition={shouldDisplayCropper && image && !isUploading}
				>
					<ImageCropper
						image={image}
						onCropComplete={onCropComplete}
					/>
				</ConditionalRender>
				<ConditionalRender condition={isUploading}>
					<div
						style={{
							width: '100%',
							height: '400px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<i
							className={
								'cm-icon cm-icon-circle-02 cm-icon-spin cm-icon-xl'
							}
						/>
					</div>
				</ConditionalRender>
			</div>
		</NewModal>
	)
}

export default ImagePickerModal
