import { gql } from 'graphql-request'

export const AwsPresignedUploadMutation = {
	identifier: 'aws_presigned_upload',
	gql: gql`
		mutation getAWSPresignedUpload($fileInfo: AWSPresignedUploadInput!) {
			getAWSPresignedUpload(fileInfo: $fileInfo) {
				url
			}
		}
	`,
	variables: ({ fileInfo }) => ({
		fileInfo,
	}),
	formatResults: (data) => {
		return data?.getAWSPresignedUpload?.url
	},
	onSuccess: (data) => {
		return data
	},
	onError: (error) => {
		return error
	},
}
