// @flow
import React from 'react'
import TransparentOverlay from 'src/components/_generic/transparent-overlay'
import SideMenuContent from 'src/components/SideMenu/SideMenuContent/side-menu-content'
import SideMenuVersion from 'src/components/SideMenu/side-menu-version'
import UserProfileImage from 'src/components/Users/user-profile-image'
import CureMintLogo from 'src/components/_generic/curemint-logo'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import { Link } from 'react-router-dom'
import SuperSlideyBar from 'src/components/SideMenu/SuperSlideyBar'
import SideMenuEnvironment from 'src/components/SideMenu/side-menu-environment'
import DisabledOverlay from 'src/components/_generic/disabled-overlay'
import { useRightSidePanel } from 'src/components/RightSidePanel/withRightSidePanel'

type Props = {
	isExpanded: boolean,
}
const SideMenu = ({ isExpanded }: Props) => {
	const { authUser } = useGetAuthenticatedUser()
	const { isDisabledOverlay } = useRightSidePanel()

	return (
		<>
			<SuperSlideyBar isExpanded={isExpanded}>
				<DisabledOverlay disabled={isDisabledOverlay}>
					<div style={styles.container}>
						<Link to={'/'}>
							<CureMintLogo
								className={'cm-mobile-hidden'}
								style={styles.cmLogo}
							/>
						</Link>
						<div className='text-center cm-full-hidden'>
							<UserProfileImage
								imagePath={authUser?.image_path}
								size='large'
								className='cm-mt-normal cm-mb-half'
							/>
							<h6>
								{authUser?.name}
								<br />
								<small>{authUser?.email}</small>
							</h6>
							<div className='cm-p-half'>
								<hr />
							</div>
						</div>
						<div style={styles.menuContent}>
							<SideMenuContent />
						</div>
						<div style={styles.menuVersion}>
							<SideMenuVersion />
						</div>
					</div>
					<SideMenuEnvironment />
				</DisabledOverlay>
			</SuperSlideyBar>
			<TransparentOverlay shouldDisplay={isExpanded} />
		</>
	)
}

export default SideMenu

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '117.5%',
	},
	cmLogo: {
		margin: '20px 40px 40px 40px',
	},
	menuContent: {
		flex: '1',
	},
	menuVersion: {
		height: 20,
	},
}
