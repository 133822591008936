// @flow
import React, { Node } from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { Link } from 'react-router-dom'
import ShouldDisplayRender from 'src/components/SideMenu/_generic/ShouldDisplayRender'
import DisabledFeatureOverlayWithTooltip from 'src/components/_generic/disabled-feature-overlay-with-tooltip'

type Props = { item: Object, children?: Node }

const SideMenuContentItemLink = ({ item, children }: Props) => {
	const content = (
		<ConditionalRender
			condition={item?.externalLink && !item?.link}
			failedRender={
				<ConditionalRender condition={item?.link}>
					<Link to={item?.link ?? '/'}>{children}</Link>
				</ConditionalRender>
			}
		>
			<a
				target='_blank'
				rel='noopener noreferrer'
				href={item?.externalLink ?? '#'}
			>
				{children}
			</a>
		</ConditionalRender>
	)

	const handleFeatureOverlay = (item) => {
		if (item?.featureKey) {
			return (
				<DisabledFeatureOverlayWithTooltip
					featureKey={item?.featureKey}
					tooltipText={item?.tooltipText}
					tooltipPlacement={'bottom'}
				>
					{content}
				</DisabledFeatureOverlayWithTooltip>
			)
		} else {
			return content
		}
	}

	return (
		<ShouldDisplayRender targetConfig={item}>
			{handleFeatureOverlay(item)}
		</ShouldDisplayRender>
	)
}

export default SideMenuContentItemLink
