// @flow
import { useQuery } from 'src/api/index'
import { GetOrgHasProductTagsQuery } from 'src/api/tags/get-org-has-product-tags'

export const useGetOrgHasProductTags = (organization_id) => {
	const { data: orgHasProductTags, ...rest } = useQuery({
		key: ['getOrgHasProductTags', { organization_id: organization_id }],
		request: GetOrgHasProductTagsQuery,
		options: {
			enabled: !!organization_id,
			staleTime: 1000 * 60 * 10,
		},
	})

	return { orgHasProductTags, ...rest }
}
