// @flow
import Product from 'src/apollo/_entities/product'
import Price from 'src/apollo/_entities/price'

const CartItem = (cartItemData) => {
	if (!cartItemData) return null

	return {
		...cartItemData,
		value: cartItemData?.id,
		label: `${cartItemData?.name}`,
		product: Product(cartItemData?.product) ?? null,
		price: Price(cartItemData?.price),
	}
}

export default CartItem
