// @flow
import { Query, useQuery } from 'src/api/index'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import { GetCartListQuery } from 'src/api/carts/get-cart-list-query'
import Cart from 'src/apollo/_entities/cart'
import { useQueryClient } from 'react-query'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'

export const useGetCartList = (modifiers: QueryModifierType) => {
	const { authUser } = useGetAuthenticatedUser()
	const queryClient = useQueryClient()

	const { data: cartData, isLoading } = useQuery({
		key: ['get_cart_list', { modifiers: modifiers }],
		request: GetCartListQuery,
		options: {
			staleTime: 1000 * 60 * 10,
			enabled: !!modifiers,
		},
	})

	const carts = cartData?.data?.map((cart) => Cart(cart)) ?? []

	const paginatorInfo = cartData?.paginatorInfo ?? { lastPage: 1 }

	if (carts.length > 1) {
		carts.sort(function (x, y) {
			return '' + x?.location_id === '' + authUser?.current_location_id
				? -1
				: '' + y?.location_id === '' + authUser?.current_location_id
				? 1
				: 0
		})
	}

	const getCartList = async (modifiers: QueryModifierType) => {
		return await queryClient.fetchQuery(
			['get_cart_list', { modifiers: modifiers }],
			Query(GetCartListQuery, queryClient)
		)
	}

	return {
		carts,
		cartCount: paginatorInfo?.total,
		paginatorInfo,
		isLoading,
		getCartList,
	}
}
