import { gql } from 'graphql-request'

export const AddFavoriteMutation = {
	identifier: 'add_favorite',
	gql: gql`
		mutation addFavoriteProduct($product_id: ID!) {
			addFavoriteProduct(product_id: $product_id) {
				id
				is_favorite
			}
		}
	`,
	variables: (product_id) => ({
		product_id,
	}),
	formatResults: (data) => {
		return data?.addFavoriteProduct?.id
	},
	onSuccess: (key, queryClient) => {
		queryClient.invalidateQueries(['favorite_products'])
	},
	optimisticUpdate: (key, queryClient, favorites_list, product_id) => {
		queryClient.setQueryData(key, [...favorites_list, '' + product_id])
	},
}
