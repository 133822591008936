// @flow
import { Auth } from 'aws-amplify'
import User from 'src/apollo/_entities/user'
import Organization from 'src/apollo/_entities/organization'
import Location from 'src/apollo/_entities/location'

const AuthUser = (userData) => {
	if (!userData) return null

	let user = User(userData)
	let organization = Organization(userData?.organization)
	let current_location = Location(userData?.current_location)
	/* eslint-disable no-console */
	return {
		...user,
		organization,
		current_location,
		organization_id: user?.organization_id ?? organization?.id,
		signOut: () =>
			Auth.signOut({ global: true })
				.then((data) => console.log(data))
				.catch(() => Auth.signOut())
				.catch((err) => console.log(err))
				.finally(() => {
					window.localStorage.removeItem('unit')
				}),
	}
	/* eslint-enable */
}

export default AuthUser
