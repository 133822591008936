import React, { createContext, useState } from 'react'
import Pusher from 'pusher-js'
import { SUBSCRIPTION_CONFIGS } from 'src/configs/app'
import { Auth } from 'aws-amplify'
import { useComponentDidMount } from 'src/hooks/index'

export const SubscriptionContext = createContext()

export const SubscriptionProvider = ({ children }) => {
	const [pusherClient, setPusherClient] = useState()

	useComponentDidMount(async () => {
		const session = await Auth.currentSession()
		const token = session?.accessToken?.jwtToken

		setPusherClient(
			new Pusher(SUBSCRIPTION_CONFIGS.appKey, {
				...SUBSCRIPTION_CONFIGS.options,
				auth: {
					headers: {
						authorization: `Bearer ${token}`,
					},
				},
			})
		)
	})

	return (
		<SubscriptionContext.Provider value={pusherClient}>
			{children}
		</SubscriptionContext.Provider>
	)
}
