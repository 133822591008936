// @flow
import React from 'react'
import type { Node } from 'react'

type Props = {
	children?: Node,
	ref?: { current?: ?HTMLDivElement },
}

export function EmptyResults({ children, ref }: Props) {
	return (
		<div className='cm-p-normal' ref={ref}>
			{children}
		</div>
	)
}

EmptyResults.defaultProps = require('./static').staticResultsDefaults
