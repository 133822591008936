// @flow
import { lazy } from 'react'
import enums from 'src/configs/enums'
import ConfigTools from 'src/configs/ConfigTools'

export default ConfigTools({
	RootPage: {
		title: '',
		path: '/',
		component: lazy(() => import('./pages/_RootPage')),
		exact: true,
		permissions: [],
	},
	DashboardPage: {
		title: 'Dashboard',
		path: '/dashboard',
		component: lazy(() => import('src/navigation/pages/DashboardPage')),
		exact: true,
	},
	StoreHomePage: {
		title: 'Store',
		path: '/procure/store',
		component: lazy(() =>
			import('./pages/Marketplace/Store/StoreHomePage')
		),
		exact: true,
		permissions: [],
	},
	ExploreStorePage: {
		title: 'Search Results',
		path: '/procure/store/explore',
		component: lazy(() =>
			import('./pages/Marketplace/Store/ExploreStorePage')
		),
		exact: true,
	},
	ShopByCategoryPage: {
		title: 'Shop By Category',
		path: '/procure/store/categories',
		component: lazy(() =>
			import('./pages/Marketplace/Store/ShopByCategoryPage')
		),
		exact: true,
	},
	StoreVendorsPage: {
		title: 'My Vendors',
		path: '/procure/store/vendors',
		component: lazy(() =>
			import('./pages/Marketplace/Store/StoreVendorsPage')
		),
		exact: true,
		permissions: [],
	},
	ShopByFormulariesPage: {
		title: 'Shop By Price Books',
		path: '/procure/store/formularies',
		component: lazy(() =>
			import('./pages/Marketplace/Store/ShopByFormulariesPage')
		),
		exact: true,
		permissions: [],
	},
	OffCatalogPage: {
		title: 'Off Catalog',
		path: '/procure/store/off_catalog',
		component: lazy(() =>
			import('./pages/Marketplace/Store/OffCatalogPage')
		),
		exact: true,
		featureKey: 'off_catalog_ordering',
		permissions: [],
	},
	StoreProductDetailsPage: {
		title: 'Product',
		path: '/procure/store/products/:productId',
		component: lazy(() =>
			import('src/pages/store/StoreProductDetailsPage')
		),
		exact: true,
		permissions: [],
	},
	CheckoutPage: {
		title: 'Checkout',
		path: '/procure/store/checkout',
		component: lazy(() => import('./pages/CheckoutPage')),
		exact: true,
		permissions: [],
	},
	CheckoutCompletedPage: {
		title: 'Order Placed',
		path: '/procure/store/checkout/complete',
		component: lazy(() => import('./pages/CheckoutCompletedPage')),
		exact: true,
		permissions: [],
	},
	ShoppingListsPage: {
		title: 'Shopping Lists',
		path: '/procure/store/lists',
		component: lazy(() =>
			import('./pages/Marketplace/ShoppingLists/ShoppingListsPage')
		),
		exact: true,
		featureKey: 'custom_shopping_lists',
		permissions: [],
	},
	ShoppingListDetailsPage: {
		title: 'Shopping List Details',
		path: '/procure/store/lists/:listId',
		component: lazy(() =>
			import('./pages/Marketplace/ShoppingLists/ShoppingListDetailsPage')
		),
		exact: true,
		featureKey: 'custom_shopping_lists',
		permissions: [],
	},
	ManageStorePage: {
		title: 'Banners',
		path: '/procure/store/manage',
		component: lazy(() =>
			import('./pages/Marketplace/Banners/ManageStorePage')
		),
		exact: true,
		featureKey: 'customized_banners',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	RequestProductPage: {
		title: 'Product Requests',
		path: '/procure/store/catalog/request/product',
		component: lazy(() =>
			import('./pages/Marketplace/Catalog/RequestProductPage')
		),
		exact: true,
		featureKey: 'product_requests',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	StoreVendorDetailsPage: {
		title: 'Vendors',
		path: '/organization/vendors/:vendor_id',
		component: lazy(() =>
			import(
				'./pages/Marketplace/Vendors/StoreVendorDetails/StoreVendorDetailsPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	ImportVendorProductsPage: {
		title: 'Import Vendor Products',
		path: '/organization/vendors/:vendor_id/import',
		component: lazy(() =>
			import(
				'./pages/Marketplace/Vendors/StoreVendorDetails/ImportVendorProductsPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	CartPage: {
		title: 'View Cart',
		path: '/procure/store/cart',
		component: lazy(() => import('./pages/CartPage')),
		exact: true,
		permissions: [],
	},
	OnboardingPage: {
		title: 'Onboarding',
		path: '/procure/store/onboarding',
		component: lazy(() => import('./pages/OnboardingPage')),
		exact: true,
		permissions: [
			'curemint-admin',
			'curemint-developer',
			'organization-admin',
		],
	},
	CategoriesListPage: {
		title: 'Categories',
		path: '/admin/categories',
		component: lazy(() => import('./pages/CategoriesListPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	CategoryDetailsPage: {
		title: 'Categories',
		path: '/admin/categories/:categoryId',
		component: lazy(() => import('./pages/CategoryDetailsPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	ManageProductRequestsPage: {
		title: 'Product Requests',
		path: '/admin/product-requests',
		component: lazy(() => import('./pages/ManageProductRequestsPage')),
		exact: true,
		permissions: ['curemint-admin', 'curemint-developer'],
	},
	ProductImportPage: {
		title: 'Product Import',
		path: '/admin/products/import',
		component: lazy(() => import('./pages/ProductImportPage')),
		exact: true,
		props: { type: 'vendor' },
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-products',
		],
	},
	BaseProductImportPage: {
		title: 'Base Product Import',
		path: '/admin/products/import/base',
		component: lazy(() => import('./pages/ProductImportPage')),
		exact: true,
		props: { type: 'base' },
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-products',
		],
	},
	CuremintIntegrationListPage: {
		title: 'Integrations',
		path: '/curemint/integrations',
		component: lazy(() => import('./pages/CuremintIntegrationListPage')),
		exact: true,
		permissions: ['curemint-developer'],
	},
	CuremintIntegrationDetailsPage: {
		title: 'Integration Details',
		path: '/curemint/integrations/:integrationId',
		component: lazy(() => import('./pages/CuremintIntegrationDetailsPage')),
		exact: true,
		permissions: ['curemint-developer'],
	},
	InventoryListPage: {
		title: 'Inventory',
		path: '/procure/inventory',
		component: lazy(() => import('./pages/InventoryListPage')),
		exact: true,
		permissions: ['curemint-admin'],
		permissionOverride: (authUser) => {
			return (
				authUser &&
				(authUser?.isAdminOfAnyLocation() ||
					authUser?.isPurchaserOfAnyLocation())
			)
		},
	},
	InventoryLocationPage: {
		title: 'Inventory',
		path: '/procure/inventory/location/:locationId',
		component: lazy(() =>
			import('src/navigation/pages/InventoryLocationPage')
		),
		exact: true,
		permissions: ['curemint-admin'],
		permissionOverride: (authUser) => {
			return (
				authUser &&
				(authUser?.isAdminOfAnyLocation() ||
					authUser?.isPurchaserOfAnyLocation())
			)
		},
	},
	ProductDuplicateExportPage: {
		title: 'Export Product Duplicates',
		path: '/admin/products/export/duplicates',
		component: lazy(() => import('./pages/ProductExportPage')),
		exact: true,
		props: { type: 'vendor' },
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-products',
		],
	},
	FormularyExportPage: {
		title: 'Export Formulary',
		path: '/admin/products/export/formulary',
		component: lazy(() => import('./pages/FormularyExportPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-products',
		],
	},
	ProductExportPage: {
		title: 'Export Base Product Duplicates',
		path: '/admin/products/export/base/duplicates',
		component: lazy(() => import('./pages/ProductExportPage')),
		exact: true,
		props: { type: 'base' },
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-products',
		],
	},
	ProductDetailsPage: {
		title: 'Products',
		path: '/admin/products/:productId',
		component: lazy(() => import('./pages/ProductDetailsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-products',
		],
	},
	BaseProductDetailsPage: {
		title: 'Base Product',
		path: '/admin/base_products/:productId',
		component: lazy(() => import('./pages/BaseProductDetailsPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	ProductsListPage: {
		title: 'Products',
		path: '/admin/products',
		component: lazy(() => import('./pages/ProductsListPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	VendorDetailsPage: {
		title: 'Vendors',
		path: '/admin/vendors/:vendorId',
		component: lazy(() => import('./pages/VendorDetailsPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	VendorsListPage: {
		title: 'Vendors',
		path: '/admin/vendors',
		component: lazy(() => import('./pages/VendorsListPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	ManufacturerListPage: {
		title: 'Manufacturers',
		path: '/admin/manufacturers',
		component: lazy(() =>
			import(
				'src/pages/manufacturers/ManufacturerListPage/ManufacturerListPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-manufacturers',
		],
	},
	ManufacturerDetailsPage: {
		title: 'Manufacturers',
		path: '/admin/manufacturers/:manufacturerId',
		component: lazy(() => import('./pages/ManufacturerDetailsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-manufacturers',
		],
	},
	OrganizationDetailsPage: {
		title: 'Organizations',
		path: '/admin/organizations/:organizationId',
		component: lazy(() => import('./pages/OrganizationDetailsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-organizations',
		],
	},
	OrganizationsListPage: {
		title: 'Organizations',
		path: '/admin/organizations',
		component: lazy(() => import('./pages/OrganizationsListPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-organizations',
		],
	},
	AllPriceBooksListPage: {
		title: 'Price Books',
		path: '/admin/formularies',
		component: lazy(() => import('./pages/AllPriceBooksListPage')),
		exact: true,
		featureKey: 'formularies',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-formularies',
		],
	},
	FormularyDetailsPage: {
		title: 'Price Books',
		path: '/admin/formularies/:formularyId',
		component: lazy(() => import('./pages/FormularyDetailsPage')),
		exact: true,
		featureKey: 'formularies',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-formularies',
		],
	},
	ImportFormularyPricesPage: {
		title: 'Import Formulary Prices',
		path: '/admin/formularies/:formularyId/vendor/:vendorId/import',
		component: lazy(() => import('./pages/ImportFormularyPricesPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-formularies',
		],
	},
	MyAccountPage: {
		title: 'My Account',
		path: '/my/account',
		component: lazy(() => import('./pages/MyAccountPage')),
		exact: true,
	},
	AllOrdersListPage: {
		title: 'All Orders',
		path: '/admin/orders',
		component: lazy(() => import('./pages/AllOrdersListPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	AllBillsListPage: {
		title: 'All Bills',
		path: '/cm-admin/bills',
		component: lazy(() => import('./pages/AllBillsListPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin'],
	},
	MyOrganizationOrdersPage: {
		title: 'Orders',
		path: '/procure/store/orders',
		component: lazy(() => import('./pages/MyOrganizationOrdersPage')),
		exact: true,
	},
	MyApprovalsPage: {
		title: 'Approvals',
		path: '/procure/store/approvals',
		component: lazy(() => import('./pages/MyApprovalsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) =>
			authUser && authUser?.isAdminOfAnyLocation(),
	},
	OrderDetailsPage: {
		title: 'Order Details',
		path: '/procure/store/orders/:orderId',
		component: lazy(() => import('./pages/OrderDetailsPage')),
		exact: true,
		permissions: [],
	},
	OrderRulesPage: {
		title: 'Order Rules',
		path: '/procure/store/order_rules',
		component: lazy(() => import('./pages/OrderRulesPage')),
		exact: true,
		featureKey: 'order_rules',
		permissions: [
			'organization-admin',
			'curemint-developer',
			'curemint-admin',
		],
	},
	ManageUsersPage: {
		title: 'User Management',
		path: '/admin/users',
		component: lazy(() => import('./pages/ManageUsersPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) =>
			authUser && authUser.isAdminOfAnyLocation(),
	},
	OrganizationVendorListPage: {
		title: 'Vendors',
		path: '/organization/vendors',
		component: lazy(() =>
			import('./pages/MyOrganization/Vendors/OrganizationVendorListPage')
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	LocationListPage: {
		title: 'Locations',
		path: '/admin/locations',
		component: lazy(() => import('src/navigation/pages/LocationListPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) =>
			authUser && authUser.isAdminOfAnyLocation(),
	},
	MyLocationPage: {
		title: 'Locations',
		path: '/admin/my/location',
		component: lazy(() => import('src/navigation/pages/MyLocationPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) =>
			authUser && authUser.isAdminOfAnyLocation(),
	},
	LocationDetailsPage: {
		title: 'Location Details',
		path: '/admin/locations/:locationId',
		component: lazy(() =>
			import('src/navigation/pages/LocationDetailsPage')
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser, props) => {
			const locationId = props?.match?.params?.locationId
			return authUser && authUser.isAdminOfLocation(locationId)
		},
	},
	LocationGroupDetailsPage: {
		title: 'Location Group',
		path: '/admin/location_groups/:locationGroupId',
		component: lazy(() =>
			import(
				'src/pages/locations/LocationGroupDetailsPage/LocationGroupDetailsPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser, props) => {
			const locationGroupId = props?.match?.params?.locationGroupId
			return (
				authUser && authUser.isAdminOfAnyLocationGroup(locationGroupId)
			)
		},
	},
	UserGroupDetailsPage: {
		title: 'User Group',
		path: '/admin/user_groups/:userGroupId',
		component: lazy(() => import('./pages/UserGroupDetailsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser, props) => {
			const userGroupId = props?.match?.params?.userGroupId

			return authUser && authUser.isAdminOfAnyUserGroup(userGroupId)
		},
	},
	BudgetsPage: {
		title: 'Budgets',
		path: '/admin/budgets',
		component: lazy(() => import('./pages/BudgetsPage')),
		exact: true,
		featureKey: 'budgets',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) => {
			return (
				!!authUser &&
				(authUser?.isAdminOfAnyLocation() ||
					authUser?.isPurchaserOfAnyLocation())
			)
		},
	},
	ImportBudgetsPage: {
		title: 'Import Budgets',
		path: '/admin/budgets/import',
		component: lazy(() => import('./pages/ImportBudgetsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	MyOrganizationPage: {
		title: 'Organization Settings',
		path: '/admin/settings',
		component: lazy(() => import('./pages/MyOrganizationPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	TutorialsPage: {
		title: 'Tutorials',
		path: '/admin/tutorials',
		component: lazy(() => import('./pages/TutorialsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) => {
			return (
				authUser &&
				(authUser.isAdminOfAnyLocation() ||
					authUser?.isAdminOfAnyLocationGroup())
			)
		},
	},
	BillsListPage: {
		title: 'Bills',
		path: '/admin/bills',
		component: lazy(() => import('src/pages/bills/BillListPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	BillDetailsPage: {
		title: 'Bills',
		path: '/admin/bills/:billId',
		component: lazy(() =>
			import('src/pages/bills/BillDetailsPage/BillDetailsPage')
		),
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	TranscriptionsPage: {
		title: 'Transcriptions',
		path: '/payables/transcriptions',
		component: lazy(() => import('./pages/TranscriptionsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'bill-transcriber',
		],
		props: {
			transcriptionType: enums.transcriptionType.TRANSCRIPTION,
		},
	},
	ValidationsPage: {
		title: 'Validations',
		path: '/payables/validations',
		component: lazy(() => import('./pages/TranscriptionsPage')),
		exact: true,
		permissions: ['curemint-developer', 'curemint-admin', 'bill-validator'],
		props: {
			transcriptionType: enums.transcriptionType.VALIDATION,
		},
	},
	EscalationsPage: {
		title: 'Escalations',
		path: '/payables/escalations',
		component: lazy(() => import('./pages/TranscriptionsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'bill-transcriber',
			'bill-validator',
			'bill-manager',
		],
		props: {
			transcriptionType: enums.transcriptionType.ESCALATION,
		},
	},
	AccountingCodesListPage: {
		title: 'Accounting Categories',
		path: '/admin/accounting_categories',
		component: lazy(() => import('./pages/AccountingCodesListPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
		permissionOverride: (authUser) => {
			return (
				authUser?.organization?.hasTags([
					'module:Payables',
					'module:Procure',
				]) &&
				(authUser.isCuremintAdmin() || authUser.isOrganizationAdmin())
			)
		},
	},
	ChartOfAccountDetailsPage: {
		title: 'Chart of Account Details',
		path: '/admin/accounting_categories/:chartOfAccountId(\\d+)',
		component: lazy(() =>
			import('src/navigation/pages/ChartOfAccountDetailsPage')
		),
		exact: true,
	},
	LineOfBusinessDetailsPage: {
		title: 'Line of Business Details',
		path: '/admin/lines_of_business/:lineOfBusinessId(\\d+)',
		component: lazy(() =>
			import(
				'src/pages/accounting_categories/LineOfBusinessDetailsPage/LineOfBusinessDetailsPage'
			)
		),
		exact: true,
	},
	UncodedProductsDetailsPage: {
		title: 'Uncoded Products',
		path: '/admin/accounting_categories/uncoded_products',
		component: lazy(() => import('./pages/UncodedProductsDetailsPage')),
		exact: true,
	},
	ImportChartOfAccountsPage: {
		title: 'Import Chart of Account Associations',
		path: '/admin/accounting_categories/product/import',
		component: lazy(() =>
			import('src/navigation/pages/ImportChartOfAccountsPage')
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	ExplorerPage: {
		title: 'Explorer',
		path: '/explorer',
		component: lazy(() => import('./pages/ExplorerPage')),
		exact: true,
		featureKey: 'data_explorer',
	},
	TierFeatureManagementPage: {
		title: 'Tier Feature Management',
		path: '/tiers/features',
		component: lazy(() => import('./pages/TierFeatureManagementPage')),
		exact: true,
	},
	BankingAccountsPage: {
		title: 'Accounts',
		path: '/admin/finance/accounts',
		component: lazy(() => import('./pages/BankingAccountsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	BankingStatementsPage: {
		title: 'Statements',
		path: '/admin/finance/statements',
		component: lazy(() => import('./pages/BankingStatementsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	BankingOAuthRedirectPage: {
		title: 'Banking OAuth Redirect',
		path: '/admin/finance/accounts/oauth',
		component: lazy(() => import('./pages/BankingOAuthRedirectPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	PaymentsListPage: {
		title: 'Payments',
		path: '/admin/finance/payments',
		component: lazy(() => import('./pages/PaymentsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	CreatePaymentsPage: {
		title: 'Payments',
		path: '/admin/finance/createpayments/:id?',
		component: lazy(() => import('src/pages/payments/CreatePaymentsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	TransactionsListPage: {
		title: 'Transactions',
		path: '/admin/finance/transactions/:transactionNumber?',
		component: lazy(() => import('./pages/BankingTransactionsPage')),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	OrganizationPriceBookListPage: {
		title: 'Price Books',
		path: '/organization/price-books',
		component: lazy(() => import('./pages/OrganizationPriceBookListPage')),
		exact: true,
		featureKey: 'formularies',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-formularies',
			'organization-admin',
		],
	},
	ImportPriceBookVendorPricesPage: {
		title: 'Import Price Book Vendor Prices',
		path: '/organization/price-books/:priceBookId/vendor/:vendorId/import',
		component: lazy(() =>
			import('./pages/ImportPriceBookVendorPricesPage')
		),
		exact: true,
		featureKey: 'formularies',
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'curemint-manage-formularies',
			'organization-admin',
		],
	},
	AccountingSoftwarePage: {
		title: 'Integrations',
		path: '/admin/accounting_software',
		component: lazy(() =>
			import(
				'src/pages/accounting_software/AccountingSoftwarePage/AccountingSoftwarePage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	AccountingSoftwareDetailsPage: {
		title: 'Accounting Software Details',
		path: '/admin/accounting_software/details/:orgEntityId',
		component: lazy(() =>
			import(
				'src/pages/accounting_software/DetailsPage/AccountingSoftwareDetailsPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	AccountingSoftwareChartOfAccountsPage: {
		title: 'Accounting Software Chart Of Accounts Page',
		path: '/admin/accounting_software/details/:orgEntityId/chart_of_accounts',
		component: lazy(() =>
			import(
				'src/pages/accounting_software/ChartOfAccountsPage/AccountingSoftwareChartOfAccountsPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	AccountingSoftwareVendorsPage: {
		title: 'Accounting Software Vendors Page',
		path: '/admin/accounting_software/details/:orgEntityId/vendors',
		component: lazy(() =>
			import(
				'src/pages/accounting_software/VendorsPage/AccountingSoftwareVendorsPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	AccountingSoftwareOtherCategoriesPage: {
		title: 'Accounting Software Other Categories Page',
		path: '/admin/accounting_software/details/:orgEntityId/other_categories',
		component: lazy(() =>
			import(
				'src/pages/accounting_software/OtherCategoriesPage/AccountingSoftwareOtherCategoriesPage'
			)
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	EntitiesPage: {
		title: 'Entities Page',
		path: '/admin/entities',
		component: lazy(() =>
			import('src/pages/entities/EntitiesPage/EntitiesPage')
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
	EntityDetailsPage: {
		title: 'Entity Details Page',
		path: '/admin/entities/:entityId',
		component: lazy(() =>
			import('src/pages/entities/EntityDetailsPage/EntityDetailsPage')
		),
		exact: true,
		permissions: [
			'curemint-developer',
			'curemint-admin',
			'organization-admin',
		],
	},
})
