// @flow
import enums from 'src/configs/enums'

export const getEntityTypeFromHumanReadable = (
	humanReadable: string
): number => {
	switch (humanReadable) {
		case 'Exclude':
			return enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_EXCLUDE
		case 'Include':
			return enums.BUDGET_RULE_ENTITY_TYPE.OPERATOR_TYPE_INCLUDE
		case 'Chart of Account':
			return enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_CHART_OF_ACCOUNT
		case 'Line of Business':
			return enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_LINE_OF_BUSINESS
		case 'Vendor':
			return enums.BUDGET_RULE_ENTITY_TYPE.PROPERTY_TYPE_VENDOR

		default:
			return 'Unknown'
	}
}
