import { gql } from 'graphql-request'

export const GetOrderIssuesCountQuery = {
	identifier: 'get-order-issues-count',
	gql: gql`
		query getOrderIssuesCount($modifiers: QueryModifierInput!) {
			getOrderIssuesCount(modifiers: $modifiers)
		}
	`,
	variables: ({ modifiers }) => ({
		modifiers,
	}),
	formatResults: (data) => {
		return data?.getOrderIssuesCount
	},
}
