import styled from 'styled-components'

const PreviewText = styled.p`
	font-size: 12px;
	text-align: left;
`
export const CartPreviewUnitPrice = styled(PreviewText)`
	font-weight: bold;
`
export const CartPreviewName = styled(PreviewText)`
	max-width: 14em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`
