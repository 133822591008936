// @flow
import React from 'react'
import DetailedPaginator from 'src/components/Search/detailed-paginator'
import SearchBar from 'src/components/Search/search-bar'
import FilterRow from 'src/components/EntityFilters/FilterRow'
import FilterPillSection from 'src/components/EntityFilters/FilterPillSection'
import useGlobalState from 'src/library/useGlobalState/useGlobalState'

export const usePersistentSearchPaginationFilterManager = (
	uniqueStorageKey = '',
	initialSearchTarget = '',
	initialPage = 1,
	defaultFilterOptions = [],
	resultsPerPage = 10,
	sorts = []
) => {
	const [modifiers, setQueryModifiers] = useGlobalState(uniqueStorageKey, {
		page: initialPage,
		filters: {},
		addOns: [],
		searchTarget: initialSearchTarget,
		selects: [],
		resultsPerPage,
		sorts,
	})

	const setPage = (page) => {
		setQueryModifiers({
			...modifiers,
			page,
		})
	}

	const setSorts = (sorts) => {
		setQueryModifiers({
			...modifiers,
			sorts,
		})
	}

	const setSearchTarget = (searchTarget) => {
		setQueryModifiers({
			...modifiers,
			page: initialPage,
			searchTarget,
		})
	}

	const setFilters = (filters) => {
		setQueryModifiers({
			...modifiers,
			page: initialPage,
			filters,
		})
	}

	const addFiltersByType = (type, addFilters) => {
		if (addFilters?.length > 0) {
			const typeFilters = modifiers?.filters[type] ?? []

			setQueryModifiers({
				...modifiers,
				page: initialPage,
				filters: {
					...modifiers?.filters,
					[type]: [...typeFilters, ...addFilters],
				},
			})
		}
	}

	const removeFiltersByType = (type, removeFilters) => {
		if (removeFilters?.length > 0) {
			const typeFilters = modifiers?.filters[type] ?? []

			setQueryModifiers({
				...modifiers,
				page: initialPage,
				filters: {
					...modifiers?.filters,
					[type]: typeFilters?.filter(
						(id) => !removeFilters.includes(id)
					),
				},
			})
		}
	}

	const toggleFiltersByType = (type, toggleFilters) => {
		const typeFilters = modifiers?.filters[type] ?? []

		addFiltersByType(
			type,
			toggleFilters?.filter((id) => !typeFilters.includes(id))
		)
		removeFiltersByType(
			type,
			toggleFilters?.filter((id) => typeFilters.includes(id))
		)
	}

	const setSelects = (selects) => {
		setQueryModifiers({
			...modifiers,
			selects,
		})
	}

	const setAddOns = (addOns) => {
		setQueryModifiers({
			...modifiers,
			addOns,
		})
	}

	const addAddOn = (addOn) => {
		setQueryModifiers({
			...modifiers,
			addOns: [...modifiers.addOns, addOn],
		})
	}

	const removeAddOn = (target) => {
		setQueryModifiers({
			...modifiers,
			addOns: modifiers?.addOns?.filter(
				(current) => '' + current !== '' + target
			),
		})
	}

	const toggleAddOn = (target) => {
		const addOn = modifiers?.addOns?.find(
			(current) => '' + current === '' + target
		)

		if (addOn) {
			removeAddOn(target)
		} else {
			addAddOn(target)
		}
	}

	const renderPagination = (paginatorInfo) => {
		return (
			<DetailedPaginator
				paginatorInfo={paginatorInfo}
				setPage={(newPage) => setPage(newPage)}
				currentPage={modifiers.page}
			/>
		)
	}

	const renderFilters = (filters: number[] = []) => {
		const filterOptions = defaultFilterOptions.filter(
			(item, index) => !filters.includes(index)
		)

		return (
			<div className={'cm-mt-half'}>
				<FilterRow
					filterOptions={filterOptions}
					filters={modifiers?.filters}
					setFilters={setFilters}
				/>
				<br />
				<FilterPillSection
					filters={modifiers?.filters}
					setFilters={setFilters}
				/>
			</div>
		)
	}

	const renderSearch = (title, renderCustomComponent = null) => {
		return renderCustomComponent ? (
			renderCustomComponent(setSearchTarget)
		) : (
			<SearchBar
				title={title}
				onSearch={setSearchTarget}
				submitOnEnter
				initialSearchTarget={modifiers.searchTarget}
			/>
		)
	}

	const renderSearchAndFilters = (title, renderCustomComponent = null) => {
		return (
			<>
				{renderSearch(title, renderCustomComponent)}
				{defaultFilterOptions?.length ? (
					<div className='cm-ml-normal cm-mr-normal'>
						{renderFilters()}
					</div>
				) : null}
			</>
		)
	}

	return {
		// api variables
		modifiers,
		searchTarget: modifiers?.searchTarget,
		page: modifiers?.page,
		filters: modifiers?.filters,
		sorts: modifiers?.sorts,
		selects: modifiers?.selects,

		// setters
		setFilters,
		setPage,
		setSorts,
		setSelects,
		setSearchTarget,
		setQueryModifiers,
		toggleFiltersByType,
		addFiltersByType,
		removeFiltersByType,
		setAddOns,
		toggleAddOn,
		addAddOn,
		removeAddOn,

		// renderers
		renderSearch,
		renderPagination,
		renderFilters,
		renderSearchAndFilters,
	}
}
