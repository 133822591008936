// @flow
import type { Node } from 'react'
import React from 'react'
import { useGetAuthenticatedUser } from 'src/api/users/useGetAuthenticatedUser'
import Organization from 'src/apollo/_entities/organization'
import DisabledOverlayWithTooltip from 'src/components/_generic/disabled-overlay-with-tooltip'

type Props = {
	children?: Node,
	featureKey: string,
	limit?: number,
	tooltipText?: string,
	tooltipPlacement?: string,
	organizationOfInterest?: Organization,
}

const DisabledFeatureOverlayWithTooltip = ({
	children,
	featureKey,
	limit,
	tooltipText = 'Request an upgrade to your plan for access to this feature.',
	tooltipPlacement = 'auto',
	organizationOfInterest = null,
}: Props) => {
	const { authUser } = useGetAuthenticatedUser()

	const featureEnabled = !!organizationOfInterest
		? organizationOfInterest.isFeatureEnabled(featureKey, limit)
		: authUser?.organization?.isFeatureEnabled(featureKey, limit)

	return (
		<DisabledOverlayWithTooltip
			disabled={!featureEnabled}
			tooltipText={tooltipText}
			tooltipPlacement={tooltipPlacement}
		>
			{children}
		</DisabledOverlayWithTooltip>
	)
}

export default DisabledFeatureOverlayWithTooltip
