// @flow
import React from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DropzonePromptIcon } from 'src/components/_generic/dropzone/dropzone.styles'

const DropzonePromptLoading = () => (
	<>
		<DropzonePromptIcon icon={solid('bars-progress')} size={'xl'} />
		<h3>Processing...</h3>
	</>
)

export default DropzonePromptLoading
