// @flow
import type { Node } from 'react'
import React from 'react'
import { Popover } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'

type Props = {
	isOpen: boolean,
	targetId: string,
	matchReference?: boolean,
	children?: Node,
	onClose?: () => any,
	placement?: string,
	container?: any,
	renderOverModal?: boolean,
}

const ResponsivePopover = ({
	isOpen,
	placement = 'bottom-end',
	targetId,
	children,
	container = undefined,
	renderOverModal = false,
}: Props) => {
	const computeStyleFn = (data) => {
		if (placement === 'match-reference') {
			return getMatchReferenceStyles(data)
		} else {
			return getBottomEndStyles(data)
		}
	}

	const getMatchReferenceStyles = (data) => {
		const popoverWidth =
			window.innerWidth > 575
				? data.offsets.reference.width > 400
					? data.offsets.reference.width
					: 600
				: data.offsets.reference.width

		data.arrowStyles = {
			display: 'none',
		}

		data.offsets.popper.width = data.styles.width = popoverWidth
		data.offsets.popper.maxWidth = data.styles.maxWidth = popoverWidth
		data.offsets.popper.zIndex = data.styles.zIndex = renderOverModal
			? 1001
			: 100
		data.offsets.popper.transform = data.styles.transform =
			'translate3d(' +
			data.offsets.reference.left +
			'px, ' +
			data.offsets.reference.bottom +
			'px, 0px)'
		return data
	}

	const getBottomEndStyles = (data) => {
		const popoverWidth =
			window.innerWidth > 991
				? 600
				: window.innerWidth > 768
				? 480
				: window.innerWidth > 575
				? 320
				: window.innerWidth * 0.75

		data.arrowStyles = {
			right: data.offsets.reference.width / 2 + 12,
			width: 0,
		}
		data.offsets.popper.width = data.styles.width = popoverWidth
		data.offsets.popper.maxWidth = data.styles.maxWidth = popoverWidth
		data.offsets.popper.zIndex = data.styles.zIndex = renderOverModal
			? 1001
			: 100
		data.offsets.popper.transform = data.styles.transform =
			'translate3d(' +
			(data.offsets.reference.right - popoverWidth + 10) +
			'px, ' +
			(data.offsets.reference.bottom + 5) +
			'px, 0px' +
			')'
		return data
	}

	return (
		<ConditionalRender condition={!!document.getElementById(targetId)}>
			<Popover
				trigger={'click'}
				placement={placement !== 'match-reference' ? placement : 'auto'}
				isOpen={isOpen}
				target={targetId}
				modifiers={{
					computeStyle: {
						fn: computeStyleFn,
					},
				}}
				container={container || undefined}
			>
				<div className='text-left'>{children}</div>
			</Popover>
		</ConditionalRender>
	)
}

export default ResponsivePopover
