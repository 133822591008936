import styled from 'styled-components'

export const BaseStatusText = styled.span`
	font-weight: 500;
	border: 1px solid var(--brand-text-dark);
	border-radius: 6px;
	padding: 2px 4px;
	font-size: 12px;
	white-space: nowrap;
	color: var(--brand-text-dark);
`

export const SuccessStatusText = styled(BaseStatusText)`
	color: var(--success);
	border: 1px solid var(--success);
`

export const DangerStatusText = styled(BaseStatusText)`
	color: var(--danger);
	border: 1px solid var(--danger);
`

export const WarningStatusText = styled(BaseStatusText)`
	color: var(--warning);
	border: 1px solid var(--warning);
`

export const StatusText = styled(BaseStatusText)`
	color: ${(props) => (props.color ? props.color : 'var(--brand-text-dark)')};
	border: 1px solid
		${(props) => (props.color ? props.color : 'var(--brand-text-dark)')};
`
