// @flow
import React from 'react'
import Background from 'src/assets/img/authbg.jpg'

const AuthLayout = ({ children }) => {
	return (
		<div className='page-header' style={styles.container}>
			<div
				style={{
					maxWidth: 800,
					marginLeft: 'auto',
					marginRight: 'auto',
					alignSelf: 'center',
				}}
			>
				{children}
			</div>
		</div>
	)
}

const styles = {
	container: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		backgroundImage: `url(${Background})`,
	},
}

export default AuthLayout
