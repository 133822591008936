// @flow
import React from 'react'
import { useHistory } from 'react-router-dom'
import Image from 'src/components/_generic/image'
import s3Image from 'src/library/s3Image'
import type { ProductType } from 'src/flowtypes/Entities/ProductType'

type Props = {
	product: ProductType,
	shouldLinkToProductPage?: boolean,
	shouldHaveFavoritesLink?: boolean,
	className?: string,
	styles?: Object,
	children?: React.Node,
	closeModal?: (any) => any | null,
}

const ProductImage = ({
	product,
	closeModal = null,
	shouldLinkToProductPage = false,
	className = 'img-rounded img-responsive ml-auto mr-auto cm-product-image',
	styles = {
		height: 200,
		objectFit: 'cover',
		width: '100%',
	},
	children,
}: Props) => {
	let history = useHistory()
	const navigateToProductPage = () => {
		if (shouldLinkToProductPage) {
			history.push(`/procure/store/products/${product?.id}`)
			if (!!closeModal) closeModal()
		}
	}

	return (
		<div style={{ width: 'auto', position: 'relative' }}>
			<Image
				alt={product?.name || 'The product'}
				className={className}
				style={styles}
				src={s3Image(product?.image_path)}
				fallbackSrc={s3Image('logo_placeholder.jpg')}
				onClick={navigateToProductPage}
			/>
			{children}
		</div>
	)
}

export default ProductImage
