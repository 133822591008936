// withRightSidePanel doc: https://app.clickup.com/8424828/v/dc/813bw-2051/813bw-114343
// useRightSidePanel doc:https://app.clickup.com/8424828/v/dc/813bw-2051/813bw-114303
// @flow
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'
import useDidClickOutsideHook from 'src/hooks/useDidClickOutsideHook'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { ElevatedDrawer } from 'src/components/RightSidePanel/RightSidePanel.styles'

const SetIsOpenContext = createContext()
const SetRenderContext = createContext()
const SetSizeContext = createContext()
const DisabledOverlay = createContext()
const SetDisabledOverlay = createContext()

export function useRightSidePanel() {
	const setIsOpen = useContext(SetIsOpenContext)
	const setRender = useContext(SetRenderContext)
	const setSize = useContext(SetSizeContext)
	const isDisabledOverlay = useContext(DisabledOverlay)
	const setIsDisabledOverlay = useContext(SetDisabledOverlay)

	return {
		open: () => {
			setIsDisabledOverlay(true)
			setIsOpen(true)
		},
		close: () => {
			setIsOpen(false)
			setIsDisabledOverlay(false)
		},
		loadContent: (renderFunction) => setRender(renderFunction),
		isDisabledOverlay,
		setIsDisabledOverlay,
		setSize,
	}
}

export const withRightSidePanel = (Component) => (props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [render, setRender] = useState(() => null)
	const [size, setSize] = useState('sm')
	const [isDisabledOverlay, setIsDisabledOverlay] = useState(false)

	const close = () => {
		if (document.getElementsByClassName('cm-modal-overlay')?.length === 0) {
			setIsDisabledOverlay(false)
			setIsOpen(false)
		}
	}

	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) {
			close()
		}
	}, [])

	const ref = useRef()

	useDidClickOutsideHook(ref, () => close(), 'dropdown-item')

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false)

		return () => {
			document.removeEventListener('keydown', escFunction, false)
		}
	}, [])

	return (
		<>
			<SetIsOpenContext.Provider value={setIsOpen}>
				<SetRenderContext.Provider value={setRender}>
					<SetSizeContext.Provider value={setSize}>
						<SetDisabledOverlay.Provider
							value={setIsDisabledOverlay}
						>
							<DisabledOverlay.Provider value={isDisabledOverlay}>
								<Component
									{...props}
									isDisabledOverlay={isDisabledOverlay}
								/>

								<ElevatedDrawer
									isExpanded={isOpen}
									customPostfix={`-right-sidepanel-${size}`}
									parentRef={ref}
								>
									{' '}
									{render}
								</ElevatedDrawer>
								<ConditionalRender
									condition={isOpen && isDisabledOverlay}
								>
									<div
										className={'cm-right-sidebar-overlay'}
									/>
								</ConditionalRender>
							</DisabledOverlay.Provider>
						</SetDisabledOverlay.Provider>
					</SetSizeContext.Provider>
				</SetRenderContext.Provider>
			</SetIsOpenContext.Provider>
		</>
	)
}
