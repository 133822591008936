import { gql } from 'graphql-request'
import { CartItemDataFragment } from 'src/api/cart_items/_fragments/_cart_item_data'
import { PaginatorInfoFragment } from 'src/api/_generic/_fragments/_paginator_info_fragment'

export const GetCartListQuery = {
	identifier: 'get_cart_list',
	gql: gql`
        query carts($modifiers: QueryModifierInput!) {
            carts(modifiers: $modifiers) {
                data {
                    ... on Cart {
                        id
                        name
                        user_id
                        location_id
						location {
							id
							name
						}
                        items {
                            ${CartItemDataFragment}
                        }
                    }
                }
                
                ${PaginatorInfoFragment}
            }
        }
    `,
	variables: ({ modifiers }) => ({ modifiers }),
	formatResults: (data) => {
		return data?.carts
	},
}
