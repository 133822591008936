// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { DropzoneContext } from 'src/pages/_components/Dropzone/DropzoneContext'
import DropzonePromptDragging from 'src/components/_generic/dropzone/dropzone-prompt-dragging'

const DropzoneDraggingBoundary = ({
	children,
	component = DropzonePromptDragging,
}) => {
	const Component = component
	return (
		<DropzoneContext.Consumer>
			{({ isDragging }) => (
				<ConditionalRender
					condition={isDragging}
					failedRender={children}
				>
					<Component />
				</ConditionalRender>
			)}
		</DropzoneContext.Consumer>
	)
}

export default DropzoneDraggingBoundary
