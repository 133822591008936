import React from 'react'
import VerticalLayout from 'src/components/_layouts/vertical-layout'
import SplitLayout from 'src/components/_layouts/split-layout'
import useRenderLayout from 'src/library/RenderLayout/useRenderLayout'

type Props = {
	children?: React.Node,
}

const CmDevToolsLayout = ({ children }: Props) => {
	const { renderLayoutItem } = useRenderLayout(children)

	return (
		<>
			<SplitLayout rightContent={renderLayoutItem('close-button')} />

			<VerticalLayout className={'vendor-slidey-bar-content'}>
				{renderLayoutItem('content')}
			</VerticalLayout>
		</>
	)
}

export default CmDevToolsLayout
