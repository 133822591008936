import React from 'react'
import PaddedLayout from 'src/components/_generic/padded-layout'
import Image from 'src/components/_generic/image'
import s3Image from 'src/library/s3Image'

const CMDevToolsView = () => {
	const imageOptions = [
		'9eedb688-0b6e-4bce-908c-2730e6afd894-chicken_furby.png',
		'25539fbb-4bbc-4309-89c7-757e8e4caaa0-drunk_furby.jpeg',
		'b59348aa-455a-4489-9554-5c1a180911dd-knife_furby.jpeg',
		'f0a0b454-5c1f-4e50-92bd-2550396f00ad-satan_furby.jpeg',
		'6c04bc4e-49a7-414d-875f-4f5707d4677b-seance_furby.jpeg',
		'dd091b98-66f2-4725-b377-9ad8e816f2df-twin_furbies.jpg',
	]

	return (
		<PaddedLayout style={Styles.container}>
			<Image
				alt={'Hail CM Dev Tools'}
				src={s3Image(
					imageOptions[
						Math.floor(Math.random() * imageOptions.length)
					]
				)}
				fallbackSrc={s3Image(
					imageOptions[
						Math.floor(Math.random() * imageOptions.length)
					]
				)}
				style={Styles.image}
			/>
		</PaddedLayout>
	)
}

export default CMDevToolsView

const Styles = {
	container: { margin: '0 auto', height: '500px' },
	image: { maxWidth: '100%', maxHeight: '100%' },
}
