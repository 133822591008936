// @flow
import React, { useState } from 'react'
import { NavItem } from 'reactstrap'
import ConditionalRender from 'src/components/_generic/conditional-render'
import CartPreview from 'src/components/Cart/CartPreview/CartPreview'
import { useHistory } from 'react-router-dom'
import { useCheckoutState } from 'src/components/Cart/Checkout/_state/withCheckoutState'
import IconButton from 'src/components/_generic/buttons/icon-button'

const TopNavbarCart = () => {
	const [isOpen, setIsOpen] = useState(false)
	const history = useHistory()
	const hoverTimer = React.useRef()

	const [{ cart }] = useCheckoutState()

	const cartTargetId = 'user-active-cart-preview'

	const onMouseEnter = () => {
		clearTimeout(hoverTimer.current)
		setIsOpen(true)
	}

	const onMouseLeave = () => {
		clearTimeout(hoverTimer.current)
		hoverTimer.current = setTimeout(() => setIsOpen(false), 500)
	}

	const itemCount = () => {
		if (cart?.items?.length) {
			return cart?.items
				?.map((cartItem) => cartItem.quantity)
				.reduce((accum, current) => accum + current)
		}
	}

	return (
		<>
			<NavItem
				id={cartTargetId}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<IconButton
					icon={
						<>
							<i className='cm-icon cm-icon-cart' />
							<ConditionalRender
								condition={cart?.items?.length > 0}
							>
								<span
									className='badge badge-pill badge-warning notification-bubble'
									style={{ right: -5, top: -5 }}
								>
									{itemCount()}
								</span>
							</ConditionalRender>
						</>
					}
					className={'position-relative'}
					onClick={() => history.push('/procure/store/cart')}
					color={'primary'}
					data-toggle='dropdown'
				/>
			</NavItem>
			<CartPreview
				targetId={cartTargetId}
				isOpen={isOpen}
				cart_items={cart?.items ?? []}
			/>
		</>
	)
}

export default TopNavbarCart
