import { gql } from 'graphql-request'

export const DeleteCartItemMutation = {
	identifier: 'delete_cart_item',
	gql: gql`
		mutation deleteCartItem($id: ID!) {
			deleteCartItem(id: $id) {
				id
				cart_id
			}
		}
	`,
	variables: ({ id }) => ({
		id,
	}),
	formatResults: (data) => {
		return data?.deleteCartItem
	},
	onSuccess: async (key, queryClient) => {
		await queryClient.invalidateQueries(['get_cart'])
		await queryClient.invalidateQueries(['my-cart-violations'])
		await queryClient.invalidateQueries(['projected_spend_by_budget'])
		await queryClient.invalidateQueries(['get_cart_list'])
		await queryClient.invalidateQueries(['get_my_saved_items_cart'])
	},
}
