// @flow

export default {
	cognito: {
		Auth: {
			region: process.env.REACT_APP_AWS_USERPOOL_REGION,
			userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
			userPoolWebClientId:
				process.env.REACT_APP_AWS_USERPOOL_WEB_CLIENT_ID,
		},
	},
}
