// @flow
import React from 'react'
import Page from 'src/components/_generic/pages/page'
import { Alert } from 'reactstrap'
import ColorTypes from 'src/configs/Enums/ColorTypes'

type Props = {
	message?: string,
	className?: string,
}

const ErrorPage = ({ message = '', className }: Props) => {
	return (
		<Page>
			<Alert color={ColorTypes.DANGER} className={className}>
				{message}
			</Alert>
		</Page>
	)
}

ErrorPage.defaultProps = require('./static').staticErrorPageDefaults

export default ErrorPage
