const EntitiesConnections = (connectionData) => {
	if (!connectionData) return null

	const getConnectionStatus = (orgEntityId) => {
		return connectionData?.statuses?.filter(
			(orgEntityStatus) => orgEntityStatus?.id === orgEntityId
		)[0]?.status
	}

	return { ...connectionData, getConnectionStatus }
}

export default EntitiesConnections
