import { gql } from 'graphql-request'
import { UserDataFragment } from 'src/api/users/_fragments/_user_data_fragment'
import { PermissionUserDataFragment } from 'src/api/permissions/_fragments/_permission_user_data_fragment'

export const UpdateUserMutation = {
	identifier: 'update_user',
	gql: gql`
        mutation updateUser($id: ID, $input: UpdateUserInput!) {
            updateUser(id: $id, input: $input) {
                ${UserDataFragment}

                organization {
                    id
                    name
                }

                permissions {
                    ${PermissionUserDataFragment}
                }
            }
        }
	`,
	variables: ({ id, input }) => ({
		id,
		input,
	}),
	formatResults: (data) => {
		return data?.updateUser
	},
	onSuccess: (key, queryClient, data, variables) => {
		queryClient.invalidateQueries(['user', { id: '' + variables?.id }])
		queryClient.invalidateQueries(['me'])
		queryClient.invalidateQueries(['users'])
		queryClient.invalidateQueries(['get_cart_list'])
		queryClient.invalidateQueries(['locations'])
		queryClient.invalidateQueries(['get_projected_spend_by_budget'])
		queryClient.invalidateQueries(['banking_access_org'])
	},
}
