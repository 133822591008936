import * as React from 'react'
import { memo } from 'react'

const SvgPodium = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height='1em'
		width='1em'
		viewBox='0 0 32 32'
		{...props}
	>
		<g
			fill={props.color || `#212121`}
			className='podium_svg__nc-icon-wrapper'
		>
			<path d='M31 24h-8v-9a1 1 0 0 0-1-1H10a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1Z' />
			<path
				data-color='color-2'
				d='M12.471 10.862 16 9.006l3.529 1.856-.674-3.93 2.855-2.783-3.945-.573L16 0l-1.765 3.576-3.945.573 2.855 2.783-.674 3.93z'
			/>
		</g>
	</svg>
)

const Memo = memo(SvgPodium)
export default Memo
