// @flow
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import s3Image from 'src/library/s3Image'
import HorizontalLayout from 'src/components/_layouts/horizontal-layout'
import Image from 'src/components/_generic/image'
import VerticalLayout from 'src/components/_layouts/vertical-layout'
import ConditionalRender from 'src/components/_generic/conditional-render'
import Highlight from 'src/components/TopNav/GlobalSearch/Results/Highlight'
import type { MarketplaceProductType } from 'src/flowtypes/Entities/MarketplaceProductType'

type Props = {
	product: Object,
	onClose: () => any,
	handleClick: (product: MarketplaceProductType) => void,
	selected?: boolean,
	isSibling?: boolean,
}

const SearchResultsListItem = ({
	product,
	onClose,
	handleClick,
	selected,
	isSibling = false,
}: Props) => {
	const history = useHistory()

	const onClick = useCallback(() => {
		if (handleClick) {
			handleClick(product)
		} else {
			history.push(`/procure/store/products/${product?.id}`)
			onClose()
		}
	}, [handleClick, product])

	return (
		<li>
			<button
				onClick={onClick}
				className={`cm-search-result-item cm-p-normal${
					selected ? ' highlight' : ''
				}`}
			>
				<HorizontalLayout>
					<div style={styles.container} className='cm-mr-normal'>
						<Image
							alt='logo'
							className='cm-no-border-radius'
							style={styles.image}
							src={s3Image(
								product?.image_path ||
									product?._requestdata?.base_product
										?.image_path
							)}
							fallbackSrc={s3Image('logo_placeholder.jpg')}
						/>
					</div>
					<div style={styles.details}>
						<VerticalLayout>
							<p style={styles.category}>
								<ConditionalRender
									condition={product?.parent_category}
								>
									{product?.parent_category}
								</ConditionalRender>
								<ConditionalRender
									condition={
										product?.parent_category &&
										product?.primary_category
									}
								>
									{' '}
									>{' '}
								</ConditionalRender>
								<ConditionalRender
									condition={product?.primary_category}
								>
									{product?.primary_category}
								</ConditionalRender>
							</p>
							<p style={styles.header}>
								<Highlight attribute='name' hit={product} />
							</p>
							<div className='cm-subtext-italic'>
								<p
									className='cm-subtext-italic'
									style={styles.sku}
								>
									<Highlight attribute='sku' hit={product} />
									<ConditionalRender condition={isSibling}>
										<span
											style={styles.similarProductMessage}
										>
											(Similar Product)
										</span>
									</ConditionalRender>
								</p>
								<p style={styles.vendor}>
									<Highlight
										attribute='vendor_name'
										hit={product}
									/>
								</p>
							</div>
						</VerticalLayout>
					</div>
				</HorizontalLayout>
			</button>
		</li>
	)
}

export default SearchResultsListItem

const styles = {
	container: {
		width: 50,
		height: 50,
		overflow: 'hidden',
		display: 'inline-block',
	},
	image: {
		width: 50,
		height: 50,
	},
	details: {
		maxWidth: 'calc(100% - 100px)',
	},
	category: {
		fontSize: 12,
		fontWeight: 'bold',
		color: '#666',
		textTransform: 'uppercase',
	},
	similarProductMessage: {
		marginLeft: 10,
		fontStyle: 'italic',
		fontWeight: 'normal',
		backgroundColor: 'rgb(215, 239, 255)',
	},
	header: {
		margin: 0,
	},
	vendor: { margin: 0 },
	sku: { margin: 0 },
}
