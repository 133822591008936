// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import { UncontrolledPopover } from 'reactstrap'

type Props = {
	badgeCount?: number,
	className?: string,
	style?: any,
	message?: string,
	popOverTarget?: string,
	type: 'warning' | 'danger' | 'success',
}

const Badge = ({
	badgeCount,
	message,
	style,
	popOverTarget,
	type = 'warning',
	className,
}: Props) => {
	return (
		<ConditionalRender condition={!!badgeCount}>
			<span
				className={`badge badge-pill badge-${type} notification-bubble${
					className ? ` ${className}` : ''
				}`}
				style={style}
			>
				{badgeCount}
			</span>
			{popOverTarget ? (
				<UncontrolledPopover
					trigger={'hover'}
					placement={'bottom'}
					target={popOverTarget}
				>
					<div style={{ padding: '5px' }}>{message}</div>
				</UncontrolledPopover>
			) : null}
		</ConditionalRender>
	)
}

export { Badge }
