// @flow
import React from 'react'
import type { CartItemType } from 'src/flowtypes/Entities/CartItemType'
import Image from 'src/components/_generic/image'
import s3Image from 'src/library/s3Image'
import { Link } from 'react-router-dom'
import IconButton from 'src/components/_generic/buttons/icon-button'
import useErrorHandler from 'src/library/Bugsnag/useErrorHandler'
import ConditionalRender from 'src/components/_generic/conditional-render'
import CartPreviewItemDetails from 'src/components/Cart/CartPreview/CartPreviewItemDetails'
import { useDeleteCartItem } from 'src/api/cart_items/useDeleteCartItem'
import { useShowToast } from 'src/_boot/ToastContext'

type Props = {
	cart_item: CartItemType,
}

const CartPreviewItem = ({ cart_item }: Props) => {
	const { mutate: deleteCartItem, isLoading } = useDeleteCartItem()
	const { showSuccessToast } = useShowToast()
	const { reportError } = useErrorHandler()

	const handleDeleteCartItem = () => {
		deleteCartItem(
			{ id: cart_item?.id },
			{
				onSuccess: () =>
					showSuccessToast('Successfully deleted cart item!'),
				onError: (err) =>
					reportError(
						err,
						'There was a problem removing this item from your cart!'
					),
			}
		)
	}

	return (
		<>
			<div
				className={`cm-cart-preview-item ${
					isLoading ? 'cm-cart-preview-item-deleting' : ''
				}`}
			>
				<Image
					alt='logo'
					style={{ width: 100, height: 100, objectFit: 'contain' }}
					src={s3Image(
						cart_item?.product?.image_path ??
							cart_item?.product?.base_product?.image_path
					)}
					fallbackSrc={s3Image('logo_placeholder.jpg')}
				/>
				<div className={'cm-cart-preview-details'}>
					<ConditionalRender
						condition={!!cart_item?.price_id}
						failedRender={
							<CartPreviewItemDetails cart_item={cart_item} />
						}
					>
						<Link
							to={`/procure/store/products/${cart_item?.price?.product_id}`}
						>
							<CartPreviewItemDetails cart_item={cart_item} />
						</Link>
					</ConditionalRender>
				</div>
				<div style={Style.trashIcon}>
					<IconButton
						icon={<i className={'cm-icon cm-icon-trash'} />}
						onClick={handleDeleteCartItem}
						color={'transparent'}
						disabled={isLoading}
						className={'cm-delete-cart-item'}
					/>
				</div>
			</div>
			<hr style={{ margin: 0 }} />
		</>
	)
}

export default CartPreviewItem

const Style = {
	trashIcon: {
		position: 'absolute',
		bottom: 2,
		right: 2,
	},
}
