// @flow
import { Query, useQuery } from 'src/api/index'
import { useQueryClient } from 'react-query'
import { GetAuthenticatedUserQuery } from 'src/api/users/get-authenticated-user-query'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'

export const useGetAuthenticatedUser = () => {
	const queryClient = useQueryClient()

	const {
		data: authUser,
		isLoading,
		error,
		...rest
	} = useQuery({
		key: ['me'],
		request: GetAuthenticatedUserQuery,
		options: {
			staleTime: 1000 * 60 * 5,
		},
	})

	const getAuthUser = async () => {
		return await queryClient.fetchQuery(
			['me'],
			Query(GetAuthenticatedUserQuery, queryClient)
		)
	}

	useEffect(() => {
		if (error) {
			Auth.signOut({ global: true })
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					window.localStorage.removeItem('unit')
					queryClient.invalidateQueries('unit')
				})
		}
	}, [error])

	return { authUser, getAuthUser, isLoading, ...rest }
}
