// @flow
import React, { forwardRef, useEffect, useState } from 'react'
import type { MarketplaceProductType } from 'src/flowtypes/Entities/MarketplaceProductType'
import SearchResultsListItem from 'src/components/TopNav/GlobalSearch/Results/search-results-list-item'
import { EmptyResults } from 'src/components/_generic/empty/results'
import SkeletonProductDropdown from 'src/components/_generic/skeletons/skeleton-product-dropdown'
import { SEARCH_DEBOUNCE_MS } from 'src/configs/app'
import { useGlobalSearch } from 'src/components/TopNav/GlobalSearch/GlobalSearchProvider'

type Props = {
	products: Array<MarketplaceProductType>,
	onClose: () => any,
	handleClick: (product: MarketplaceProductType) => void,
	selectedProducts?: MarketplaceProductType[],
	loading?: boolean,
}

function EmptyResultsDeffer({ ref }) {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const RESULT_TIMEOUT = 500
		const timeout = setTimeout(
			() => {
				requestAnimationFrame(() => {
					setLoaded(true)
				})
			},
			SEARCH_DEBOUNCE_MS < RESULT_TIMEOUT
				? RESULT_TIMEOUT
				: SEARCH_DEBOUNCE_MS
		)

		return () => {
			clearTimeout(timeout)
		}
	}, [SEARCH_DEBOUNCE_MS])

	if (!loaded) {
		return [1, 2, 3].map((item) => (
			<SkeletonProductDropdown key={item} isLoading cell />
		))
	}
	return <EmptyResults ref={ref} />
}

const SearchResultsList = forwardRef<Props, ?HTMLElement>(
	(
		{
			products = [],
			onClose = () => {},
			handleClick,
			selectedProducts = [],
			loading,
		},
		ref
	) => {
		const { modifiers } = useGlobalSearch()

		if (!loading && !products.length) {
			return <EmptyResultsDeffer ref={ref} />
		}
		return (
			<ul ref={ref} className='cm-navbar-search-list' role='listbox'>
				{loading
					? [1, 2, 3].map((item) => (
							<SkeletonProductDropdown
								key={item}
								isLoading
								cell
							/>
					  ))
					: products.map((product) => (
							<SearchResultsListItem
								key={`marketplace-product-${product.id}-${product.sku}`}
								product={product}
								onClose={onClose}
								handleClick={handleClick}
								isSibling={product?.isSiblingTo(
									modifiers?.searchTarget
								)}
								selected={selectedProducts.some(
									(item: MarketplaceProductType) =>
										item?.id === product.id
								)}
							/>
					  ))}
			</ul>
		)
	}
)

export default SearchResultsList
