import { gql } from 'graphql-request'
import { OrganizationsVendorsDataFragment } from 'src/api/organization_vendors/_fragments/_organizations_vendors_data_fragment'

export const GetManyOrgVendorsQuery = {
	identifier: 'get_many_org_vendors_query',
	gql: gql`
		query me($ids: [ID]) {
			me {
				organization {
					vendors(ids: $ids) {
						${OrganizationsVendorsDataFragment}
					}
				}
			}
		}
	`,
	formatResults: (data) => {
		return data?.me?.organization?.vendors ?? []
	},
}
