export const ProductListDataFragment = `
	id
	sku
	name
	packaging_details
	image_path
	is_favorite
	is_verified
	vendor {
		id
		image_path
		name
	}
	base_product {
		id
		manufacturer_sku
		name
		description
		packaging_details
		image_path
		manufacturer {
			id
			image_path
			name
		}
		parent_category {
			id
			name
		}
		primary_category {
			id
			name
		}
	}
	sibling_skus
`
