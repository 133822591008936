// @flow
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useDidClickOutsideHook from 'src/hooks/useDidClickOutsideHook'
import { useGlobalSearch } from 'src/components/TopNav/GlobalSearch/GlobalSearchProvider'
import GlobalSearchForm from 'src/components/TopNav/GlobalSearch/SearchForm/global-search-form'
import GlobalSearchButton from 'src/components/TopNav/GlobalSearch/SearchForm/global-search-button'
import GlobalSearchResults from 'src/components/TopNav/GlobalSearch/Results/GlobalSearchResults'
import GlobalSearchInput from 'src/components/TopNav/GlobalSearch/SearchForm/GlobalSearchInput'
import { withInstantSearch } from 'src/components/Search/InstantSearch/withInstantSearch'

const GlobalSearch = ({ className = '', id = 'global-search-bar' }) => {
	const { modifiers } = useGlobalSearch()
	const [isOpen, setIsOpen] = useState(false)
	const history = useHistory()

	const ref = useRef()

	useDidClickOutsideHook(ref, () => setIsOpen(false))

	const handleSubmit = () => {
		setIsOpen(false)

		history.push({
			pathname: '/procure/store/explore',
		})
	}

	return (
		<div ref={ref} className={`flex-grow-1 ${className}`}>
			<GlobalSearchForm
				className={className}
				onSubmit={handleSubmit}
				initialValues={{ search: modifiers?.searchTarget }}
			>
				<GlobalSearchInput
					id={id}
					lid={'search-input'}
					setIsOpen={setIsOpen}
				/>
				<GlobalSearchResults
					isOpen={isOpen}
					lid={'global-search-results'}
					setIsOpen={setIsOpen}
					targetId={id}
				/>
				<GlobalSearchButton
					lid={'global-search-button'}
					onClick={handleSubmit}
				/>
			</GlobalSearchForm>
		</div>
	)
}
export default withInstantSearch(GlobalSearch)
