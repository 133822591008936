// @flow
import React from 'react'
import AppConfigs from 'src/configs/app'
import { useQuery } from 'react-query'
import { EmptyFunc } from 'src/library/index'
import { useRightSidePanel } from 'src/components/RightSidePanel/withRightSidePanel'
import CMDevTools from 'src/components/CMDevTools/CMDevTools'

const SideMenuEnvironment = () => {
	const { data } = useQuery('cm-dev-tools', EmptyFunc, {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	})

	const { open, close, setSize, loadContent } = useRightSidePanel()

	const openDevPanel = () => {
		setSize('lg')
		open()
		loadContent(() => {
			return <CMDevTools close={close} />
		})
	}

	if (AppConfigs.env === 'production') {
		return null
	}

	return (
		<div
			className={`cm-devtools cm-devtools-${AppConfigs.env}`}
			onClick={openDevPanel}
		>
			{data?.page?.component}
			<br />
			Environment: {AppConfigs.env}
		</div>
	)
}

export default SideMenuEnvironment
