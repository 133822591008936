// @flow
import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import useRenderLayout from 'src/library/RenderLayout/useRenderLayout'

const GlobalSearchLayout = ({ children }) => {
	const { renderLayoutItem } = useRenderLayout(children)

	return (
		<InputGroup className='flex-grow-1'>
			<InputGroupAddon addonType='prepend'>
				<InputGroupText>
					<i className='nc-icon nc-zoom-split' />
				</InputGroupText>
			</InputGroupAddon>
			{renderLayoutItem('search-input')}

			{renderLayoutItem('global-search-results')}
			<InputGroupAddon addonType='append'>
				{renderLayoutItem('global-search-button')}
			</InputGroupAddon>
		</InputGroup>
	)
}

export default GlobalSearchLayout
