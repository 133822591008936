import styled, { css } from 'styled-components'
import AsyncCreatable from 'react-select/async-creatable'
import { AsyncCreatableInputProps } from 'src/pages/_components/Inputs/AsyncCreatableInput'
import { ComponentType } from 'react'
import { default as RAsyncSelect } from 'react-select/async'
import { AsyncInputProps } from 'src/pages/_components/Inputs/_types/FormFieldProps'

export const FormLabel = styled.label`
	font-weight: 600;
	font-size: 14px;
	color: #262c32;
`

export const CreatableSelect: ComponentType<AsyncCreatableInputProps> = styled(
	AsyncCreatable
)`
  background-color: white;
  border-radius: 4px;

  [class$=control] {
    min-height: 40px;
  }

  [class$='IndicatorsContainer'], .cm-select__indicators {
    ${(props) =>
		!!props?.hideDropdownIndicator
			? css`
					display: none;
			  `
			: ''};
  }

  [class$='menu'] {
    width: ${(props) => props?.minMenuWidth ?? '100%'};

    ${(props) =>
		props?.menuEdge === 'right'
			? css`
					right: 0;
			  `
			: css`
					left: 0;
			  `};
  }

  [class$='option'] {
    overflow-wrap: break-word;
  }

  &.cm-select-has-danger {

    > [class$='control'] {
      border: 1px solid var(--danger);
    }
  }
}
`

export const StyledAsyncSelect: ComponentType<AsyncInputProps> = styled(
	RAsyncSelect
)`
  &.react-select.react-select--is-disabled
  .react-select__control--is-disabled {
    background-color: #f2f2f2;
  }

  &.react-select .react-select__menu, [class$='menu'] { {
    min-width: ${(props) => props?.minMenuWidth ?? '100%'};

    ${(props) =>
		props?.menuEdge === 'right'
			? css`
					right: 0;
			  `
			: css`
					left: 0;
			  `};
  }
`
