// @flow
import type { Node } from 'react'
import React from 'react'

type Props = {
	children: Node,
}

const CartPreviewBody = ({ children }: Props) => {
	return <div style={Style.container}>{children}</div>
}

export default CartPreviewBody

const Style = {
	container: {
		height: '100%',
		maxHeight: '300px',
		overflow: 'scroll',
		width: '100%',
		maxWidth: '70vw',
	},
}
