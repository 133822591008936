// @flow
import React from 'react'
import ConditionalRender from 'src/components/_generic/conditional-render'
import LoadingView from 'src/components/_generic/loading-view'

const LoadingBoundary = ({ isLoading, children, component = LoadingView }) => {
	const Component = component
	return (
		<ConditionalRender condition={isLoading} failedRender={children}>
			<Component />
		</ConditionalRender>
	)
}

export default LoadingBoundary
